import React from "react";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import InputModal from "../InputModal";
import LookupCustomer from "./LookupCustomer";
import NewCustomerInformation from "./NewCustomer";

const SendNewConsentFlow = ({ isOpen, onClose, store, onVisitCreated }) => {
  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <LookupCustomer
                {...props}
                onClose={onClose}
                store={store}
                onVisitCreated={onVisitCreated}
              />
            )}
          />
          <Route
            exact
            path="/new-customer"
            render={(props) => (
              <NewCustomerInformation
                {...props}
                onClose={onClose}
                store={store}
                onVisitCreated={onVisitCreated}
              />
            )}
          />
        </Switch>
      </Router>
    </InputModal>
  );
};

export default SendNewConsentFlow;
