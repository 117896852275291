import { Avatar, Box, Flex, Image, Icon, useDisclosure } from "@chakra-ui/core";
import React from "react";
import Button from "../../../../../components/Button";
import { Body } from "../../../../../components/Typography";
import InformationRow from "../../../../../components/VisitSheet/InformationRow";
import { summarizeAddress } from "../../../../../lib/address";
import EditStoreInfo from "./EditStoreInfo";

const StoreInfo = ({ store }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box paddingY={5}>
      <EditStoreInfo store={store} onClose={onClose} isOpen={isOpen} />

      <Flex>
        <Button
          leftIcon="edit"
          variant="default"
          onClick={onOpen}
          marginLeft="auto"
        >
          Edit
        </Button>
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <Box flex={1}>
          <InformationRow label="Name">
            <Body>{store.name}</Body>
          </InformationRow>

          <InformationRow label="Website">
            <Body>{store.website || "N/A"}</Body>
          </InformationRow>

          <InformationRow label="Phone Number">
            <Body>{store.phoneNumber || "N/A"}</Body>
          </InformationRow>

          <InformationRow label="Email">
            <Body>{store.emailAddress || "N/A"}</Body>
          </InformationRow>

          <InformationRow label="Address">
            <Body>{summarizeAddress(store.address)}</Body>
          </InformationRow>
        </Box>
        <Box flex={1}>
          <InformationRow label="Profile Image">
            <Avatar src={store.profileImageUrl} name={store.name} />
          </InformationRow>
          <InformationRow label="Header Image">
            <Box
              paddingBottom={"56%"}
              borderRadius={20}
              backgroundColor="lightGrey"
              overflow="hidden"
              position="relative"
            >
              {store.headerImageUrl && (
                <Image
                  width="100%"
                  height="100%"
                  position="absolute"
                  objectFit="cover"
                  top={0}
                  src={store.headerImageUrl}
                />
              )}

              {!store.headerImageUrl && (
                <Flex
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon name="store" fontSize="60px" />
                </Flex>
              )}
            </Box>
          </InformationRow>
        </Box>
      </Flex>
    </Box>
  );
};

export default StoreInfo;
