import { Box, Heading } from "@chakra-ui/core";
import React from "react";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import { Body } from "../../../../components/Typography";
import { useMutation } from "@apollo/client";
import { GENERATE_BILLING_SESSION } from "./gql";

const SettingsPage = ({ account, history }) => {
  const [generateBillingSession, { loading }] = useMutation(
    GENERATE_BILLING_SESSION,
    {
      variables: { input: { accountId: account.id } },
      onCompleted: ({ result }) => {
        if (result.redirectUrl) {
          window.location.assign(result.redirectUrl);
        } else {
          alert("There was a problem. Please contact support@bodyart.app");
        }
      },
    },
  );

  return (
    <Box padding={5}>
      <PageHeader title="Account Settings" />

      <Box mt={5} mb={5} maxW={["100%", "50%"]}>
        <Heading size="md" mb={3}>
          Billing
        </Heading>

        <Body color="darkGrey" mb={5}>
          You can manage any of the billing related tasks on your account,
          including adding or changing your credit card on file, and cancelling
          your subscription here.
        </Body>

        <Button
          variant="ghost"
          isLoading={loading}
          onClick={() => generateBillingSession()}
        >
          Manage billing
        </Button>
      </Box>

      <Box mt={5} mb={5} maxW={["100%", "50%"]}>
        <Heading size="md" mb={3}>
          Support
        </Heading>

        <Body color="darkGrey" mb={5}>
          If you need support with your BodyArt account, you can contact our
          support team here.
        </Body>

        <Button
          variant="ghost"
          onClick={() => history.push("mailto:support@bodyart.app")}
        >
          Contact support
        </Button>
      </Box>
    </Box>
  );
};

export default SettingsPage;
