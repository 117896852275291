import {
  Box,
  Text,
  Heading,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/core";
import useDocumentTitle from "@rehooks/document-title";
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../../components/Table";
import ItemMenu from "../../../../../components/ItemMenu";
import { formatDate } from "../../../../../lib/dates";
import DeleteDialog from "./DeleteCategoryDialog";
import Button from "../../../../../components/Button";
import ManageConsentQuestionCategory from "./ManageCategory";

const ConsentQuestionCategories = ({ store, categories, onSelectCategory }) => {
  useDocumentTitle(`Consent Questions - ${store.name} - Bodyart`);

  const [removingId, setRemovingId] = useState(null);
  const [editing, setEditing] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  return (
    <Box>
      <DeleteDialog
        isOpen={!!removingId}
        categoryId={removingId}
        onClose={() => setRemovingId(null)}
      />
      <ManageConsentQuestionCategory
        isOpen={!!editing || isCreating}
        store={store}
        category={editing}
        onClose={() => {
          setEditing(null);
          setIsCreating(false);
        }}
      />
      <Table>
        <TableBody>
          {categories.map((category) => (
            <TableRow key={category.id} onClick={() => {}}>
              <TableCell onClick={() => onSelectCategory(category)}>
                <Heading fontSize={["md", "lg"]}>{category.name}</Heading>
              </TableCell>
              <TableCell
                justifyContent="flex-end"
                hideOnSmall
                flexShrink={0}
                flexGrow={0}
                flexBasis={200}
              >
                <Text color="midGrey" fontSize={14}>
                  Last Updated: {formatDate(category.updatedAt) || "N/A"}
                </Text>
              </TableCell>
              <TableCell
                justifyContent="flex-end"
                flexShrink={0}
                flexGrow={0}
                flexBasis={50}
              >
                <ItemMenu>
                  <MenuList>
                    <MenuItem onClick={() => setEditing(category)}>
                      Edit
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      color="statusRed.500"
                      onClick={() => setRemovingId(category.id)}
                    >
                      Remove
                    </MenuItem>
                  </MenuList>
                </ItemMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Button
        variant="primary"
        position="absolute"
        bottom={5}
        right={5}
        onClick={() => setIsCreating(true)}
      >
        Add new category
      </Button>
    </Box>
  );
};

export default ConsentQuestionCategories;
