import { gql } from "@apollo/client";
import {
  pageInfo,
  accountSeat,
  invitedAccountSeat,
} from "../../../../fragments";

export const MEMBERS_FOR_ACCOUNT = gql`
  query MembersForAccount(
    $accountId: ID!
    $first: Number!
    $after: String
    $filters: AccountMemberFilters
  ) {
    account: node(id: $accountId) {
      ... on Account {
        id
        members(first: $first, after: $after, filterBy: $filters) {
          edges {
            node {
              ...accountSeat
            }
          }
          pageInfo {
            ...pageInfo
          }
        }
      }
    }
  }
  ${pageInfo}
  ${accountSeat}
`;

export const REMOVE_SEAT = gql`
  mutation RemoveMemberFromAccount($input: RemoveMemberFromAccountInput!) {
    result: removeMemberFromAccount(input: $input) {
      seat {
        ...accountSeat
      }
      errors {
        field
        message
      }
    }
  }
  ${accountSeat}
`;

export const UPDATE_ROLE = gql`
  mutation UpdateMemberInAccount($input: UpdateMemberInAccountInput!) {
    result: updateMemberInAccount(input: $input) {
      seat {
        ...accountSeat
      }
      errors {
        field
        message
      }
    }
  }
  ${accountSeat}
`;

export const INVITE = gql`
  mutation InviteMemberToAccount($input: InviteToAccountInput!) {
    result: inviteToAccount(input: $input) {
      invite {
        ...invitedAccountSeat
      }
      errors {
        field
        message
      }
    }
  }
  ${invitedAccountSeat}
`;
