import { Box } from "@chakra-ui/core";
import React from "react";
import { Label } from "../Typography";

const InformationRow = ({
  label,
  children,
  isLast = false,
  labelProps = {},
}) => (
  <Box flex={1} marginBottom={isLast ? 0 : 4} marginRight={2}>
    <Label {...labelProps}>{label}</Label>
    <Box marginTop={1}>{children}</Box>
  </Box>
);

export default InformationRow;
