import React from "react";
import {
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Textarea,
} from "@chakra-ui/core";
import Button from "../Button";
import { useMutation } from "@apollo/client";
import { DENY_VISIT } from "./gql";
import { Body } from "../Typography";
import { Formik } from "formik";
import FormField from "../FormField";

const DenyVisitDialogue = ({ visitId, isOpen, onClose }) => {
  const [denyVisit, { loading }] = useMutation(DENY_VISIT, {
    onCompleted: () => {
      onClose();
    },
  });

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Deny Consent
        </AlertDialogHeader>

        <Formik
          initialValues={{ denyReason: "", visitId }}
          onSubmit={(input) => denyVisit({ variables: { input } })}
        >
          {(props) => (
            <Box as="form" onSubmit={props.handleSubmit}>
              <AlertDialogBody>
                <Body marginBottom={4}>
                  Are you sure you’d like to deny this customer’s consent? This
                  action cannot be undone. You can make changes to the consent
                  form instead or send the customer a new form if there was a
                  mistake.
                </Body>

                <FormField name="denyReason" label="Deny Reason">
                  {(props) => (
                    <Textarea
                      {...props}
                      height={150}
                      variant="filled"
                      placeholder="Provide an explanation as to why you're denying this visit. This will be visible to the customer."
                    />
                  )}
                </FormField>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button variant="ghost" ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="dangerFilled"
                  type="submit"
                  isLoading={loading}
                  ml={3}
                >
                  Deny Consent
                </Button>
              </AlertDialogFooter>
            </Box>
          )}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DenyVisitDialogue;
