import { Flex, ModalBody, Image, Text } from "@chakra-ui/core";
import React from "react";
import Button from "../Button";
import InputModal from "../InputModal";

import iconLibrary from "./data.json";
import { Header, Section } from "../VisitSheet/Section";
import PageHeader from "../PageHeader";

function buildIconUrl(path) {
  return `https://d1vwenbwwrka62.cloudfront.net/app-service-icons${path}`;
}

const IconLibrary = ({ isOpen, onClose, value, onChange }) => {
  const [selectedIcon, setSelectedIcon] = React.useState(value);

  React.useEffect(() => {
    setSelectedIcon(value);
  }, [value]);

  const onSave = () => {
    onChange(selectedIcon);
    onClose();
  };

  const selectIcon = (icon) => {
    return setSelectedIcon({
      selectedUrl: buildIconUrl(icon.selected),
      unselectedUrl: buildIconUrl(icon.unselected),
    });
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader title="Select Icon" />

        <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
          {Object.keys(iconLibrary).map((type) => {
            const { name, icons } = iconLibrary[type];

            return (
              <Section>
                <Header title={name} />

                <Flex flexWrap="wrap">
                  {icons.map((icon) => {
                    const isSelected =
                      selectedIcon &&
                      selectedIcon.selectedUrl.includes(icon.selected);

                    return (
                      <Flex
                        as="button"
                        onClick={() => selectIcon(icon)}
                        padding={2}
                        border="1px solid"
                        backgroundColor={
                          isSelected ? "brandYellow.500" : "secondaryMidGrey"
                        }
                        borderColor={
                          isSelected ? "brandYellow.500" : "secondaryMidGrey"
                        }
                        width={120}
                        height={120}
                        marginRight={2}
                        marginBottom={2}
                        borderRadius={20}
                        textAlign="center"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Image src={buildIconUrl(icon.selected)} width={80} />
                        <Text
                          fontSize={10}
                          fontWeight={500}
                          mt={2}
                          textTransform="uppercase"
                          letterSpacing={1}
                        >
                          {icon.name}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              </Section>
            );
          })}
        </ModalBody>
        <Flex
          justifyContent="flex-end"
          paddingTop={5}
          borderTop="1px solid"
          borderTopColor="black"
        >
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!selectedIcon}
            onClick={onSave}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </InputModal>
  );
};

export default IconLibrary;
