import { gql } from "@apollo/client";
import { partialStoreEmployee, fullVisit } from "../../fragments";

export const MARK_ID_AS_VERIFIED = gql`
  mutation MarkIdAsVerifiedOnVisit($input: MarkIdAsVerifiedOnVisitInput!) {
    result: markIdAsVerifiedOnVisit(input: $input) {
      visit {
        ...fullVisit
      }
      errors {
        field
        message
      }
    }
  }
  ${fullVisit}
`;

export const ACKNOWLEDGE_MEDICAL_HISTORY = gql`
  mutation AcknowledgeMedicalHistoryOnVisit(
    $input: AcknowledgeMedicalHistoryOnVisitInput
  ) {
    result: acknowledgeMedicalHistoryOnVisit(input: $input) {
      visit {
        id
        medicalHistoryAcknowledgedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const EMPLOYEES_FOR_STORE = gql`
  query ListEmployeesForStore(
    $storeId: ID!
    $first: Number!
    $after: String
    $filters: StoreEmployeeFilters!
  ) {
    store: node(id: $storeId) {
      ... on Store {
        employees(first: $first, after: $after, filterBy: $filters) {
          edges {
            node {
              ...partialStoreEmployee
            }
          }
        }
      }
    }
  }
  ${partialStoreEmployee}
`;

export const UPDATE_PROCEDURES = gql`
  mutation UpdateProceduresForVisit($input: UpdateProceduresForVisitInput!) {
    result: updateProceduresForVisit(input: $input) {
      visit {
        ...fullVisit
      }
      errors {
        field
        message
      }
    }
  }
  ${fullVisit}
`;

export const APPROVE_VISIT = gql`
  mutation ApproveVisit($input: VisitOperationInput!) {
    result: approveVisit(input: $input) {
      visit {
        ...fullVisit
      }
      errors {
        field
        message
      }
    }
  }
  ${fullVisit}
`;
