import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/core";
import { Field } from "formik";

type Props = {
  name: string;
  label: string;
  isRequired?: boolean;
  hasRightMargin?: boolean;
  children: (object) => JSX.Element;
  validate?: (item) => void;
};

const FormField: React.FC<Props> = ({
  name,
  label,
  isRequired,
  children,
  hasRightMargin = true,
  ...rest
}) => {
  return (
    <Field name={name} {...rest}>
      {({ field, form }) => (
        <FormControl
          mb={4}
          mr={hasRightMargin ? 4 : 0}
          flex={1}
          isRequired={isRequired}
          isInvalid={form.errors[name] && form.touched[name]}
        >
          <FormLabel
            htmlFor="emailAddress"
            textTransform="uppercase"
            color="darkGrey"
            fontSize={14}
            marginBottom={1}
          >
            {label}
          </FormLabel>
          {children({ ...field, name })}
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default FormField;
