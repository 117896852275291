import React from "react";
import { Badge, Flex } from "@chakra-ui/core";
import { isArray } from "lodash";
import { Body } from "../Typography";
import { Header, Section } from "./Section";
import InformationRow from "./InformationRow";
import { Visit } from "../../types";
import { MEDICAL_QUESTIONS } from "../../constants";

function cellColor(val) {
  return val === true ||
    (isArray(val) && val.length > 0) ||
    (typeof val === "string" && val !== "")
    ? "statusRed.500"
    : "black";
}

function yesNo(val) {
  return val === true ? "Yes" : "No";
}

const HealthInformation = ({ visit }: { visit: Visit }) => (
  <Section>
    <Header title="Health Info">
      {visit.hasMedicalHistoryToReview && (
        <Badge ml={2} variantColor="red">
          Medical Concerns
        </Badge>
      )}
    </Header>

    <Flex flexDirection={["column", "row"]}>
      <InformationRow label="Medical History">
        <Body color={cellColor(visit.medicalHistory.medicalConditions)}>
          {visit.medicalHistory.medicalConditions.join(", ") || "N/A"}
        </Body>
      </InformationRow>
      <InformationRow label={MEDICAL_QUESTIONS.isGenerallyHealthy}>
        <Body color={cellColor(!visit.medicalHistory.isGenerallyHealthy)}>
          {yesNo(visit.medicalHistory.isGenerallyHealthy)}
        </Body>
      </InformationRow>
    </Flex>
    <Flex flexDirection={["column", "row"]}>
      <InformationRow label={MEDICAL_QUESTIONS.hasSkinCondition}>
        <Body color={cellColor(visit.medicalHistory.hasSkinCondition)}>
          {yesNo(visit.medicalHistory.hasSkinCondition)}
        </Body>
      </InformationRow>

      <InformationRow label={MEDICAL_QUESTIONS.hasIndicatedMedicationUse}>
        <Body color={cellColor(visit.medicalHistory.hasIndicatedMedicationUse)}>
          {yesNo(visit.medicalHistory.hasIndicatedMedicationUse)}
        </Body>
      </InformationRow>
    </Flex>
    <Flex flexDirection={["column", "row"]}>
      <InformationRow label={MEDICAL_QUESTIONS.hasAllergyToProducts}>
        <Body color={cellColor(visit.medicalHistory.hasAllergyToProducts)}>
          {yesNo(visit.medicalHistory.hasAllergyToProducts)}
        </Body>
      </InformationRow>

      <InformationRow label={MEDICAL_QUESTIONS.isProneToFainting}>
        <Body color={cellColor(visit.medicalHistory.isProneToFainting)}>
          {yesNo(visit.medicalHistory.isProneToFainting)}
        </Body>
      </InformationRow>
    </Flex>
    <Flex flexDirection={["column", "row"]}>
      <InformationRow label={MEDICAL_QUESTIONS.hasBloodConditions}>
        <Body color={cellColor(visit.medicalHistory.hasBloodConditions)}>
          {yesNo(visit.medicalHistory.hasBloodConditions)}
        </Body>
      </InformationRow>

      <InformationRow label={MEDICAL_QUESTIONS.hasHistoryOfComplications}>
        <Body color={cellColor(visit.medicalHistory.hasHistoryOfComplications)}>
          {yesNo(visit.medicalHistory.hasHistoryOfComplications)}
        </Body>
      </InformationRow>
    </Flex>
    <Flex flexDirection={["column", "row"]}>
      <InformationRow label="Any other medical conditions or notes?">
        <Body color={cellColor(visit.medicalHistory.additionalNotes)}>
          {visit.medicalHistory.additionalNotes || "N/A"}
        </Body>
      </InformationRow>
    </Flex>
  </Section>
);

export default HealthInformation;
