import { gql } from "@apollo/client";
import { visitService } from "../../fragments";

export const ACKNOWLEDGE_MEDICAL_HISTORY = gql`
  mutation AcknowledgeMedicalHistoryOnVisit(
    $input: AcknowledgeMedicalHistoryOnVisitInput!
  ) {
    result: acknowledgeMedicalHistoryOnVisit(input: $input) {
      visit {
        id
        medicalHistoryAcknowledgedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_PROCEDURES = gql`
  mutation UpdateProceduresForVisit($input: UpdateProceduresForVisitInput!) {
    result: updateProceduresForVisit(input: $input) {
      visit {
        id
        services {
          ...visitService
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${visitService}
`;

export const APPROVE_PROCEDURE = gql`
  mutation ApproveProcedure($input: ApproveProcedureInput!) {
    result: approveProcedure(input: $input) {
      visitService {
        ...visitService
      }
      errors {
        field
        message
      }
    }
  }
  ${visitService}
`;
