import { gql } from "@apollo/client";
import { serviceType } from "../../../../fragments";

export const SERVICE_TYPES = gql`
  query ServiceTypesForStore($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        id
        serviceTypes {
          ...serviceType
        }
      }
    }
  }
  ${serviceType}
`;

export const TOGGLE_VISIBILITY = gql`
  mutation ToggleServiceTypeForStore($input: ToggleServiceTypeForStoreInput!) {
    result: toggleServiceTypeForStore(input: $input) {
      serviceType {
        ...serviceType
      }
      errors {
        field
        message
      }
    }
  }
  ${serviceType}
`;
