import { gql } from "@apollo/client";
import { storeInformation } from "../../fragments";

export const STORE_ACCESS = gql`
  query StoresUserCanAccess {
    viewer {
      id
      isStaff
      accounts {
        id
        name
        slug
        stores {
          ...storeInformation
        }
      }
    }
  }
  ${storeInformation}
`;
