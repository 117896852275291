import { Box, Heading, Text } from "@chakra-ui/core";
import React, { useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/Table";
import Button from "../../../../components/Button";
import { summarizeAddress } from "../../../../lib/address";
import ManageStore from "./ManageStore";
import { formatDate } from "../../../../lib/dates";

const StoresPage = ({ account, history }) => {
  const [isCreating, setIsCreating] = useState(false);

  return (
    <Box padding={5}>
      <ManageStore
        isOpen={isCreating}
        accountId={account.id}
        onClose={() => setIsCreating(false)}
      />

      <PageHeader title="Stores" />

      <Table>
        <TableBody>
          {account.stores.map((store) => (
            <TableRow
              height="auto"
              key={store.id}
              onClick={() =>
                history.push(`/${account.slug}/stores/${store.slug}`)
              }
            >
              <TableCell alignItems="flex-start" paddingY={3}>
                <Box>
                  <Heading fontSize={["md", "xl"]}>{store.name}</Heading>
                  <Text fontSize={14} fontWeight="500" color="darkGrey">
                    {summarizeAddress(store.address)}
                  </Text>
                </Box>
              </TableCell>
              <TableCell
                flexGrow={0}
                flexBasis={200}
                marginLeft="auto"
                hideOnSmall
              >
                <Text color="darkGrey">
                  Added {formatDate(store.insertedAt)}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Button
        variant="primary"
        position="absolute"
        bottom={5}
        right={5}
        onClick={() => setIsCreating(true)}
      >
        Add new store
      </Button>
    </Box>
  );
};

export default StoresPage;
