import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  fullAccount as fullAccountFragment,
  accountViewerContext as accountViewerContextFragment,
  storeInformation,
} from "../fragments";
import { Account } from "../types";

const ACCOUNT_QUERY = gql`
  query Account($slug: String!) {
    account(slug: $slug) {
      ...fullAccount
      ...accountViewerContext
      stores {
        ...storeInformation
      }
    }
  }
  ${fullAccountFragment}
  ${accountViewerContextFragment}
  ${storeInformation}
`;

export default function useQueryAccountFromSlug(
  slug: string,
): { loading: boolean; account: Account } {
  const history = useHistory();
  const { data, loading } = useQuery(ACCOUNT_QUERY, {
    variables: { slug },
    onError: () => {
      history.push("/login", {
        next: "/",
        message: "Could not access account, please try again",
      });
    },
  });

  return { account: data?.account, loading };
}
