import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import Button from "../Button";
import FormField from "../FormField";

/*
  Allows inventory to be selected for proecedures.
*/

const validationSchema = Yup.object().shape({
  inventoryItemId: Yup.string()
    .required("Must select a piece of inventory")
    .nullable(),
  selectedValues: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required("Must select a value").nullable(),
    }),
  ),
});

const SelectInventoryDialog = ({
  onClose,
  isOpen,
  inventoryItems,
  value,
  onSubmit,
}) => {
  const initialValues = {
    inventoryItemId: value
      ? value.inventoryItem
        ? value.inventoryItem.id
        : value.inventoryItemId
      : null,
    selectedValues: value?.selectedValues.map((value) => ({
      name: value.name,
      value: value.value,
    })),
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay zIndex={1403} />
      <ModalContent zIndex={1404} borderRadius={10}>
        <ModalHeader>
          <Box
            borderBottomColor="black"
            borderBottom="1px solid"
            paddingBottom={3}
          >
            Select item
          </Box>
        </ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(input) => {
            const selectedItem = inventoryItems.find(
              (ii) => ii.id === input.inventoryItemId,
            );

            const selectedValues =
              selectedItem && input.selectedValues
                ? input.selectedValues.map(({ value }, idx) => {
                    const property = selectedItem.properties[idx];

                    return {
                      name: property.name,
                      value: value,
                    };
                  })
                : [];

            onSubmit({
              inventoryItemId: input.inventoryItemId,
              selectedValues,
            });
          }}
        >
          {(formProps) => {
            const { values } = formProps;
            const selectedItem = inventoryItems.find(
              (ii) => ii.id === values.inventoryItemId,
            );

            return (
              <Box as="form" onSubmit={formProps.handleSubmit}>
                <ModalBody>
                  <FormField name="inventoryItemId" label="Inventory Item">
                    {(props) => (
                      <Select {...props} variant="filled">
                        <option value="">Select an item</option>
                        {inventoryItems.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                  {(selectedItem?.properties || []).map((property, idx) => (
                    <Box key={idx}>
                      <FormField
                        name={`selectedValues[${idx}].value`}
                        label={property.name}
                      >
                        {(props) => (
                          <Select {...props} variant="filled">
                            <option value="">Select an option</option>
                            {property.options.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </Select>
                        )}
                      </FormField>
                    </Box>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!formProps.isValid}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Box>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default SelectInventoryDialog;
