import { useQuery } from "@apollo/client";
import {
  Box,
  Text,
  Heading,
  Flex,
  Avatar,
  Badge,
  Icon,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from "@chakra-ui/core";
import React, { useState } from "react";
import { get } from "lodash";
import { LIST_EMPLOYEES } from "./gql";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../../components/Table";
import Button from "../../../../../components/Button";
import InviteNewEmployee from "./InviteNewEmployee";
import ItemMenu from "../../../../../components/ItemMenu";
import RemoveEmployeeDialog from "./RemoveEmployeeDialog";
import ManageEmployee from "./ManageEmployee";
import { EmployeeRoleName } from "../../../../../constants";

const Employees = ({ store }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [employeeToRemove, setEmployeeToRemove] = useState(null);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);

  const { data, loading } = useQuery(LIST_EMPLOYEES, {
    variables: {
      storeId: store.id,
      first: 999,
      filters: {},
    },
  });

  const employeeEdges = get(data, "store.employees.edges", []);

  return (
    <Box paddingY={5}>
      <InviteNewEmployee isOpen={isOpen} onClose={onClose} store={store} />

      <RemoveEmployeeDialog
        isOpen={!!employeeToRemove}
        onClose={() => setEmployeeToRemove(null)}
        employeeId={employeeToRemove}
      />

      <ManageEmployee
        isOpen={!!employeeToEdit}
        onClose={() => setEmployeeToEdit(null)}
        employee={employeeToEdit}
        store={store}
      />

      <Flex>
        <Box marginLeft="auto" mb={5}>
          <Button variant="default" marginLeft="auto" onClick={onOpen}>
            <Icon name="add" mr={2} fontSize={20} />
            Add new employee
          </Button>
        </Box>
      </Flex>

      {!loading && employeeEdges.length === 0 && (
        <Text marginY={5}>0 results found</Text>
      )}
      <Table borderTop="1px solid" borderTopColor="secondaryMidGrey">
        <TableBody>
          {employeeEdges.map((edge) => (
            <TableRow height={"74px"} key={edge.node.id} onClick={() => {}}>
              <TableCell flexShrink={0} flexGrow={0} flexBasis={300}>
                <Flex alignItems="center">
                  <Avatar
                    size={"sm"}
                    name={edge.node.user.name}
                    src={edge.node.user.profileImageUrl}
                    mr={3}
                  />
                  <Box>
                    <Heading fontSize={["md", "lg"]}>
                      {edge.node.user.name}
                    </Heading>
                    <Text
                      fontSize={[12, 14]}
                      fontWeight="500"
                      color="darkGrey"
                      isTruncated
                    >
                      {edge.node.user.emailAddress}
                    </Text>
                  </Box>
                </Flex>
              </TableCell>
              <TableCell hideOnSmall>
                {edge.node.roles.map((role) => (
                  <Badge key={role} mr={2}>
                    {EmployeeRoleName[role]}
                  </Badge>
                ))}
              </TableCell>
              <TableCell
                hideOnSmall
                justifyContent="flex-end"
                flexShrink={0}
                flexGrow={0}
                flexBasis={100}
              >
                <Text color="midGrey">{edge.node.lastVisitAt || "N/A"}</Text>
              </TableCell>
              <TableCell
                justifyContent="flex-end"
                flexShrink={0}
                flexGrow={0}
                flexBasis={50}
              >
                <ItemMenu>
                  <MenuList>
                    <MenuItem onClick={() => setEmployeeToEdit(edge.node)}>
                      Edit
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      color="statusRed.500"
                      onClick={() => setEmployeeToRemove(edge.node.id)}
                    >
                      Remove
                    </MenuItem>
                  </MenuList>
                </ItemMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Employees;
