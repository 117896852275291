import {
  Box,
  Flex,
  Text,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  Badge,
  Link,
} from "@chakra-ui/core";
import React from "react";
import { chunk, uniq } from "lodash";
import InformationRow from "./InformationRow";
import { Body } from "../Typography";
import { Header, Section } from "./Section";
import AssignedArtist from "../AssignedArtist";
import ItemMenu from "../ItemMenu";
import { AddProcedure, RemoveProcedureDialog } from "../ManageProcedures";
import EditProcedure from "../ManageProcedures/EditProcedure";
import ReviewProceduresFlow from "../ReviewProceduresFlow";
import CompleteProceduresFlow from "../CompleteProceduresFlow";
import { summarizeInventorySelection } from "../../lib/inventory";
import { Features, determineVisitServiceStatus } from "../../constants";
import { serviceName } from "../../lib/services";
import {
  Visit,
  VisitService,
  VisitServiceStatus,
  VisitStatus,
} from "../../types";

type Props = {
  visit: Visit;
  services: VisitService[];
  refetchVisit: () => void;
  onCloseSheet: () => void;
  showArtist?: boolean;
  canManageProcedures?: boolean;
};

const Procedures: React.FC<Props> = ({
  visit,
  refetchVisit,
  services,
  showArtist = true,
  canManageProcedures = false,
  onCloseSheet,
}) => {
  const totalServices = services.length;
  const [removeProcedureId, setRemoveProcedureId] = React.useState<
    string | null
  >(null);
  const [editProcedureId, setEditProcedureId] = React.useState<string | null>(
    null,
  );

  const addDisclosure = useDisclosure();
  const reviewingDisclosure = useDisclosure();
  const completingDisclosure = useDisclosure();

  const visitStatuses = uniq(visit.services.map((vs) => vs.status));
  const visitStatus = determineVisitServiceStatus(visitStatuses);

  return (
    <Section>
      <AddProcedure
        visit={visit}
        refetchVisit={refetchVisit}
        {...addDisclosure}
      />

      <RemoveProcedureDialog
        procedureId={removeProcedureId}
        isOpen={!!removeProcedureId}
        refetchVisit={refetchVisit}
        onClose={() => setRemoveProcedureId(null)}
      />

      <ReviewProceduresFlow visit={visit} {...reviewingDisclosure} />
      <CompleteProceduresFlow
        {...completingDisclosure}
        visit={visit}
        onCompleted={() => {
          completingDisclosure.onClose();
          onCloseSheet();
        }}
      />

      <EditProcedure
        procedure={
          editProcedureId
            ? services.find((service) => service.id === editProcedureId)
            : undefined
        }
        isOpen={editProcedureId}
        onClose={() => setEditProcedureId(null)}
      />

      <Header title={`Procedures (${totalServices})`}>
        <ItemMenu>
          <MenuList>
            <MenuItem
              onClick={addDisclosure.onOpen}
              isDisabled={!canManageProcedures}
            >
              Add new procedure
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={reviewingDisclosure.onOpen}
              isDisabled={
                visit.status !== VisitStatus.Approved ||
                visitStatus !== VisitServiceStatus.Reviewing
              }
            >
              Review procedures
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={completingDisclosure.onOpen}
              isDisabled={
                visit.status !== VisitStatus.Approved ||
                visitStatus !== VisitServiceStatus.Approved
              }
            >
              Complete procedures
            </MenuItem>
          </MenuList>
        </ItemMenu>
      </Header>

      {services.map((visitService: VisitService) => {
        return (
          <Box
            key={visitService.id}
            mt={4}
            pt={4}
            borderTop="2px solid"
            borderTopColor="secondaryMidGrey"
          >
            <Header title={serviceName(visitService)}>
              <Flex alignItems="center">
                <Box ml={2}>
                  <Badge>{visitService.status}</Badge>
                </Box>
                <ItemMenu disabled={!canManageProcedures}>
                  <MenuList>
                    <MenuItem
                      onClick={() => setEditProcedureId(visitService.id)}
                    >
                      Edit
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      color="statusRed.500"
                      onClick={() => setRemoveProcedureId(visitService.id)}
                    >
                      Remove
                    </MenuItem>
                  </MenuList>
                </ItemMenu>
              </Flex>
            </Header>

            {chunk(visitService.submittedInformation, 2).map((chunk, idx) => (
              <Flex key={idx} flexDirection={["column", "row"]}>
                {chunk.map((info) => (
                  <InformationRow label={info.label} key={info.name}>
                    <Body>{info.value || "N/A"}</Body>
                  </InformationRow>
                ))}
              </Flex>
            ))}

            <Flex flexDirection={["column", "row"]}>
              {showArtist && (
                <InformationRow label="Artist">
                  {!visitService.assignedEmployee && (
                    <Body>Unassigned artist</Body>
                  )}

                  {visitService.assignedEmployee && (
                    <AssignedArtist artist={visitService.assignedEmployee} />
                  )}
                </InformationRow>
              )}

              {Features.inventorySelectionAndManagement &&
                visitService.inventorySelections.length > 0 && (
                  <InformationRow label="Inventory">
                    {visitService.inventorySelections.map((is) => (
                      <Text key={is.id}>{summarizeInventorySelection(is)}</Text>
                    ))}
                  </InformationRow>
                )}

              {!Features.inventorySelectionAndManagement && (
                <InformationRow label="Inventory">
                  <Text>{visitService.inventoryNotes || "N/A"}</Text>
                </InformationRow>
              )}
            </Flex>

            {(visitService.sterilizationNotes ||
              visitService.sterilizationLogImageUrl) && (
              <Flex flexDirection={["column", "row"]}>
                <InformationRow label="Serilization notes">
                  <Text>{visitService.sterilizationNotes}</Text>
                </InformationRow>

                <InformationRow label="Serilization log image">
                  {visitService.sterilizationLogImageUrl && (
                    <Link
                      href={visitService.sterilizationLogImageUrl}
                      target="_blank"
                      color="statusBlue"
                    >
                      Uploaded Image
                    </Link>
                  )}
                </InformationRow>
              </Flex>
            )}
          </Box>
        );
      })}
    </Section>
  );
};

export default Procedures;
