import React from "react";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/core";

const InputModal = ({ isOpen, onClose, children, size = "5xl" }) => {
  return (
    <Modal size={size} isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay zIndex={1401} />
      <ModalContent
        zIndex={1402}
        borderRadius={[0, 20]}
        height={["100%", "675px"]}
        overflow="hidden"
        margin={0}
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

export default InputModal;
