import { Service, ServiceType, ServiceTypeCategory } from "../../types";

export type SelectedService = {
  selectedServiceType: ServiceType | null;
  selectedCategory: ServiceTypeCategory | null;
  selectedService: Service | null;
};

export function selectedServices(serviceTypesById, values): SelectedService {
  const selectedServiceType = serviceTypesById[values.visitServiceType];

  const selectedCategory =
    selectedServiceType &&
    filterDisabledServices(selectedServiceType.categories).find(
      (c) => c.id === values.serviceTypeCategoryId,
    );

  const selectedService =
    selectedCategory &&
    filterDisabledServices(selectedCategory.services).find(
      (stcs) => stcs.service.id === values.serviceId,
    );

  return {
    selectedServiceType,
    selectedCategory,
    selectedService: selectedService?.service,
  };
}

export function filterDisabledServices(items): any {
  if (!items) return;

  return (items as []).filter((item: ServiceType) => !item.disabledAt);
}
