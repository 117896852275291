import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Login from "./screens/Login";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import StoreInvite from "./screens/StoreInvite";
import RequireAuth from "./screens/RequireAuth";
import AccountRouter from "./screens/Account/Router";
import StaffRouter from "./screens/Staff/Router";
import ActiveStoreRedirect from "./screens/Account/Store/ActiveStoreRedirect";
import AccountInvite from "./screens/AccountInvite";

function usePageViews() {
  const location = useLocation();
  React.useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
}

const Routes = () => {
  usePageViews();

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:token" component={ResetPassword} />
      <Route exact path="/invite/store/:token" component={StoreInvite} />
      <Route exact path="/invite/account/:token" component={AccountInvite} />

      <RequireAuth>
        <Switch>
          <Route path="/staff" component={StaffRouter} />
          <Route exact path="/" component={ActiveStoreRedirect} />
          <Route path="/:account" component={AccountRouter} />
        </Switch>
      </RequireAuth>
    </Switch>
  );
};

export default Routes;
