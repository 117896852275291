import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import { Body } from "../Typography";
import Button from "../Button";
import { useMutation } from "@apollo/client";
import { SEND_VISIT } from "./gql";

const SendVisitToCustomer = ({ visitId, isOpen, onClose, onCompleted }) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const [sendVisitToCustomer, { loading }] = useMutation(SEND_VISIT, {
    variables: { input: { visitId } },
    onCompleted: ({ result }) => {
      if (result.visit) {
        toast({
          title: "Visit has been sent to customer",
          description: "",
          status: "success",
          position: "bottom-left",
        });
        onClose();
        onCompleted();
      }
    },
  });

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold" paddingBottom={0}>
          Are you sure you want to send this consent?
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            One you send the consent form the customer will receive an email and
            see it within their Bodyart app.
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            ml={3}
            isLoading={loading}
            onClick={() => {
              sendVisitToCustomer();
            }}
          >
            Send to customer
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SendVisitToCustomer;
