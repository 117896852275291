import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  useToast,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/core";
import PageHeader from "../../../../../components/PageHeader";
import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { EmployeeRoleName, ServiceTypes } from "../../../../../constants";
import { ServiceTypeOffering, StoreEmployeeRole } from "../../../../../types";
import { INVITE_EMPLOYEE } from "./gql";
import InputModal from "../../../../../components/InputModal";

const roles = [
  StoreEmployeeRole.Artist,
  StoreEmployeeRole.FrontDesk,
  StoreEmployeeRole.Admin,
];

const artistServices = [
  ServiceTypeOffering.Piercing,
  ServiceTypeOffering.PermanentMakeup,
  ServiceTypeOffering.Tattoo,
];

const initialValues = {
  role: null,
  emailAddress: null,
  artistServices: [],
};

const InviteNewEmployee = ({ isOpen, onClose, store }) => {
  const toast = useToast();
  const [inviteEmployee, { loading }] = useMutation(INVITE_EMPLOYEE, {
    refetchQueries: [],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Employee invited to store",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Invite new Employee"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            const input = { ...values, storeId: store.id };
            const { data } = await inviteEmployee({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection="column"
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} maxWidth={["100%", "50%"]}>
                    <FormField name="emailAddress" label="Email">
                      {(props) => (
                        <Input type="email" {...props} variant="filled" />
                      )}
                    </FormField>

                    <FormField name="role" label="Role">
                      {(props) => (
                        <Select
                          {...props}
                          variant="filled"
                          onChange={(e) => {
                            formProps.setFieldValue("artistServices", []);
                            props.onChange(e);
                          }}
                          placeholder="Select role"
                        >
                          {roles.map((role) => (
                            <option key={role} value={role}>
                              {EmployeeRoleName[role]}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FormField>

                    {formProps.values.role === StoreEmployeeRole.Artist && (
                      <FormField name="artistServices" label="Services">
                        {(props) => (
                          <CheckboxGroup
                            name={props.name}
                            onChange={(value) =>
                              formProps.setFieldValue(props.name, value)
                            }
                          >
                            {artistServices.map((service) => (
                              <Checkbox value={service}>
                                {ServiceTypes[service]}
                              </Checkbox>
                            ))}
                          </CheckboxGroup>
                        )}
                      </FormField>
                    )}
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Invite
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default InviteNewEmployee;
