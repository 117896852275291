import { gql } from "@apollo/client";
import { inventoryItem } from "../../fragments";

export const INVENTORY = gql`
  query InventoryForProcedureForStore($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        inventoryItems(first: 999) {
          edges {
            node {
              ...inventoryItem
            }
          }
        }
      }
    }
  }
  ${inventoryItem}
`;
