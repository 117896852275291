import { gql } from "@apollo/client";
import { User } from "../../fragments";

export const EDIT_PROFILE = gql`
  mutation EditProfile($input: UpdateProfileInput!) {
    result: updateProfile(input: $input) {
      user {
        ...User
      }
      errors {
        field
        message
      }
    }
  }
  ${User}
`;
