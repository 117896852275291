import React from "react";
import { Formik } from "formik";
import { without, includes } from "lodash";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  useToast,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/core";
import PageHeader from "../../../../../components/PageHeader";
import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { EmployeeRoleName, ServiceTypes } from "../../../../../constants";
import { ServiceTypeOffering, StoreEmployeeRole } from "../../../../../types";
import { UPDATE_EMPLOYEE } from "./gql";
import InputModal from "../../../../../components/InputModal";

const roles = [
  StoreEmployeeRole.Artist,
  StoreEmployeeRole.FrontDesk,
  StoreEmployeeRole.Admin,
];

const artistServices = [
  ServiceTypeOffering.Piercing,
  ServiceTypeOffering.PermanentMakeup,
  ServiceTypeOffering.Tattoo,
];

const ManageEmployee = ({ isOpen, onClose, store, employee }) => {
  const toast = useToast();
  const [updateEmployee, { loading }] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Employee updated",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = {
    employeeId: employee?.id,
    user: {
      firstName: employee?.user?.firstName,
      lastName: employee?.user?.lastName,
      emailAddress: employee?.user?.emailAddress,
    },
    roles: employee?.roles,
    artistServices: employee?.artistServices,
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Employee Profile"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await updateEmployee({ variables: { input } });

            if (data.result.errors) {
              // TODO: handle errors
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} flex={1}>
                    <FormField name="user.firstName" label="First Name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="user.lastName" label="Last Name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="user.emailAddress" label="Email Address">
                      {(props) => (
                        <Input type="email" {...props} variant="filled" />
                      )}
                    </FormField>
                  </Box>
                  <Box paddingY={5} flex={1}>
                    <FormField name="roles[0]" label="Primary Role">
                      {(props) => (
                        <Select
                          {...props}
                          variant="filled"
                          onChange={(e) => {
                            formProps.setFieldValue("artistServices", []);
                            formProps.setFieldValue("roles[1]", null);
                            props.onChange(e);
                          }}
                          placeholder="Select role"
                        >
                          {roles.map((role) => (
                            <option key={role} value={role}>
                              {EmployeeRoleName[role]}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FormField>

                    <FormField name="roles[1]" label="Secondary Role">
                      {(props) => (
                        <Select
                          {...props}
                          variant="filled"
                          onChange={(e) => props.onChange(e)}
                          placeholder="Select role"
                        >
                          {without(roles, formProps.values.roles[0]).map(
                            (role) => (
                              <option key={role} value={role}>
                                {EmployeeRoleName[role]}
                              </option>
                            ),
                          )}
                        </Select>
                      )}
                    </FormField>

                    {includes(
                      formProps.values.roles,
                      StoreEmployeeRole.Artist,
                    ) && (
                      <FormField name="artistServices" label="Services">
                        {(props) => (
                          <CheckboxGroup
                            name={props.name}
                            value={props.value}
                            onChange={(value) =>
                              formProps.setFieldValue(props.name, value)
                            }
                          >
                            {artistServices.map((service) => (
                              <Checkbox value={service}>
                                {ServiceTypes[service]}
                              </Checkbox>
                            ))}
                          </CheckboxGroup>
                        )}
                      </FormField>
                    )}
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageEmployee;
