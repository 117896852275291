import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
} from "@chakra-ui/core";
import React, { memo, useState } from "react";

type Props = {
  onSubmit: (string) => void;
};

const SearchField: React.FC<Props & InputProps> = memo(function SearchField({
  onSubmit,
  value = "",
  ...rest
}) {
  const [searchTerm, setSearchTerm] = useState(value);

  return (
    <Box
      width="100%"
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(searchTerm);
      }}
    >
      <InputGroup
        backgroundColor="secondaryMidGrey"
        borderRadius={20}
        overflow="hidden"
        width={["calc(100vw - 2.5rem)", "400px"]}
      >
        <InputLeftElement>
          <Icon name="search" />
        </InputLeftElement>
        <Input
          name="search"
          backgroundColor="secondaryMidGrey"
          fontSize={14}
          fontWeight="medium"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          {...rest}
        />
        <InputRightElement
          display={["none", "block"]}
          backgroundColor="black"
          width="96px"
        >
          <Box as="button" role="submit" flex={1} width="100%" height="100%">
            <Text
              textTransform="uppercase"
              color="brandYellow.500"
              fontWeight="bold"
              fontSize={14}
            >
              Search
            </Text>
          </Box>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
});

export default SearchField;
