import React from "react";
import { Select } from "@chakra-ui/core";

const SelectFromData = ({
  items,
  valueSelector = (item) => item.id,
  nameSelector = (item) => item.displayName,
  ...rest
}) => (props) => (
  <Select {...props} {...rest} variant="filled">
    {items.map((item) => (
      <option key={valueSelector(item)} value={valueSelector(item)}>
        {nameSelector(item)}
      </option>
    ))}
  </Select>
);

export default SelectFromData;
