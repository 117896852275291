import { gql } from "@apollo/client";
import { fullAccount, pageInfo } from "../../../fragments";

export const ACCOUNTS = gql`
  query AllAccounts($filters: AccountFilters, $first: Number!, $after: String) {
    accounts(first: $first, after: $after, filterBy: $filters) {
      edges {
        node {
          ...fullAccount
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${pageInfo}
  ${fullAccount}
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountInput) {
    result: createAccount(input: $input) {
      account {
        ...fullAccount
      }
      errors {
        field
        message
      }
    }
  }
  ${fullAccount}
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput) {
    result: updateAccount(input: $input) {
      account {
        ...fullAccount
      }
      errors {
        field
        message
      }
    }
  }
  ${fullAccount}
`;
