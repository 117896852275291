import { gql } from "@apollo/client";
import { visitListingInformation } from "../../../../fragments";

export const QUERY_VISITS_FOR_STORE = gql`
  query VisitsForStore(
    $storeId: ID!
    $filters: StoreVisitFilters!
    $first: Number!
    $after: String
  ) {
    store: node(id: $storeId) {
      ... on Store {
        id
        visits(filterBy: $filters, first: $first, after: $after) {
          edges {
            node {
              ...visitListingInformation
            }
          }
        }
      }
    }
  }
  ${visitListingInformation}
`;

export const ADD_VISIT_TO_QUEUE = gql`
  mutation AddVisitToQueue($input: AddVisitToQueueInput!) {
    result: addVisitToQueue(input: $input) {
      visit {
        id
        scheduledFor
        status
      }
      errors {
        field
        message
      }
    }
  }
`;
