import { gql } from "@apollo/client";
import { storeAftercare } from "../../../../../fragments";

export const AFTERCARE = gql`
  query AftercareForStore($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        id
        aftercareDefaults {
          ...storeAftercare
        }
      }
    }
  }
  ${storeAftercare}
`;

export const RESET_FOR_SERVICE_TYPE = gql`
  mutation ResetForServiceType(
    $input: ResetStoreAftercareForServiceTypeInput!
  ) {
    result: resetStoreAftercareForServiceType(input: $input) {
      success
    }
  }
`;

export const UPDATE_AFTERCARE = gql`
  mutation UpdateStoreAftercare($input: UpdateStoreAftercareInput!) {
    result: updateStoreAftercare(input: $input) {
      storeAftercare {
        ...storeAftercare
      }
      errors {
        field
        message
      }
    }
  }
  ${storeAftercare}
`;
