import React from "react";
import { Flex, ModalCloseButton, useToast } from "@chakra-ui/core";
import { get, includes } from "lodash";
import PageHeader from "../PageHeader";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_PROCEDURE, SERVICES } from "./gql";
import useCurrentStore from "../../hooks/useCurrentStore";
import ManageProcedureForm from "./ManageProcedureForm";
import InputModal from "../InputModal";

const EditProcedure = ({ procedure, onClose, isOpen }) => {
  const store = useCurrentStore();
  const toast = useToast();
  const { data, loading } = useQuery(SERVICES, {
    skip: !isOpen,
    variables: { storeId: store?.id },
  });

  const [updateProcedure, { loading: isUpdating }] = useMutation(
    UPDATE_PROCEDURE,
    {
      onCompleted: () => {
        toast({
          title: "Service updated",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      },
    },
  );

  const serviceTypes = get(data, "store.serviceTypes", []);

  const selectedServiceType =
    procedure &&
    serviceTypes.find((st) => st.id === procedure.service.serviceType.id);

  const selectedCategory =
    selectedServiceType &&
    selectedServiceType.categories.find((c) => {
      return includes(
        c.services.map((stcs) => stcs.service.id),
        procedure.service.id,
      );
    });

  const initialValues = procedure && {
    assignedEmployeeId: procedure.assignedEmployee?.id,
    visitServiceType: selectedServiceType?.id,
    serviceTypeCategoryId: selectedCategory?.id,
    serviceId: procedure.service.id,
    inventoryNotes: procedure.inventoryNotes,
    inventorySelections: (procedure.inventorySelections || []).map((is) => ({
      inventoryItemId: is.inventoryItem.id,
      selectedValues: (is.selectedValues || []).map((v) => ({
        name: v.name,
        value: v.value,
      })),
    })),
    submittedInformation: procedure.submittedInformation?.reduce(
      (acc, info) => {
        return { ...acc, [info.name]: info.value };
      },
      {},
    ),
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Edit Procedure"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />
        {!loading && serviceTypes.length > 0 && (
          <ManageProcedureForm
            store={store}
            onClose={onClose}
            initialValues={initialValues}
            canManageServiceType={false}
            mode="edit"
            onSubmit={(values) =>
              updateProcedure({
                variables: {
                  input: { ...values, procedureId: procedure.id },
                },
              })
            }
            isLoading={isUpdating}
            serviceTypes={serviceTypes}
          />
        )}
      </Flex>
    </InputModal>
  );
};

export default EditProcedure;
