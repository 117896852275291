import React from "react";
import {
  MemoryRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ReviewMedicalConcerns from "./ReviewMedicalConcerns";
import ReviewProcedure from "./ReviewProcedure";
import Finished from "./Finished";
import InputModal from "../InputModal";

const compose = (Component, p) => (props) => <Component {...p} {...props} />;

const ReviewProceduresFlow = ({ visit, onOpen, onClose, isOpen }) => {
  const screenProps = {
    visit,
    visitServiceIds: visit.services.map((vs) => vs.id),
    onClose,
    totalSteps: visit.hasMedicalHistoryToReview ? 2 : 1,
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Router key={`visit-${visit.id}`}>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              visit.hasMedicalHistoryToReview ? (
                <Redirect to="/medical-concerns" />
              ) : (
                <Redirect to="/procedure" />
              )
            }
          />
          <Route
            exact
            path="/medical-concerns"
            render={compose(ReviewMedicalConcerns, screenProps)}
          />
          <Route
            exact
            path="/procedure"
            render={compose(ReviewProcedure, screenProps)}
          />
          <Route
            exact
            path="/finished"
            render={compose(Finished, screenProps)}
          />
        </Switch>
      </Router>
    </InputModal>
  );
};

export default ReviewProceduresFlow;
