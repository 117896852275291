import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Textarea,
  Text,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import FormField from "../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { CREATE_SERVICE, UPDATE_SERVICE } from "./gql";
import { Section, Header } from "../../../../components/VisitSheet/Section";
import InputModal from "../../../../components/InputModal";
import { ServiceTypeOffering } from "../../../../types";
import SelectServiceIcon from "../../../../components/SelectServiceIcon";
import { prepareForSubmission } from "../../../../lib/form";

enum Mode {
  Creating = "CREATING",
  Updating = "UPDATING",
}

const Mutations = {
  [Mode.Creating]: CREATE_SERVICE,
  [Mode.Updating]: UPDATE_SERVICE,
};

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required("Display name is required"),
  minimumAgeRequirement: Yup.number().min(13).max(18).nullable(),
  aftercareInstructions: Yup.string().max(999).nullable(),
  externalAftercareInstructionsUrl: Yup.string()
    .url("Must be a valid URL")
    .nullable(),
});

const ManageService = ({ isOpen, onClose, service, category }) => {
  const toast = useToast();
  const mode = service ? Mode.Updating : Mode.Creating;
  const [updateService, { loading }] = useMutation(Mutations[mode], {
    refetchQueries: ["ServiceTypeCategory"],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Service saved",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = {
    serviceId: service?.id,
    name: service?.name,
    displayName: service?.displayName,
    minimumAgeRequirement: service?.minimumAgeRequirement,
    aftercareInstructions: service?.aftercareInstructions,
    externalAftercareInstructionsUrl: service?.externalAftercareInstructionsUrl,
    downsizeTime: service?.downsizeTime,
    healingTime: service?.healingTime,
    icon: service?.icon,
    ...(mode === Mode.Creating
      ? { categoryId: category?.id }
      : { serviceId: service?.id }),
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Manage Service"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const input = prepareForSubmission({
              ...values,
              minimumAgeRequirement:
                values.minimumAgeRequirement !== ""
                  ? values.minimumAgeRequirement
                  : null,
            });

            const { data } = await updateService({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection="column"
                  onSubmit={formProps.handleSubmit}
                >
                  <Section>
                    <Header title="Service Information" />

                    <Flex flexDirection={["column", "row"]}>
                      <Box flex={1} width={["100%", "50%"]}>
                        <Text maxW={350} color="darkGrey" mb={5} fontSize={14}>
                          Add the main details for this service. If you set an
                          age minimum, all customers must be greater than or
                          equal to that age to see this service as an option.
                        </Text>
                      </Box>
                      <Box flex={1} width={["100%", "50%"]}>
                        <FormField name="name" label="Name">
                          {(props) => <Input {...props} variant="filled" />}
                        </FormField>
                        <FormField
                          name="displayName"
                          label="Display Name (Shown in App)"
                        >
                          {(props) => <Input {...props} variant="filled" />}
                        </FormField>
                        <FormField
                          name="minimumAgeRequirement"
                          label="Minimum Age Requirement"
                        >
                          {(props) => (
                            <Input type="number" {...props} variant="filled" />
                          )}
                        </FormField>

                        <FormField name="icon" label="Icon">
                          {(props) => <SelectServiceIcon {...props} />}
                        </FormField>
                      </Box>
                    </Flex>
                  </Section>

                  <Section>
                    <Header title="Aftercare" />

                    <Flex flexDirection={["column", "row"]}>
                      <Box flex={1} width={["100%", "50%"]}>
                        <Text maxW={350} color="darkGrey" mb={5} fontSize={14}>
                          Add any aftercare instructions for this service. These
                          will be prepopulated to all artists who perform a
                          procedure including this service.
                        </Text>

                        <Text maxW={350} color="darkGrey" mb={5} fontSize={14}>
                          Leave this blank to have the aftercare here inherit
                          from the default aftercare set for this service type.
                        </Text>
                      </Box>
                      <Box flex={1} width={["100%", "50%"]}>
                        <FormField
                          name="aftercareInstructions"
                          label="Aftercare Instructions"
                        >
                          {(props) => <Textarea {...props} variant="filled" />}
                        </FormField>
                        <FormField
                          name="externalAftercareInstructionsUrl"
                          label="External Aftercare Instructions"
                        >
                          {(props) => <Input {...props} variant="filled" />}
                        </FormField>

                        {service &&
                          service.serviceType.type ===
                            ServiceTypeOffering.Piercing && (
                            <>
                              <FormField
                                name="downsizeTime"
                                label="Downsize Time"
                              >
                                {(props) => (
                                  <Input {...props} variant="filled" />
                                )}
                              </FormField>
                              <FormField
                                name="healingTime"
                                label="Healing Time"
                              >
                                {(props) => (
                                  <Input {...props} variant="filled" />
                                )}
                              </FormField>
                            </>
                          )}
                      </Box>
                    </Flex>
                  </Section>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageService;
