import { useQuery } from "@apollo/client";
import { Heading, Box, Text, MenuList, MenuItem } from "@chakra-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import PageHeader from "../../../components/PageHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../components/Table";
import Button from "../../../components/Button";
import { ACCOUNTS } from "./gql";
import { formatDate } from "../../../lib/dates";
import ManageAccount from "./ManageAccount";
import ItemMenu from "../../../components/ItemMenu";
import { Account } from "../../../types";

const Accounts = () => {
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [editing, setEditing] = React.useState<Account | null>(null);
  const { data } = useQuery(ACCOUNTS, {
    variables: {
      first: 50,
    },
  });

  const accountEdges = get(data, "accounts.edges", []);

  return (
    <Box padding={5}>
      <PageHeader title="Accounts" />

      <ManageAccount
        isOpen={isCreating || editing}
        account={editing}
        onClose={() => {
          setEditing(null);
          setIsCreating(false);
        }}
      />

      <Table>
        <TableBody>
          {accountEdges.map((edge) => (
            <TableRow height="auto" key={edge.node.id}>
              <TableCell onClick={() => history.push(`/${edge.node.slug}`)}>
                <Box py={3}>
                  <Heading size="md">{edge.node.name}</Heading>
                  <Text color="darkGrey">{edge.node.slug}</Text>
                </Box>
              </TableCell>
              <TableCell
                flexGrow={0}
                flexBasis={200}
                marginLeft="auto"
                hideOnSmall
              >
                <Text color="darkGrey">
                  Added {formatDate(edge.node.insertedAt)}
                </Text>
              </TableCell>
              <TableCell
                justifyContent="flex-end"
                flexShrink={0}
                flexGrow={0}
                flexBasis={50}
                marginLeft="auto"
              >
                <ItemMenu>
                  <MenuList placement="bottom-end">
                    <MenuItem onClick={() => setEditing(edge.node)}>
                      Edit account
                    </MenuItem>
                  </MenuList>
                </ItemMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Button
        variant="primary"
        position="absolute"
        bottom={5}
        right={5}
        onClick={() => setIsCreating(true)}
      >
        Add new account
      </Button>
    </Box>
  );
};

export default Accounts;
