import { gql } from "@apollo/client";
import { serviceType, serviceTypeCategory } from "../../../../fragments";

export const SERVICE_TYPE = gql`
  query ServiceType($serviceTypeId: ID!) {
    serviceType: node(id: $serviceTypeId) {
      ... on ServiceType {
        ...serviceType
      }
    }
  }
  ${serviceType}
`;

export const TOGGLE_VISIBILITY = gql`
  mutation ToggleServiceTypeCategoryForStore(
    $input: ToggleServiceTypeCategoryForStoreInput!
  ) {
    result: toggleServiceTypeCategoryForStore(input: $input) {
      serviceTypeCategory {
        ...serviceTypeCategory
      }
      errors {
        field
        message
      }
    }
  }
  ${serviceTypeCategory}
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateServiceTypeCategory($input: UpdateServiceTypeCategoryInput!) {
    result: updateServiceTypeCategory(input: $input) {
      serviceTypeCategory {
        ...serviceTypeCategory
      }
      errors {
        field
        message
      }
    }
  }
  ${serviceTypeCategory}
`;

export const CREATE_CATEGORY = gql`
  mutation CreateServiceTypeCategory($input: CreateServiceTypeCategoryInput!) {
    result: createServiceTypeCategory(input: $input) {
      serviceTypeCategory {
        ...serviceTypeCategory
      }
      errors {
        field
        message
      }
    }
  }
  ${serviceTypeCategory}
`;

export const REMOVE_CATEGORY = gql`
  mutation RemoveServiceTypeCategory($input: RemoveServiceTypeCategoryInput!) {
    result: removeServiceTypeCategory(input: $input) {
      serviceTypeCategory {
        ...serviceTypeCategory
      }
      errors {
        field
        message
      }
    }
  }
  ${serviceTypeCategory}
`;
