import { Box, Flex, Icon, Link } from "@chakra-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

type MenuOptionProps = {
  to: string;
  icon?: string;
  title: string;
  isSelected?: boolean;
  isCollapsed?: boolean;
  onClick?: () => void;
};

const MenuOption: React.FC<MenuOptionProps> = ({
  to,
  icon,
  title,
  isSelected,
  isCollapsed = false,
  onClick,
}) => {
  return (
    <Box
      mb={[3, 6]}
      pt={[3, 0]}
      borderTop="1px solid"
      borderTopColor={["darkestGrey", "transparent"]}
    >
      <Link
        as={RouterLink as any}
        {...{ to }}
        color={isSelected ? "brandYellow.500" : "midGrey"}
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={onClick}
      >
        <Flex
          mr={isCollapsed ? 0 : 4}
          backgroundColor="brandYellow.500"
          opacity={isSelected ? 1 : 0.2}
          width={10}
          height={10}
          borderRadius={40}
          alignItems="center"
          justifyContent="center"
        >
          <Icon name={icon || "star"} size="24px" />
        </Flex>
        {!isCollapsed && title}
      </Link>
    </Box>
  );
};

export default MenuOption;
