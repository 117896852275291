import { gql } from "@apollo/client";
import {
  pageInfo,
  consentQuestion,
  consentQuestionCategory,
} from "../../../../../fragments";

export const CONSENT_QUESTION_CATEGORIES = gql`
  query ConsentQuestionCategories($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        id
        consentQuestionCategories {
          ...consentQuestionCategory
        }
      }
    }
  }
  ${consentQuestionCategory}
`;

export const CONSENT_QUESTIONS = gql`
  query ConsentQuestionsForStore(
    $storeId: ID!
    $first: Number!
    $after: String
  ) {
    store: node(id: $storeId) {
      ... on Store {
        id
        consentQuestions(first: $first, after: $after) {
          edges {
            node {
              id
              question
              index
              updatedAt
              insertedAt
            }
            cursor
          }
          pageInfo {
            ...pageInfo
          }
        }
      }
    }
  }

  ${pageInfo}
`;

export const REMOVE_CONSENT_QUESTION = gql`
  mutation RemoveConsentQuestion($input: RemoveConsentQuestionForStoreInput!) {
    result: removeConsentQuestionForStore(input: $input) {
      consentQuestion {
        ...consentQuestion
      }
      errors {
        field
        message
      }
    }
  }
  ${consentQuestion}
`;

export const REMOVE_CONSENT_QUESTION_CATEGORY = gql`
  mutation RemoveConsentQuestionCategory(
    $input: RemoveConsentQuestionCategoryForStoreInput!
  ) {
    result: removeConsentQuestionCategoryForStore(input: $input) {
      consentQuestionCategory {
        ...consentQuestionCategory
      }
      errors {
        field
        message
      }
    }
  }
  ${consentQuestionCategory}
`;

export const CREATE_CONSENT_QUESTION = gql`
  mutation CreateConsentQuestion($input: CreateConsentQuestionForStoreInput!) {
    result: createConsentQuestionForStore(input: $input) {
      consentQuestion {
        ...consentQuestion
      }
      errors {
        field
        message
      }
    }
  }
  ${consentQuestion}
`;

export const CREATE_CONSENT_QUESTION_CATEGORY = gql`
  mutation CreateConsentQuestionCategory(
    $input: CreateConsentQuestionCategoryForStoreInput!
  ) {
    result: createConsentQuestionCategoryForStore(input: $input) {
      consentQuestionCategory {
        ...consentQuestionCategory
      }
      errors {
        field
        message
      }
    }
  }
  ${consentQuestionCategory}
`;

export const UPDATE_CONSENT_QUESTION = gql`
  mutation UpdateConsentQuestion($input: UpdateConsentQuestionForStoreInput!) {
    result: updateConsentQuestionForStore(input: $input) {
      consentQuestion {
        ...consentQuestion
      }
      errors {
        field
        message
      }
    }
  }
  ${consentQuestion}
`;

export const UPDATE_CONSENT_QUESTION_CATEGORY = gql`
  mutation UpdateConsentQuestionCategory(
    $input: UpdateConsentQuestionCategoryForStoreInput!
  ) {
    result: updateConsentQuestionCategoryForStore(input: $input) {
      consentQuestionCategory {
        ...consentQuestionCategory
      }
      errors {
        field
        message
      }
    }
  }
  ${consentQuestionCategory}
`;

export const REORDER_CONSENT_QUESTION = gql`
  mutation ReorderConsentQuestion(
    $input: ReorderConsentQuestionForStoreInput!
  ) {
    result: reorderConsentQuestionForStore(input: $input) {
      consentQuestion {
        ...consentQuestion
      }
      errors {
        field
        message
      }
    }
  }
  ${consentQuestion}
`;
