import {
  Badge,
  Box,
  Flex,
  Heading,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import React from "react";
import Button from "../Button";
import BasicInfo from "./BasicInfo";
import HealthInformation from "./HealthInformation";
import InformationRow from "./InformationRow";
import Procedures from "./Procedures";
import ReviewVisitFlow from "../ReviewVisitFlow";
import { VisitStatus } from "../../types";
import DenyVisitDialogue from "./DenyVisitDialogue";
import { VisitStatusMessage, VisitStatusColor } from "../../constants";
import SendVisitToCustomer from "./SendVisitToCustomer";
import { formatDate, formatDateTime } from "../../lib/dates";
import ConsentAnswers from "./ConsentAnswers";

enum VisitActions {
  SendConsent = "SendConsent",
  EditCustomer = "EditCustomer",
  ManageServices = "ManageServices",
  ApproveServices = "ApproveServices",
  CompleteServices = "CompleteServices",
  Approve = "Approve",
  Deny = "Deny",
}

const visitPermissions = {
  [VisitStatus.Pending]: {
    [VisitActions.SendConsent]: true,
    [VisitActions.Deny]: false,
    [VisitActions.Approve]: false,
    [VisitActions.EditCustomer]: true,
    [VisitActions.ManageServices]: true,
  },
  [VisitStatus.Draft]: {
    [VisitActions.SendConsent]: false,
    [VisitActions.Deny]: false,
    [VisitActions.Approve]: false,
    [VisitActions.EditCustomer]: true,
    [VisitActions.ManageServices]: true,
  },
  [VisitStatus.Submitted]: {
    [VisitActions.SendConsent]: false,
    [VisitActions.Deny]: true,
    [VisitActions.Approve]: false,
    [VisitActions.EditCustomer]: true,
    [VisitActions.ManageServices]: true,
  },
  [VisitStatus.ConsentRequested]: {
    [VisitActions.SendConsent]: false,
    [VisitActions.Deny]: true,
    [VisitActions.Approve]: false,
    [VisitActions.EditCustomer]: true,
    [VisitActions.ManageServices]: true,
  },
  [VisitStatus.Reviewing]: {
    [VisitActions.SendConsent]: false,
    [VisitActions.Deny]: true,
    [VisitActions.Approve]: true,
    [VisitActions.EditCustomer]: true,
    [VisitActions.ManageServices]: true,
  },
  [VisitStatus.Approved]: {
    [VisitActions.SendConsent]: false,
    [VisitActions.Deny]: true,
    [VisitActions.Approve]: false,
    [VisitActions.EditCustomer]: false,
    [VisitActions.ManageServices]: false,
  },
  [VisitStatus.Denied]: {
    [VisitActions.SendConsent]: false,
    [VisitActions.Deny]: false,
    [VisitActions.Approve]: false,
    [VisitActions.EditCustomer]: false,
    [VisitActions.ManageServices]: false,
  },
  [VisitStatus.Completed]: {
    [VisitActions.SendConsent]: false,
    [VisitActions.Deny]: false,
    [VisitActions.Approve]: false,
    [VisitActions.EditCustomer]: false,
    [VisitActions.ManageServices]: false,
  },
};

function customerName(visit) {
  const customerName = `${visit.customerInformation.firstName} ${visit.customerInformation.lastName}`;
  return visit.customerInformation.firstName
    ? customerName
    : visit.customer?.name;
}

const VisitDetails = ({ visit, refetchVisit, onClose: onCloseSheet }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sendDisclosure = useDisclosure();
  const {
    isOpen: isDenyOpen,
    onOpen: onDenyOpen,
    onClose: onDenyClose,
  } = useDisclosure();
  const permissions = visitPermissions[visit.status];

  return (
    <>
      <ReviewVisitFlow
        visit={visit}
        isOpen={isOpen}
        onClose={onClose}
        onCompleted={() => {
          onClose();
          onCloseSheet();
        }}
      />
      <DenyVisitDialogue
        visitId={visit.id}
        isOpen={isDenyOpen}
        onClose={onDenyClose}
      />
      <SendVisitToCustomer
        visitId={visit.id}
        isOpen={sendDisclosure.isOpen}
        onClose={sendDisclosure.onClose}
        onCompleted={() => onCloseSheet()}
      />

      <DrawerHeader padding={0} paddingBottom={0}>
        <Flex
          direction="row"
          alignItems="center"
          padding={5}
          paddingTop={3}
          borderBottom={"2px solid"}
          borderBottomColor="black"
        >
          <Box>
            <Badge variantColor={VisitStatusColor[visit.status]} mr={2}>
              {VisitStatusMessage[visit.status]}
            </Badge>
            {visit.customer?.isMinor && <Badge>Minor</Badge>}

            <Flex
              justifyContent="flex-start"
              alignItems={["flex-start", "center"]}
              flexDirection={["column", "row"]}
            >
              <Heading fontSize={["xl", "2xl"]}>
                {/* TODO: Should we just always use the `customerInformation` name here? */}
                {customerName(visit)}
              </Heading>
              {visit.customer?.isMinor && (
                <Text fontSize={16} fontWeight="normal" ml={[0, 2]}>
                  Guardian: {visit.customer.parent.name}
                </Text>
              )}
            </Flex>

            <Text
              color="darkGrey"
              fontWeight={400}
              fontSize={14}
              marginLeft="auto"
            >
              Created {formatDateTime(visit.insertedAt)}
              {visit.scheduledFor &&
                ` • Scheduled for ${formatDate(visit.scheduledFor)}`}
            </Text>
          </Box>
        </Flex>
      </DrawerHeader>
      <DrawerBody paddingX={0} paddingY={0} overflowY="scroll">
        {visit.status === VisitStatus.Denied && visit.denyReason && (
          <Box padding={5} paddingBottom={1} backgroundColor="lightGrey">
            <InformationRow
              label="Denial Reason"
              labelProps={{ color: "statusRed.500" }}
            >
              <Text>{visit.denyReason}</Text>
            </InformationRow>
          </Box>
        )}
        <Box paddingX={5}>
          {visit.customer?.isMinor && (
            <>
              <BasicInfo
                visit={visit}
                mode="minor"
                customerInformation={visit.customerInformation}
                canEdit={permissions[VisitActions.EditCustomer]}
              />
              <BasicInfo
                visit={visit}
                mode="parent"
                customerInformation={visit.parentCustomerInformation}
                canEdit={permissions[VisitActions.EditCustomer]}
              />
            </>
          )}
          {!visit.customer?.isMinor && (
            <BasicInfo
              visit={visit}
              customerInformation={visit.customerInformation}
              canEdit={permissions[VisitActions.EditCustomer]}
            />
          )}
          <Procedures
            visit={visit}
            services={visit.services}
            refetchVisit={refetchVisit}
            canManageProcedures={permissions[VisitActions.ManageServices]}
            onCloseSheet={onCloseSheet}
          />
          {visit.medicalHistory && <HealthInformation visit={visit} />}
          {visit.consentAnsweredAt && <ConsentAnswers visit={visit} />}
        </Box>
      </DrawerBody>
      {(permissions[VisitActions.Deny] ||
        permissions[VisitActions.Approve]) && (
        <DrawerFooter backgroundColor="black" paddingY={6}>
          <ButtonGroup>
            {permissions[VisitActions.Deny] && (
              <Button variant="danger" marginRight={2} onClick={onDenyOpen}>
                Deny
              </Button>
            )}
            {permissions[VisitActions.Approve] && (
              <Button variant="primary" onClick={onOpen}>
                Continue
              </Button>
            )}
          </ButtonGroup>
        </DrawerFooter>
      )}

      {permissions[VisitActions.SendConsent] && (
        <DrawerFooter backgroundColor="black" paddingY={6}>
          <ButtonGroup>
            <Button variant="danger" marginRight={2} onClick={onCloseSheet}>
              Cancel
            </Button>
            <Button variant="primary" onClick={sendDisclosure.onOpen}>
              Send new consent
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      )}
    </>
  );
};

export default VisitDetails;
