import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ContextState = {
  storeId: string;
  accountId: string;
};

const initialState: ContextState = {
  storeId: null,
  accountId: null,
};

type IdPayload = string | null;

const contextSlice = createSlice({
  name: "context",
  initialState,
  reducers: {
    setStoreId: (state, action: PayloadAction<IdPayload>) => {
      state.storeId = action.payload;
    },
    setAccountId: (state, action: PayloadAction<IdPayload>) => {
      state.accountId = action.payload;
    },
  },
});

// Exported for convienience
export const { setStoreId, setAccountId } = contextSlice.actions;

export default contextSlice;
