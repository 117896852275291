import { includes } from "lodash";

export function isMedicalConcern(concerns, question) {
  if (question === "isGenerallyHealthy") {
    return concerns[question] === false;
  }

  return concerns[question];
}

export function flagMedicalConcern(value, key) {
  switch (key) {
    case "isGenerallyHealthy": {
      return value === false;
    }
    case "additionalNotes": {
      return !includes(["n/a", "na", "none", ""], (value || "").toLowerCase());
    }
    default: {
      return !!value;
    }
  }
}
