import { useQuery, NetworkStatus } from "@apollo/client";
import { Box, Heading, IconButton, Spinner, Flex, Text } from "@chakra-ui/core";
import { useQueryParams, StringParam } from "use-query-params";
import { get, uniq, groupBy, includes } from "lodash";
import { format, endOfDay, startOfDay } from "date-fns";
import useDocumentTitle from "@rehooks/document-title";
import React from "react";
import VisitCard from "../../../../components/VisitCard";
import Button from "../../../../components/Button";
import { VisitEdge, VisitServiceStatus } from "../../../../types";
import { QUERY_VISITS_FOR_STORE } from "../Visits/gql";
import VisitSheet from "../../../../components/VisitSheet";
import ArtistVisitDetails from "../../../../components/VisitSheet/ArtistVisitDetails";
import PageHeader from "../../../../components/PageHeader";
import { determineVisitServiceStatus } from "../../../../constants";

const ActiveStatuses = [
  VisitServiceStatus.Reviewing,
  VisitServiceStatus.Approved,
];

const Visits = ({ store }) => {
  useDocumentTitle(`Visit Queue - ${store.name} - Bodyart`);

  const now = new Date();
  const [query, setQuery] = useQueryParams({
    visitId: StringParam,
  });
  const { visitId } = query;
  const { data, loading, refetch, networkStatus } = useQuery(
    QUERY_VISITS_FOR_STORE,
    {
      pollInterval: parseInt(
        process.env.REACT_APP_POLL_INTERVAL || "10000",
        10,
      ),
      notifyOnNetworkStatusChange: true,
      variables: {
        storeId: store.id,
        filters: {
          scheduledFor: {
            from: startOfDay(now),
            to: endOfDay(now),
          },
        },
        first: 999,
      },
    },
  );

  const visitEdges = get(data, "store.visits.edges", []);
  const totalVisits = visitEdges.length;

  // Right now the visits only have the status from visit itself, not derived
  // from the visit services, which is what the artist should see.
  const visitsWithCorrectStatus = visitEdges.map((visitEdge: VisitEdge) => {
    const visitStatuses = uniq(
      (visitEdge.node.services || []).map((vs) => vs.status),
    );
    const serviceStatus = determineVisitServiceStatus(visitStatuses);

    return { ...visitEdge, node: { ...visitEdge.node, status: serviceStatus } };
  });

  const groupedVisitEdges = groupBy(visitsWithCorrectStatus, (visitEdge) =>
    includes(ActiveStatuses, visitEdge.node.status) ? "active" : "completed",
  );

  const { completed: completedEdges, active: activeEdges } = groupedVisitEdges;

  return (
    <Box
      padding={5}
      backgroundColor="lightGrey"
      marginBottom={5}
      minHeight="100%"
    >
      <PageHeader
        title="Your Queue"
        renderSubHeader={() => (
          <Text color="darkGrey" fontSize="md" fontWeight="bold" marginLeft="2">
            ({format(now, "MM/dd/yyyy")})
          </Text>
        )}
        renderRightControls={() => (
          <IconButton
            icon="repeat"
            aria-label="Refresh"
            variant="ghost"
            size="md"
            fontSize={20}
            onClick={() => refetch()}
            isLoading={networkStatus === NetworkStatus.refetch}
          />
        )}
      />
      <Box paddingY={5} maxWidth={1024} marginX="auto">
        {networkStatus === NetworkStatus.loading && (
          <Spinner color="brandYellow.500" size="sm" />
        )}

        {networkStatus !== NetworkStatus.loading && totalVisits === 0 && (
          <Flex alignItems="center" flexDirection="column">
            <Text textAlign="center">
              There's nothing in your queue right now.
            </Text>

            <Button
              isLoading={networkStatus === NetworkStatus.refetch}
              variant="ghost"
              onClick={() => refetch()}
              marginTop={3}
            >
              Check for new visits
            </Button>
          </Flex>
        )}

        {!loading && totalVisits > 0 && (
          <Box mb={10}>
            <Heading
              size="xs"
              fontWeight="medium"
              textTransform="uppercase"
              color="darkGrey"
              mb={5}
              borderBottom="1px solid"
              borderBottomColor="lightGrey"
            >
              Active ({activeEdges?.length || 0})
            </Heading>

            {activeEdges?.map((edge: VisitEdge) => (
              <VisitCard
                key={edge.node.id}
                visit={edge.node}
                onClick={() => {
                  setQuery({ visitId: edge.node.id }, "push");
                }}
              />
            ))}

            {!activeEdges && (
              <Text>Nothing ready for you in your queue right now.</Text>
            )}
          </Box>
        )}

        {!loading && completedEdges && (
          <Box>
            <Heading
              size="xs"
              fontWeight="medium"
              textTransform="uppercase"
              color="darkGrey"
              mb={5}
            >
              Completed ({completedEdges.length})
            </Heading>

            {completedEdges.map((edge: VisitEdge) => (
              <VisitCard
                key={edge.node.id}
                visit={edge.node}
                onClick={() => {
                  setQuery({ visitId: edge.node.id }, "push");
                }}
              />
            ))}
          </Box>
        )}
      </Box>

      <VisitSheet
        visitId={visitId}
        isOpen={!!visitId}
        DetailsComponent={ArtistVisitDetails}
        onClose={() => setQuery({ visitId: undefined }, "push")}
      />
    </Box>
  );
};

export default Visits;
