import { Flex, FlexProps } from "@chakra-ui/core";
import React, { memo } from "react";

export const Table: React.FC<FlexProps> = memo(function Table({
  children,
  ...rest
}) {
  return <Flex {...rest}>{children}</Flex>;
});

export const TableBody: React.FC<FlexProps> = memo(function TableBody({
  children,
  ...rest
}) {
  return (
    <Flex flex={1} overflowY="auto" flexDirection="column" {...rest}>
      {children}
    </Flex>
  );
});

type TableRowProps = {
  onClick?: () => void;
};

export const TableRow: React.FC<TableRowProps & FlexProps> = memo(
  function TableRow({ children, ...rest }) {
    return (
      <Flex
        flex={1}
        height="48px"
        borderBottomColor="secondaryMidGrey"
        borderBottomWidth={1}
        cursor="pointer"
        {...rest}
      >
        {children}
      </Flex>
    );
  },
);

type TableCellProps = {
  hideOnSmall?: boolean;
};

export const TableCell: React.FC<TableCellProps & FlexProps> = memo(
  function TableCell({ children, hideOnSmall = false, ...rest }) {
    const styles = {
      paddingX: [0, 3],
      flex: 1,
      alignItems: "center",
      flexShrink: 0,
      overflow: "hidden",
      display: [hideOnSmall ? "none" : "flex", "flex"],
    };

    return (
      <Flex {...styles} {...rest}>
        {children}
      </Flex>
    );
  },
);
