import React from "react";
import { Menu, MenuButton, IconButton } from "@chakra-ui/core";

const ItemMenu = ({ children, ...rest }) => {
  return (
    <Menu>
      <MenuButton
        display="flex"
        marginLeft="auto"
        as={IconButton}
        {...rest}
        {...{
          icon: "threeDot",
          size: "sm",
          fontSize: "20px",
          variant: "unstyled",
        }}
      />
      {children}
    </Menu>
  );
};

export default ItemMenu;
