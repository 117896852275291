import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { Spinner, Flex } from "@chakra-ui/core";
import { writeStorage } from "@rehooks/local-storage";
import useDocumentTitle from "@rehooks/document-title";
import useQueryStoreFromSlug from "../../../hooks/useQueryStoreFromSlug";
import MainLayout from "../../../components/MainLayout";
import { Account, StoreEmployeeRole } from "../../../types";
import Visits from "./Visits";
import ArtistQueue from "./ArtistQueue";
import Customers from "./Customers";
import Customer from "./Customer";
import Settings from "./Settings";
import StoreMenu from "./StoreMenu";
import ServiceTypes from "./ServiceTypes";
import ServiceTypeCategories from "./ServiceTypeCategories";
import Services from "./Services";
import Inventory from "./Inventory";
import StoreHeader from "../../../components/StoreHeader";

type StoreParams = {
  account: string;
  store: string;
};

type Props = {
  account: Account;
};

const withStore = (Component, outerProps) => (props) => (
  <Component {...props} {...outerProps} />
);

const StoreRouter: React.FC<Props & RouteComponentProps<StoreParams>> = ({
  match,
  account,
}) => {
  const accountSlug = account.slug;
  const { store: slug } = match.params;
  const { store, loading } = useQueryStoreFromSlug(accountSlug, slug);

  useEffect(() => {
    if (accountSlug && store) {
      // Set this as the last viewed store
      writeStorage("@bodyart/last-account", accountSlug);
      writeStorage("@bodyart/last-store", store.slug);
    }
  }, [accountSlug, store]);

  useDocumentTitle(`${store?.name || "Loading..."} - Bodyart`);

  return (
    <MainLayout
      renderHeader={(props) => (
        <StoreHeader {...props} store={store} storePath={match.url} />
      )}
      renderMenu={(props) =>
        store && <StoreMenu {...props} store={store} account={account} />
      }
    >
      {loading && (
        <Flex justifyContent="center" alignItems="center" flex={1}>
          <Spinner color="brandYellow.500" size="lg" />
        </Flex>
      )}
      {!loading && store && (
        <Switch>
          <Route
            exact
            path={`${match.url}/queue`}
            component={withStore(
              store.context.role === StoreEmployeeRole.Artist
                ? ArtistQueue
                : Visits,
              { store, account },
            )}
          />
          <Route
            exact
            path={`${match.url}/customers`}
            component={withStore(Customers, { store, account })}
          />
          <Route
            exact
            path={`${match.url}/customers/:id`}
            component={withStore(Customer, { store, account })}
          />
          <Route
            exact
            path={`${match.url}/services`}
            component={withStore(ServiceTypes, { store, account })}
          />
          <Route
            exact
            path={`${match.url}/services/:id`}
            component={withStore(ServiceTypeCategories, { store, account })}
          />
          <Route
            exact
            path={`${match.url}/services/:serviceTypeId/:id`}
            component={withStore(Services, { store, account })}
          />
          <Route
            exact
            path={`${match.url}/inventory`}
            component={withStore(Inventory, { store, account })}
          />
          <Route
            exact
            path={`${match.url}/settings`}
            component={withStore(Settings, { store, account })}
          />
          <Redirect to={`${match.url}/queue`} />
        </Switch>
      )}
    </MainLayout>
  );
};

export default StoreRouter;
