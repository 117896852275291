import { Box, Image, Flex, useDisclosure } from "@chakra-ui/core";
import React from "react";
import Button from "../Button";
import IconLibrary from "./IconLibrary";

const SelectServiceIcon = ({ name, onChange, value }) => {
  const disclosure = useDisclosure();

  return (
    <Box>
      <IconLibrary
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        value={value}
        onChange={(icon) => {
          // Fake an onchange event 🙃
          onChange({ target: { name, value: icon } });
        }}
      />

      {value ? (
        <Flex alignItems="flex-end">
          <Box
            padding={2}
            border="1px solid"
            backgroundColor="secondaryMidGrey"
            borderColor="midGrey"
            borderRadius={20}
            width={100}
            height={100}
            mr={2}
          >
            <Image src={value.selectedUrl} width={80} />
          </Box>

          <Button variant="ghostSmall" onClick={disclosure.onOpen} mr={2}>
            Edit
          </Button>
          <Button
            variant="ghostSmall"
            onClick={() => onChange({ target: { name, value: null } })}
          >
            Clear
          </Button>
        </Flex>
      ) : (
        <Button variant="ghostSmall" onClick={disclosure.onOpen}>
          Select icon
        </Button>
      )}
    </Box>
  );
};

export default SelectServiceIcon;
