import React from "react";
import {
  Box,
  ModalBody,
  ModalCloseButton,
  Text,
  Textarea,
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/core";
import { useApolloClient, useMutation } from "@apollo/client";
import PageHeader from "../PageHeader";
import Button from "../Button";
import * as Yup from "yup";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { Header, Section } from "../VisitSheet/Section";
import { Body } from "../Typography";
import FormField from "../FormField";
import InformationRow from "../VisitSheet/InformationRow";
import { VisitServiceStatus, VisitService } from "../../types";
import { visitService as visitServiceFragment } from "../../fragments";
import { APPROVE_PROCEDURE } from "./gql";
import DenyProcedureDialogue from "../VisitSheet/DenyProcedureDialogue";
import { summarizeInventorySelection } from "../../lib/inventory";
import { Features } from "../../constants";
import { serviceName } from "../../lib/services";

const procedureSchema = Yup.object().shape({});

const validationSchema = Yup.object().shape({
  procedures: Yup.array().of(procedureSchema),
});

type Props = {
  history: any;
  visitServiceIds: string[];
  totalSteps: number;
  onClose: () => void;
};

const arePropsEqual = (prev, next) =>
  isEqual(prev.visitServiceIds, next.visitServiceIds);

const ReviewProcedure: React.FC<Props> = React.memo(
  ({ history, visitServiceIds, totalSteps, onClose }) => {
    const toast = useToast();
    const client = useApolloClient();
    const [currentIdx, setCurrentIdx] = React.useState(0);
    const [servicesToReview, setServicesToReviews] = React.useState([]);
    const {
      isOpen: isDenyOpen,
      onOpen: onDenyOpen,
      onClose: onDenyClose,
    } = useDisclosure();

    /*
    These visits can get in weird states, which is why we want to only operate
    on any of the services that have not yet been approved in this flow.

    We read these into state to prevent us from shifting items as we're updating
    them, which can happen when there are multiple services
    */
    React.useEffect(() => {
      const services = visitServiceIds.map((id) =>
        client.readFragment({
          fragment: visitServiceFragment,
          fragmentName: "visitService",
          id: `VisitService:${id}`,
        }),
      );

      const filteredServices = services.filter(
        (vs) => vs.status === VisitServiceStatus.Reviewing,
      );

      setServicesToReviews(filteredServices);
    }, [visitServiceIds, client, setServicesToReviews]);

    const totalVisitServices = servicesToReview.length - 1;
    const visitService = servicesToReview[currentIdx];

    const [approveProcedure, { loading: approving }] = useMutation(
      APPROVE_PROCEDURE,
      {
        onCompleted: ({ result }) => {
          if (!result.errors) {
            if (currentIdx < totalVisitServices) {
              toast({
                title: "Procedure saved",
                status: "success",
                position: "bottom-left",
              });

              setCurrentIdx(currentIdx + 1);
              return;
            }

            toast({
              title: "Visit approved!",
              status: "success",
              position: "bottom-left",
            });
            history.push("/finished");
          }
        },
      },
    );

    if (!visitService) {
      return null;
    }

    const initialValues = {
      procedureId: visitService.id,
      inventoryNotes: visitService.inventoryNotes || "",
      staffNotes: visitService.staffNotes || "",
    };

    return (
      <>
        <DenyProcedureDialogue
          isOpen={isDenyOpen}
          onClose={() => {
            onDenyClose();

            if (currentIdx < totalVisitServices) {
              setCurrentIdx(currentIdx + 1);
              return;
            }

            history.push("/finished");
          }}
          procedures={[visitService]}
        />
        <Formik
          key={visitService.id}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(input) => {
            approveProcedure({ variables: { input } });
          }}
        >
          {(props) => (
            <Flex
              flexDirection="column"
              position="relative"
              overflow="hidden"
              padding={6}
              flex={1}
            >
              <PageHeader
                title="Review Service"
                renderSubHeader={() => (
                  <Text color="darkGrey" fontSize="md" marginLeft="2">
                    Step {totalSteps} of {totalSteps}
                  </Text>
                )}
                renderRightControls={() => (
                  <ModalCloseButton size="lg" right={0} top={-2} />
                )}
              />
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={props.handleSubmit}
                >
                  <Section>
                    <Header
                      title={`Procedure ${currentIdx + 1} - ${serviceName(
                        visitService as VisitService,
                      )}`}
                    />

                    <Flex flexDirection={["column", "row"]}>
                      <Box maxWidth={["100%", "50%"]} flex={1}>
                        <InformationRow label="Service Name">
                          <Body>{visitService.service.displayName}</Body>
                        </InformationRow>

                        {visitService.submittedInformation.map((info) => (
                          <InformationRow label={info.label} key={info.name}>
                            <Body>{info.value || "N/A"}</Body>
                          </InformationRow>
                        ))}

                        {Features.inventorySelectionAndManagement &&
                          visitService.inventorySelections.length > 0 && (
                            <InformationRow label="Inventory">
                              {visitService.inventorySelections.map((is) => (
                                <Text key={is.id}>
                                  {summarizeInventorySelection(is)}
                                </Text>
                              ))}
                            </InformationRow>
                          )}

                        {!Features.inventorySelectionAndManagement && (
                          <InformationRow label="Inventory">
                            <Text>{visitService.inventoryNotes || "N/A"}</Text>
                          </InformationRow>
                        )}
                      </Box>
                      <Box maxWidth={["100%", "50%"]} flex={1}>
                        <FormField
                          name={`staffNotes`}
                          label="Additional Details (optional)"
                        >
                          {(props) => (
                            <Textarea
                              {...props}
                              variant="filled"
                              placeholder="Only visible to staff members"
                            />
                          )}
                        </FormField>
                      </Box>
                    </Flex>
                  </Section>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="danger" mr={3} onClick={onDenyOpen}>
                  Deny
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!props.isValid}
                  isLoading={approving}
                  onClick={props.handleSubmit}
                >
                  {currentIdx < totalVisitServices ? "Approve" : "Finish"}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </>
    );
  },
  arePropsEqual,
);

export default ReviewProcedure;
