import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../../../../../components/Button";
import { useMutation } from "@apollo/client";
import { REMOVE_CONSENT_QUESTION_CATEGORY } from "./gql";
import { Body } from "../../../../../components/Typography";

const RemoveCategoryDialog = ({ categoryId, isOpen, onClose }) => {
  const toast = useToast();
  const [removeCategory, { loading }] = useMutation(
    REMOVE_CONSENT_QUESTION_CATEGORY,
    {
      variables: { input: { categoryId } },
      refetchQueries: ["ConsentQuestionCategories"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast({
          title: "Consent question category has been removed",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      },
    },
  );

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Remove Consent Question Category
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            Are you sure you’d like to remove this consent question category?
            All questions under this category will no longer be accessible, but
            any existing answers recorded on consent forms will be.
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dangerFilled"
            onClick={() => removeCategory()}
            isLoading={loading}
            ml={3}
          >
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveCategoryDialog;
