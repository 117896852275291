import React from "react";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import ReviewIdentity from "./ReviewIdentity";
import ReviewMedicalConcerns from "./ReviewMedicalConcerns";
import ReviewServices from "./ReviewServices";
import Finished from "./Finished";
import InputModal from "../InputModal";

const compose = (Component, p) => (props) => <Component {...p} {...props} />;

const ReviewVisitFlow = ({ visit, onClose, onCompleted, isOpen }) => {
  const screenProps = {
    visit,
    onClose,
    onCompleted,
    visitServiceIds: visit.services.map((vs) => vs.id),
    totalSteps: visit.hasMedicalHistoryToReview ? 3 : 2,
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Router key={`visit-${visit.id}`}>
        <Switch>
          <Route exact path="/" render={compose(ReviewIdentity, screenProps)} />
          <Route
            exact
            path="/medical-concerns"
            render={compose(ReviewMedicalConcerns, screenProps)}
          />
          <Route
            exact
            path="/services"
            render={compose(ReviewServices, screenProps)}
          />
          <Route
            exact
            path="/finished"
            render={compose(Finished, screenProps)}
          />
        </Switch>
      </Router>
    </InputModal>
  );
};

export default ReviewVisitFlow;
