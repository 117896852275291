import { useQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/core";
import useDocumentTitle from "@rehooks/document-title";
import React, { useState } from "react";
import { get } from "lodash";
import { INVENTORY_ITEMS } from "./gql";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/Table";
import ItemMenu from "../../../../components/ItemMenu";
import { formatDate } from "../../../../lib/dates";
import ManageInventoryItem from "./ManageInventoryItem";
import DeleteDialog from "./DeleteDialog";

const Inventory = ({ store }) => {
  useDocumentTitle(`Inventory Items - ${store.name} - Bodyart`);

  const [removingId, setRemovingId] = useState(null);
  const [editing, setEditing] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const { data, loading } = useQuery(INVENTORY_ITEMS, {
    variables: {
      storeId: store.id,
      first: 999,
      filters: {},
    },
  });

  const inventoryItems = get(data, "store.inventoryItems.edges", []);

  return (
    <Box padding={5}>
      <PageHeader title="Inventory" />

      <ManageInventoryItem
        isOpen={!!editing || isCreating}
        store={store}
        inventoryItem={editing}
        onClose={() => {
          setEditing(null);
          setIsCreating(false);
        }}
      />

      <DeleteDialog
        isOpen={!!removingId}
        inventoryItemId={removingId}
        onClose={() => setRemovingId(null)}
      />

      <Box>
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            flex={1}
            padding={5}
          >
            <Spinner color="brandYellow.500" size="md" />
          </Flex>
        )}
        <Table>
          <TableBody>
            {!loading && inventoryItems.length === 0 && (
              <Box py={3}>
                <Text>No inventory items here just yet. Add a new one?</Text>
              </Box>
            )}

            {inventoryItems.map((inventoryItemEdge) => (
              <TableRow key={inventoryItemEdge.node.id} height="auto">
                <TableCell>
                  <Box my={3}>
                    <Heading
                      fontSize={["md", "xl"]}
                      color={inventoryItemEdge.disabledAt ? "midGrey" : "black"}
                    >
                      {inventoryItemEdge.node.name}
                    </Heading>

                    <Text color="darkGrey" fontSize={12}>
                      {inventoryItemEdge.node.services
                        .map((s) => s.displayName)
                        .join(", ")}
                    </Text>
                  </Box>
                </TableCell>
                <TableCell hideOnSmall justifyContent="flex-end">
                  <Text color="midGrey" fontSize={14}>
                    Last Updated:{" "}
                    {formatDate(inventoryItemEdge.node.updatedAt) || "N/A"}
                  </Text>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  flexShrink={0}
                  flexGrow={0}
                  flexBasis={50}
                >
                  <ItemMenu>
                    <MenuList placement="bottom-end">
                      <MenuItem
                        onClick={() => setEditing(inventoryItemEdge.node)}
                      >
                        Edit item
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        color="statusRed.500"
                        onClick={() => setRemovingId(inventoryItemEdge.node.id)}
                      >
                        Remove item
                      </MenuItem>
                    </MenuList>
                  </ItemMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button
          variant="primary"
          position="absolute"
          bottom={5}
          right={5}
          onClick={() => setIsCreating(true)}
        >
          Add new item
        </Button>
      </Box>
    </Box>
  );
};

export default Inventory;
