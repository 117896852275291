import React from "react";
import { Box, BoxProps, Flex, Heading } from "@chakra-ui/core";

type SectionProps = {
  isLast?: boolean;
};

export const Section: React.FC<SectionProps & BoxProps> = ({
  children,
  isLast,
  ...rest
}) => (
  <Box
    paddingTop={6}
    paddingBottom={4}
    borderBottom="2px solid"
    flex={1}
    borderBottomColor={isLast ? "transparent" : "midGrey"}
    {...rest}
  >
    {children}
  </Box>
);

export const Header: React.FC<{ title: string }> = ({ title, children }) => (
  <Flex mb={5} alignItems="center">
    <Heading fontSize={18}>{title}</Heading>
    <Box flex={1}>{children}</Box>
  </Flex>
);
