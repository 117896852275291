import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../Button";
import { useMutation } from "@apollo/client";
import { REMOVE_PROCEDURE } from "./gql";
import { Body } from "../Typography";

const RemoveProcedureDialog = ({
  procedureId,
  refetchVisit,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const [removeProcedure, { loading }] = useMutation(REMOVE_PROCEDURE, {
    variables: { input: { procedureId } },
    onCompleted: () => {
      toast({
        title: "Procedure removed",
        status: "success",
        position: "bottom-left",
      });
      refetchVisit();
      onClose();
    },
  });

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Remove Service
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            Are you sure you'd like to remove this service from the customers
            visit? This process cannot be undone.
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => removeProcedure()}
            isLoading={loading}
            ml={3}
          >
            Remove Service
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveProcedureDialog;
