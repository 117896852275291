import React, { FunctionComponent } from "react";
import {
  Badge,
  Box,
  Flex,
  Icon,
  Image,
  Collapse,
  Button,
} from "@chakra-ui/core";
import { chunk } from "lodash";
import { Body } from "../Typography";
import { Header, Section } from "./Section";
import InformationRow from "./InformationRow";
import { AnsweredConsentQuestion, Visit } from "../../types";

interface ConsentAnswersProps {
  visit: Visit;
}

const ConsentAnswers: FunctionComponent<ConsentAnswersProps> = ({ visit }) => {
  const [show, setShow] = React.useState(false);
  const flaggedConsentAnswers = visit.consentAnswers.filter((a) => a.isFlagged);
  const hasFlaggedAnswers = flaggedConsentAnswers.length > 0;

  return (
    <Section isLast>
      <Header title="Consent Answers">
        {hasFlaggedAnswers && (
          <Badge ml={2} variantColor="red">
            Flagged Answers
          </Badge>
        )}
      </Header>

      <InformationRow label="Signed Consent">
        <Flex alignItems="center">
          <Icon name="tick" size="24px" marginRight={2} />
          <Body>Customer e-signed consent</Body>

          <Button size="sm" onClick={() => setShow(!show)} ml="auto">
            Show {show ? "Less" : "More"}
          </Button>
        </Flex>
      </InformationRow>

      <Collapse mt={2} isOpen={show}>
        {chunk(visit.consentAnswers, 2).map((chunk, idx) => (
          <Box key={idx}>
            {chunk.map((answer: AnsweredConsentQuestion) => (
              <InformationRow label={answer.question} key={answer.questionId}>
                <Body color={answer.isFlagged ? "statusRed.500" : "black"}>
                  {answer.value}
                </Body>
              </InformationRow>
            ))}
          </Box>
        ))}
        {visit.customerSignatureImageUrl && <CustomerSignature visit={visit} />}
      </Collapse>
    </Section>
  );
};

interface CustomerSignatureProps {
  visit: Visit;
}

const CustomerSignature: FunctionComponent<CustomerSignatureProps> = ({
  visit: {
    customerInformation: { firstName, lastName },
    customerSignatureImageUrl,
  },
}) => {
  const customerName = `${firstName} ${lastName}`;
  return (
    <Box d="flex" justifyContent="flex-end" maxW="100%">
      <Box
        d="flex"
        flexDirection="column"
        alignItems="flex-start"
        mt="2"
        maxW="50%"
      >
        <Body>Customer signature</Body>
        <Image
          src={customerSignatureImageUrl}
          alt="customer provided digital signature of consent"
        />
        <Body>{customerName}</Body>
      </Box>
    </Box>
  );
};

export default ConsentAnswers;
