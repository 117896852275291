import {
  ServiceTypeOffering,
  Store,
  StoreAftercare,
  VisitService,
} from "../types";

export function serviceName(visitService: VisitService) {
  if (visitService.service.serviceType.type === ServiceTypeOffering.Tattoo) {
    return `${visitService.service.displayName}`;
  }

  return `${visitService.service.serviceType.displayName} - ${visitService.service.displayName}`;
}

function findAftercareDefaults(
  store: Store,
  serviceType: ServiceTypeOffering,
): StoreAftercare | null {
  return (store.aftercareDefaults || []).find(
    (ad) => ad.serviceType === serviceType,
  );
}

export function defaultAftercareForVisitService(
  visitService: VisitService,
  store: Store,
) {
  const serviceType = visitService.service.serviceType.type;
  const defaultAftercare = findAftercareDefaults(store, serviceType);

  const aftercareInstructions =
    visitService.service.aftercareInstructions ||
    defaultAftercare?.aftercareInstructions ||
    "";

  const externalAftercareInstructionsUrl =
    visitService.service.externalAftercareInstructionsUrl ||
    defaultAftercare?.externalAftercareInstructionsUrl;

  return {
    aftercareInstructions,
    externalAftercareInstructionsUrl,
  };
}
