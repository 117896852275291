import { Box } from "@chakra-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { Account } from "../../../types";
import { MenuOption } from "../../../components/MainLayout";

type Props = {
  account: Account;
};

const AdminMenu: React.FC<Props> = React.memo(({ account }) => {
  const location = useLocation();
  const path = `/${account.slug}/admin`;

  return (
    <Box paddingY={3} width="100%">
      <MenuOption
        title="Stores"
        icon="home"
        to={path + "/stores"}
        isSelected={location.pathname === path + "/stores"}
      />
      <MenuOption
        title="Team"
        icon="user"
        to={path + "/team"}
        isSelected={location.pathname === path + "/team"}
      />
      <MenuOption
        title="Settings"
        icon="settings"
        to={path + "/settings"}
        isSelected={location.pathname === path + "/settings"}
      />
    </Box>
  );
});

export default AdminMenu;
