import React from "react";
import { Heading, Text, Box, Link } from "@chakra-ui/core";
import { useMutation } from "@apollo/client";
import useDocumentTitle from "@rehooks/document-title";
import { Link as RouterLink } from "react-router-dom";
import { RESET_PASSWORD } from "./gql";
import Form, { FormInputs } from "./Form";

const ForgotPassword = () => {
  useDocumentTitle("Forgot Password - Bodyart");

  const [showSuccess, setShowSuccess] = React.useState();
  const [requestReset] = useMutation(RESET_PASSWORD);

  return (
    <Box
      display="flex"
      flex={1}
      height="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor="black"
    >
      <Box maxW="md" rounded="lg" padding={4}>
        <Box mb={4} textAlign="center">
          <Heading as="h1" size="lg" color="white" mb={2}>
            Reset your password
          </Heading>

          {!showSuccess && (
            <Text color="midGrey">
              Forgotten your Bodyart password? You can reset it here. You will
              need access to your email in order to complete this process.
            </Text>
          )}
        </Box>

        {showSuccess ? (
          <Box textAlign="center">
            <Text color="midGrey">
              If a Bodyart account exists for the email you provided, then you
              will have received an email to continue.
            </Text>
          </Box>
        ) : (
          <Form
            onSubmit={async (input: FormInputs, actions) => {
              const {
                data: { result },
              } = await requestReset({ variables: { ...input } });

              if (result.errors) {
                return result.errors.forEach((error) => {
                  actions.setFieldError(error.field, error.message);
                });
              }

              setShowSuccess(true);
            }}
          />
        )}

        <Box textAlign="center" mt={5}>
          <Link
            color="brandYellow.500"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing={1}
            fontSize={12}
            as={RouterLink as any}
            {...{ to: "/login" }}
          >
            Login to your account
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
