import { Flex, IconButton } from "@chakra-ui/core";
import React from "react";

const PaginationControls = ({ pageSize, pageInfo, updatePageInfo }) => (
  <Flex
    alignItems="center"
    position="absolute"
    bottom={0}
    right={0}
    left={0}
    padding={3}
    borderTop="1px solid"
    backgroundColor="white"
    borderTopColor="midGrey"
  >
    <Flex marginLeft="auto">
      <IconButton
        aria-label="Previous page"
        {...{ icon: "leftArrow" as any }}
        variant="unstyled"
        textAlign="center"
        isDisabled={!pageInfo?.hasPreviousPage}
        onClick={() =>
          updatePageInfo({
            before: pageInfo.startCursor,
            after: undefined,
            last: pageSize,
            first: undefined,
          })
        }
      />
      <IconButton
        aria-label="Next page"
        {...{ icon: "rightArrow" as any }}
        variant="unstyled"
        textAlign="center"
        isDisabled={!pageInfo?.hasNextPage}
        onClick={() => {
          updatePageInfo({
            after: pageInfo.endCursor,
            before: undefined,
            first: pageSize,
            last: undefined,
          });
        }}
      />
    </Flex>
  </Flex>
);

export default PaginationControls;
