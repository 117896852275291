import React from "react";
import {
  Checkbox,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
} from "@chakra-ui/core";
import { pickBy, omit } from "lodash";
import PageHeader from "../PageHeader";
import Button from "../Button";
import FormField from "../FormField";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { Header, Section } from "../VisitSheet/Section";
import InformationRow from "../VisitSheet/InformationRow";
import { ACKNOWLEDGE_MEDICAL_HISTORY } from "./gql";
import { Body } from "../Typography";
import { MEDICAL_QUESTIONS } from "../../constants";
import { isMedicalConcern, flagMedicalConcern } from "../../lib/visit";

const schema = Yup.object().shape({
  signature: Yup.boolean()
    .required()
    .equals([true], "You must e-sign to continue"),
});

const ReviewMedicalConcerns = ({ history, visit, onClose, totalSteps }) => {
  const [acknowledgeMedicalHistory, { loading }] = useMutation(
    ACKNOWLEDGE_MEDICAL_HISTORY,
    {
      onCompleted: (data) => {
        if (data.result.visit) {
          history.push("/procedure");
        }
      },
    },
  );

  const visitServiceIds = visit.services.map((vs) => vs.id);
  const medicalHistory = omit(visit.medicalHistory, ["__typename"]);
  const medicalConcerns = pickBy(medicalHistory, flagMedicalConcern);

  return (
    <Formik
      initialValues={{}}
      validationSchema={schema}
      onSubmit={(values) => {
        acknowledgeMedicalHistory({
          variables: { input: { visitId: visit.id, visitServiceIds } },
        });
      }}
    >
      {(props) => (
        <Flex
          flexDirection="column"
          position="relative"
          overflow="hidden"
          padding={6}
          flex={1}
        >
          <PageHeader
            title="Review Health Info"
            renderSubHeader={() => (
              <Text color="darkGrey" fontSize="md" marginLeft="2">
                Step 1 of {totalSteps}
              </Text>
            )}
            renderRightControls={() => (
              <ModalCloseButton size="lg" right={0} top={-2} />
            )}
          />
          <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
            <Flex
              as="form"
              onSubmit={props.handleSubmit}
              flexDirection={["column", "row"]}
            >
              <Section marginRight={[0, 5]} isLast>
                <Header title="Health Info" />

                {medicalConcerns.medicalConditions && (
                  <InformationRow label="Medical History">
                    <Body color="statusRed.500">
                      {medicalConcerns.medicalConditions.join(", ")}
                    </Body>
                  </InformationRow>
                )}

                {Object.keys(MEDICAL_QUESTIONS).map((question) =>
                  isMedicalConcern(medicalConcerns, question) ? (
                    <InformationRow
                      label={MEDICAL_QUESTIONS[question]}
                      key={question}
                    >
                      <Body color="statusRed.500">
                        {medicalConcerns[question] ? "Yes" : "No"}
                      </Body>
                    </InformationRow>
                  ) : null,
                )}

                {medicalConcerns.additionalNotes && (
                  <InformationRow label="Any other medical conditions or notes?">
                    <Body color="statusRed.500">
                      {medicalConcerns.additionalNotes || "N/A"}
                    </Body>
                  </InformationRow>
                )}
              </Section>
              <Section isLast>
                <Header title="Signature"></Header>

                <FormField label="E-Signature" name="signature">
                  {(props) => (
                    <Checkbox
                      {...props}
                      alignItems="flex-start"
                      variantColor="yellow"
                      fontWeight="medium"
                      mr={2}
                    >
                      I sign that I have reviewed these medical concerns and
                      have determined that they, to the best of my knowledge, do
                      not endanger the client for the procedure(s) that we will
                      be performing.
                    </Checkbox>
                  )}
                </FormField>
              </Section>
            </Flex>
          </ModalBody>
          <Flex
            justifyContent="flex-end"
            paddingTop={5}
            borderTop="1px solid"
            borderTopColor="black"
          >
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!props.isValid}
              onClick={props.handleSubmit}
              isLoading={loading}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default ReviewMedicalConcerns;
