import React from "react";
import { Box, Input } from "@chakra-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "../../components/Button";
import FormField from "../../components/FormField";

export type FormInputs = {
  emailAddress: string;
  password: string;
};

const initialValues: FormInputs = {
  emailAddress: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required("Email address is required"),
  password: Yup.string().required("Password is required"),
});

const Form = ({ onSubmit }) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Box as="form" onSubmit={props.handleSubmit} maxW={320} marginX="auto">
          <FormField
            hasRightMargin={false}
            label="Email Address"
            name="emailAddress"
          >
            {(props) => (
              <Input {...props} type="email" placeholder="you@yourstore.com" />
            )}
          </FormField>

          <FormField hasRightMargin={false} label="Password" name="password">
            {(props) => <Input {...props} type="password" />}
          </FormField>

          <Button
            width="100%"
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={!props.isValid}
            type="submit"
            mt={3}
          >
            Login
          </Button>
        </Box>
      )}
    </Formik>
  );
};

export default Form;
