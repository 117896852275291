import React from "react";
import { Flex, Box, Heading, IconButton } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";

type Props = {
  title: string;
  renderSubHeader?: () => JSX.Element;
  renderRightControls?: () => JSX.Element;
  hasBackButton?: boolean;
  onBackClick?: () => void;
};

const PageHeader: React.FC<Props> = ({
  title,
  renderSubHeader,
  renderRightControls,
  hasBackButton,
  onBackClick,
}) => {
  const history = useHistory();

  return (
    <Flex
      borderBottomWidth={1}
      borderBottomColor="black"
      borderBottomStyle="solid"
      flexWrap="wrap"
      position="relative"
      paddingBottom={[0, 4]}
    >
      <Flex alignItems="center" flex={1}>
        {hasBackButton && (
          <Box borderRight="1px solid" borderRightColor="black" mr={3}>
            <IconButton
              aria-label="back"
              variant="unstyled"
              {...{ icon: "leftArrow" as any }}
              fontSize="22px"
              onClick={() => (onBackClick ? onBackClick() : history.goBack())}
            />
          </Box>
        )}
        <Flex
          flexDirection={"row"}
          alignItems={["flex-start", "center"]}
          mb={[4, 0]}
        >
          <Heading fontSize={["xl", "2xl"]}>{title}</Heading>
          {renderSubHeader && renderSubHeader()}
        </Flex>
      </Flex>
      <Flex marginLeft={[0, "auto"]} mb={[4, 0]}>
        {renderRightControls && renderRightControls()}
      </Flex>
    </Flex>
  );
};

export default PageHeader;
