import { Avatar, Box, Flex, Heading } from "@chakra-ui/core";
import React from "react";
import { Body } from "../Typography";

const Services = {
  TATTOO: "Tattoo",
  PIERCING: "Piercing",
  PERMANENT_MAKEUP: "Permanent Makeup",
};

const AssignedArtist = ({ artist }) => {
  return (
    <Flex alignItems="center">
      <Avatar
        size="sm"
        name={artist.user.name}
        src={artist.user.profileImageUrl}
        marginRight={2}
      />
      <Box>
        <Heading size="sm">{artist.user.name}</Heading>
        {artist.artistServices && (
          <Body
            color="darkGrey"
            fontWeight="medium"
            fontSize={14}
            textTransform="capitalize"
          >
            {artist.artistServices.map((s) => Services[s]).join(", ")} Artist
          </Body>
        )}
      </Box>
    </Flex>
  );
};

export default AssignedArtist;
