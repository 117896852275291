import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  Input,
  Text,
  useToast,
} from "@chakra-ui/core";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import FormField from "../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { INVITE } from "./gql";
import InputModal from "../../../../components/InputModal";
import { AccountSeatRole } from "../../../../types";

const initialValues = {
  accountRole: AccountSeatRole.Admin,
  emailAddress: null,
};

const InviteNewAdmin = ({ isOpen, onClose, account }) => {
  const toast = useToast();
  const [invite, { loading }] = useMutation(INVITE, {
    refetchQueries: [],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Member invited to account",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Invite New Account Admin"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            const input = { ...values, accountId: account.id };
            const { data } = await invite({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection="column"
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} maxWidth={["100%", "50%"]}>
                    <FormField name="emailAddress" label="Email">
                      {(props) => (
                        <Input type="email" {...props} variant="filled" />
                      )}
                    </FormField>

                    <Text mt={5} color="darkGrey">
                      {
                        "Once invited, this user will have access to all stores in the account. If you wish to add an employee to a specific store, please do that under Store > Settings > Employees."
                      }
                    </Text>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Invite
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default InviteNewAdmin;
