import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../../components/PageHeader";
import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import InputModal from "../../../../../components/InputModal";
import { useMutation } from "@apollo/client";
import { UPDATE_AFTERCARE } from "./gql";
import { ServiceTypes } from "../../../../../constants";

const schema = Yup.object().shape({
  aftercareInstructions: Yup.string().nullable(),
  externalAftercareInstructionsUrl: Yup.string().url().nullable(),
});

const ManageAftercare = ({ isOpen, onClose, aftercare }) => {
  const toast = useToast();
  const [updateAftercare, { loading }] = useMutation(UPDATE_AFTERCARE, {
    refetchQueries: [],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Aftercare updated",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = {
    storeAftercareId: aftercare?.id,
    aftercareInstructions: aftercare?.aftercareInstructions,
    externalAftercareInstructionsUrl:
      aftercare?.externalAftercareInstructionsUrl,
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title={`Update ${ServiceTypes[aftercare?.serviceType]} Aftercare`}
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (input, actions) => {
            const { data } = await updateAftercare({ variables: { input } });

            if (data.result.errors) {
              // TODO: handle errors
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} width={["100%", "50%"]}>
                    <FormField
                      name="aftercareInstructions"
                      label="Aftercare Instructions"
                    >
                      {(props) => (
                        <Textarea
                          {...props}
                          placeholder="Please write some notes for the customer to use while healing..."
                          variant="filled"
                          height={180}
                        />
                      )}
                    </FormField>
                    <FormField
                      name="externalAftercareInstructionsUrl"
                      label="External Instructions URL"
                    >
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageAftercare;
