import { gql } from "@apollo/client";
import { customerInformation, pageInfo } from "../../../../fragments";

export const CUSTOMERS = gql`
  query CustomersForStore(
    $storeId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filters: StoreCustomerFilters
  ) {
    store: node(id: $storeId) {
      id
      ... on Store {
        customers(
          first: $first
          last: $last
          before: $before
          after: $after
          filterBy: $filters
        ) {
          edges {
            node {
              id
              customer {
                ...customerInformation
              }
              lastVisitAt
            }
            cursor
          }
          pageInfo {
            ...pageInfo
          }
        }
      }
    }
  }
  ${customerInformation}
  ${pageInfo}
`;
