import { gql } from "@apollo/client";
import { User } from "../../fragments";

export const LOGIN_VIA_EMAIL = gql`
  mutation LoginViaEmail($input: LoginInput!) {
    result: login(input: $input) {
      errors {
        message
        field
      }
      session {
        token
        refreshToken
        user {
          ...User
        }
      }
    }
  }
  ${User}
`;
