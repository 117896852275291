import React from "react";
import {
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  useToast,
  Input,
} from "@chakra-ui/core";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import FormField from "../../components/FormField";
import { setAuthFromSession } from "../../state/auth";
import { Session } from "../../types";
import { SIGNUP_USER } from "./gql";
import InputModal from "../../components/InputModal";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Firstname is required"),
  lastName: Yup.string().required("Lastname is required"),
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match",
  ),
});

function buildPayloadFromSession(session: Session) {
  return {
    userId: session.user.id,
    token: session.token,
    refreshToken: session.refreshToken,
  };
}

const SetupAccount = ({ emailAddress, onOpen, onClose, isOpen }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [signupUser, { loading }] = useMutation(SIGNUP_USER);

  const initialValues = {
    emailAddress,
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (input, actions) => {
          const {
            data: { result },
          } = await signupUser({ variables: { input } });

          if (result.errors) {
            return result.errors.forEach((error) => {
              actions.setFieldError(error.field, error.message);
            });
          }

          toast({
            title: "Your Bodyart account has been created",
            status: "success",
            position: "bottom-left",
          });

          const sessionPayload = buildPayloadFromSession(result.session);
          dispatch(setAuthFromSession(sessionPayload));
          onClose();
        }}
      >
        {(props) => (
          <Flex
            flexDirection="column"
            position="relative"
            overflow="hidden"
            padding={6}
            flex={1}
          >
            <PageHeader
              title="Account Setup"
              renderRightControls={() => (
                <ModalCloseButton size="lg" right={0} top={-2} />
              )}
            />
            <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
              <Flex
                as="form"
                flexDirection={["column", "row"]}
                onSubmit={props.handleSubmit}
              >
                <Box width={["100%", "50%"]} paddingY={5}>
                  <FormField label="Email Address" name="emailAddress">
                    {(props) => <Input type="email" isDisabled {...props} />}
                  </FormField>
                  <FormField label="First name" name="firstName">
                    {(props) => <Input {...props} />}
                  </FormField>
                  <FormField label="Last name" name="lastName">
                    {(props) => <Input {...props} />}
                  </FormField>
                  <FormField label="Password" name="password">
                    {(props) => <Input type="password" {...props} />}
                  </FormField>
                  <FormField
                    label="Confirm Password"
                    name="passwordConfirmation"
                  >
                    {(props) => <Input type="password" {...props} />}
                  </FormField>
                </Box>
              </Flex>
            </ModalBody>
            <Flex
              justifyContent="flex-end"
              paddingTop={5}
              borderTop="1px solid"
              borderTopColor="black"
            >
              <Button variant="danger" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                isLoading={loading}
                disabled={!props.isValid}
                onClick={props.handleSubmit}
              >
                Create Account
              </Button>
            </Flex>
          </Flex>
        )}
      </Formik>
    </InputModal>
  );
};

export default SetupAccount;
