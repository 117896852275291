import React from "react";
import { Button as ChakraButton } from "@chakra-ui/core";

const variantStyles = {
  primary: {
    backgroundColor: "brandYellow.500",
    variantColor: "brandYellow",
    color: "black",
  },
  ghost: {
    variant: "outline",
    borderColor: "brandYellow.500",
    borderWidth: 2,
    color: "black",
  },
  ghostSmall: {
    height: "2rem",
    fontSize: 12,
    paddingX: 3,
    variant: "outline",
    borderColor: "brandYellow.500",
    borderWidth: 2,
    color: "black",
  },
  danger: {
    variant: "outline",
    borderWidth: 2,
    variantColor: "statusRed",
    _hover: {
      backgroundColor: "statusRed.500",
      borderColor: "statusRed.500",
      color: "#fff",
    },
  },
  dangerFilled: {
    backgroundColor: "statusRed.500",
    variantColor: "statusRed",
    color: "white",
  },
  default: {
    backgroundColor: "secondaryMidGrey",
    color: "black",
    _hover: {
      backgroundColor: "midGrey",
    },
  },
};

const Button = ({ children, variant, ...rest }) => {
  return (
    <ChakraButton
      borderRadius={60}
      textTransform="uppercase"
      fontSize={[12, 14]}
      paddingX={5}
      letterSpacing={1}
      {...rest}
      {...variantStyles[variant]}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
