import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  Checkbox,
  ModalBody,
  ModalCloseButton,
  useToast,
  Textarea,
  Select,
  Input,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../../components/PageHeader";
import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { CREATE_CONSENT_QUESTION, UPDATE_CONSENT_QUESTION } from "./gql";
import InputModal from "../../../../../components/InputModal";
import { ConsentQuestionType } from "../../../../../types";

const validationSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  type: Yup.string().required("Type is required"),
});

enum Mode {
  Creating = "CREATING",
  Updating = "UPDATING",
}

const Mutations = {
  [Mode.Creating]: CREATE_CONSENT_QUESTION,
  [Mode.Updating]: UPDATE_CONSENT_QUESTION,
};

const Types = {
  [ConsentQuestionType.Boolean]: "Checkbox",
  [ConsentQuestionType.Text]: "Text",
  [ConsentQuestionType.YesNo]: "Yes / No",
};

const ManageConsentQuestion = ({
  isOpen,
  onClose,
  store,
  category,
  consentQuestion,
}) => {
  const toast = useToast();
  const mode = consentQuestion ? Mode.Updating : Mode.Creating;
  const mutation = Mutations[mode];

  const [performMutation, { loading }] = useMutation(mutation, {
    refetchQueries: ["ConsentQuestionCategories"],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: `Consent question ${
            mode === Mode.Creating ? "created" : "updated"
          }`,
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = consentQuestion
    ? {
        consentQuestionId: consentQuestion.id,
        question: consentQuestion.question,
        type: consentQuestion.type,
        isRequired: consentQuestion.isRequired,
        flagVisitWhenEqual: consentQuestion.flagVisitWhenEqual,
        index: consentQuestion.index,
      }
    : {
        categoryId: category.id,
        storeId: store.id,
        question: "",
        type: "",
        isRequired: false,
        flagVisitWhenEqual: "",
        index: 0,
      };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Manage Consent Question"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await performMutation({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} width={["100%", "50%"]}>
                    <FormField name="question" label="Consent Question">
                      {(props) => (
                        <Textarea {...props} variant="filled" height={200} />
                      )}
                    </FormField>
                    <FormField name="type" label="Type">
                      {(props) => (
                        <Select {...props}>
                          <option>Select a type</option>
                          {Object.keys(Types).map((type) => (
                            <option key={type} value={type}>
                              {Types[type]}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                    <FormField name="isRequired" label="Required field?">
                      {(props) => (
                        <Checkbox
                          {...props}
                          defaultIsChecked={!!formProps.values.isRequired}
                          alignItems="flex-start"
                          variantColor="yellow"
                          fontWeight="medium"
                          mr={2}
                        >
                          Is this field required to submit the consent?
                        </Checkbox>
                      )}
                    </FormField>
                    <FormField
                      name="flagVisitWhenEqual"
                      label="Flag if the value is equal to:"
                    >
                      {(props) => <Input {...props} />}
                    </FormField>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageConsentQuestion;
