import React from "react";
import {
  ModalBody,
  Flex,
  Checkbox,
  Box,
  Text,
  Input,
  ModalCloseButton,
} from "@chakra-ui/core";
import Button from "../Button";
import { Formik } from "formik";
import PageHeader from "../PageHeader";
import FormField from "../FormField";
import { useMutation, useQuery } from "@apollo/client";
import { LOOKUP_CUSTOMER, CREATE_VISIT } from "./gql";
import { useHistory } from "react-router-dom";

const LookupCustomer = ({ store, onVisitCreated, onClose }) => {
  const history = useHistory();

  const [createVisit, { loading: isCreatingVisit }] = useMutation(
    CREATE_VISIT,
    {
      onCompleted: ({ result }) => {
        if (result?.visit?.id) {
          onVisitCreated(result.visit);
        }
      },
    },
  );

  const { loading: isLookingUpCustomer, refetch: lookupCustomer } = useQuery(
    LOOKUP_CUSTOMER,
    {
      skip: true,
      variables: { storeId: store.id, emailAddress: "", phoneNumber: "" },
    },
  );

  const initialValues = {
    storeId: store.id,
    emailAddress: "",
    phoneNumber: "",
    currentlyInStore: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (input) => {
        const { data } = await lookupCustomer({
          storeId: store.id,
          emailAddress: input.emailAddress,
          phoneNumber: input.phoneNumber,
        });

        const customer = data?.store?.lookupCustomer;

        if (customer) {
          // We found a customer, so lets create a new visit for them
          await createVisit({
            variables: {
              input: {
                storeId: store.id,
                customerId: customer.id,
                scheduledFor: input.currentlyInStore ? new Date() : null,
              },
            },
          });
        } else {
          // No customer, capture basic information to create the new visit
          history.push("/new-customer", input);
        }
      }}
    >
      {(formProps) => (
        <Flex
          flexDirection="column"
          position="relative"
          overflow="hidden"
          padding={6}
          flex={1}
        >
          <PageHeader
            title="New Consent Form"
            renderRightControls={() => (
              <ModalCloseButton size="lg" right={0} top={-2} />
            )}
          />
          <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
            <Flex
              as="form"
              flexDirection={["column", "row"]}
              onSubmit={formProps.handleSubmit}
            >
              <Box paddingY={5} maxWidth={["100%", "50%"]} flex={1}>
                <FormField name="emailAddress" label="Email address">
                  {(props) => (
                    <Input {...props} type="email" variant="filled" />
                  )}
                </FormField>

                <Box
                  mb={8}
                  mr={5}
                  textAlign="center"
                  borderBottom="1px solid"
                  borderBottomColor="midGrey"
                  position="relative"
                >
                  <Text
                    backgroundColor="white"
                    display="inline-block"
                    marginX="auto"
                    position="relative"
                    top="12px"
                    paddingX={5}
                    lineHeight="14px"
                    fontSize="14px"
                  >
                    or
                  </Text>
                </Box>

                <FormField name="phoneNumber" label="Phone number">
                  {(props) => <Input {...props} variant="filled" />}
                </FormField>

                <Box
                  borderTop="1px solid"
                  borderTopColor="midGrey"
                  pt={5}
                  mt={8}
                  mr={5}
                >
                  <FormField name="currentlyInStore" label="Add to queue?">
                    {(props) => (
                      <Checkbox
                        {...props}
                        isChecked={props.value}
                        alignItems="center"
                        variantColor="yellow"
                        display="flex"
                      >
                        <Text color="black" fontSize={16}>
                          Customer is currently in the store
                        </Text>
                      </Checkbox>
                    )}
                  </FormField>
                </Box>
              </Box>
            </Flex>
          </ModalBody>
          <Flex
            justifyContent="flex-end"
            paddingTop={5}
            borderTop="1px solid"
            borderTopColor="black"
          >
            <Button variant="ghost" onClick={onClose} mr={2}>
              Close
            </Button>

            <Button
              variant="primary"
              type="submit"
              isLoading={isLookingUpCustomer || isCreatingVisit}
              disabled={!formProps.isValid}
              onClick={formProps.handleSubmit}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default LookupCustomer;
