import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../../../../components/Button";
import { useMutation } from "@apollo/client";
import { REMOVE_SEAT } from "./gql";
import { Body } from "../../../../components/Typography";

const RemoveSeatDialog = ({ seatId, isOpen, onClose }) => {
  const toast = useToast();
  const [removeEmployee, { loading }] = useMutation(REMOVE_SEAT, {
    variables: { input: { seatId } },
    refetchQueries: ["MembersForAccount"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast({
        title: "Employee has been removed",
        status: "success",
        position: "bottom-left",
      });
      onClose();
    },
  });

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Remove Seat
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            Are you sure you’d like to remove this seat from the account?
            They'll no longer have access, but any visits performed by them will
            still exist.
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dangerFilled"
            onClick={() => removeEmployee()}
            isLoading={loading}
            ml={3}
          >
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveSeatDialog;
