import { Flex, Box, Link } from "@chakra-ui/core";
import React from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import {
  Account,
  AccountSeatRole,
  Store,
  StoreViewerContext,
} from "../../../types";
import { MenuOption } from "../../../components/MainLayout";
import { Features } from "../../../constants";

type Props = {
  account: Account;
  store: Store;
  hideMenu: () => void;
};

const hasPermission = (context: StoreViewerContext, permission) =>
  context.permissions.includes(permission);

const StoreMenu: React.FC<Props> = React.memo(
  ({ account, store, hideMenu }) => {
    const location = useLocation();
    const storePath = `/${account.slug}/stores/${store.slug}`;

    return (
      <Flex
        flexDirection="column"
        paddingY={3}
        paddingBottom={0}
        width="100%"
        height="100%"
      >
        {hasPermission(store.context, "store:list_visits") && (
          <MenuOption
            title="Dashboard"
            icon="home"
            to={storePath + "/queue"}
            isSelected={location.pathname === storePath + "/queue"}
            onClick={hideMenu}
          />
        )}
        {hasPermission(store.context, "store:list_customers") && (
          <MenuOption
            title="Customers"
            icon="user"
            to={storePath + "/customers"}
            isSelected={location.pathname.startsWith(storePath + "/customers")}
            onClick={hideMenu}
          />
        )}
        {hasPermission(store.context, "store:manage_services") && (
          <MenuOption
            title="Services"
            icon="services"
            to={storePath + "/services"}
            isSelected={location.pathname.startsWith(storePath + "/services")}
            onClick={hideMenu}
          />
        )}

        {Features.inventorySelectionAndManagement &&
          hasPermission(store.context, "store:manage_inventory") && (
            <MenuOption
              title="Inventory"
              icon="inventory"
              to={storePath + "/inventory"}
              isSelected={location.pathname === storePath + "/inventory"}
              onClick={hideMenu}
            />
          )}

        {hasPermission(store.context, "store:list_inventory_logs") && (
          <MenuOption
            title="Settings"
            icon="settings"
            to={storePath + "/settings"}
            isSelected={location.pathname === storePath + "/settings"}
            onClick={hideMenu}
          />
        )}

        {account.context.role === AccountSeatRole.Admin && (
          <Box marginTop="auto">
            <Link
              as={RouterLink as any}
              {...{ to: `/${account.slug}/admin` }}
              color="darkGrey"
            >
              Manage Account
            </Link>
          </Box>
        )}
      </Flex>
    );
  },
);

export default StoreMenu;
