import React from "react";
import { Box, Flex, ModalBody, Textarea } from "@chakra-ui/core";
import Button from "../Button";
import { get, keyBy, chunk, omit } from "lodash";
import { Formik } from "formik";
import { Section } from "../VisitSheet/Section";
import SelectFromData from "../SelectFromData";
import FormField from "../FormField";
import {
  ServiceType,
  ServiceTypeOffering,
  Store,
  StoreEmployeeRole,
} from "../../types";
import { EMPLOYEES_FOR_STORE } from "../ReviewVisitFlow/gql";
import { useQuery } from "@apollo/client";
import ServiceInformationField from "./ServiceInformationField";
import { filterDisabledServices, selectedServices } from "./selectedServices";

type FormValues = {
  visitServiceType: string | null;
  serviceTypeCategoryId: string | null;
  serviceId: string | null;
  assignedEmployeeId: string | null;
  submittedInformation: {
    [k: string]: string;
  };
};

const defaultInitialValues = {
  visitServiceType: null,
  serviceTypeCategoryId: null,
  serviceId: null,
  assignedEmployeeId: null,
  submittedInformation: {},
  inventorySelections: [],
  inventoryNotes: "",
};

type Props = {
  onClose: () => void;
  onSubmit: (values) => void;
  isLoading: boolean;
  serviceTypes: ServiceType[];
  store: Store;
  initialValues?: FormValues;
  canManageServiceType?: boolean;
  mode?: "create" | "edit";
};

const ManageProcedureForm: React.FC<Props> = React.memo(
  ({
    store,
    onClose,
    isLoading,
    onSubmit,
    serviceTypes,
    initialValues = defaultInitialValues,
    canManageServiceType = true,
    mode = "create",
  }) => {
    const serviceTypesById = keyBy(
      filterDisabledServices(serviceTypes),
      (st) => st.id,
    );

    const { data } = useQuery(EMPLOYEES_FOR_STORE, {
      variables: {
        storeId: store.id,
        first: 999,
        filters: {
          roles: [StoreEmployeeRole.Artist],
        },
      },
    });

    const employeeEdges = get(data, "store.employees.edges", []);
    const employees = employeeEdges.map((edge) => edge.node);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const { selectedService } = selectedServices(
            serviceTypesById,
            values,
          );

          let input = omit(values, [
            "visitServiceType",
            "serviceTypeCategoryId",
          ]);

          input.submittedInformation = Object.keys(
            values.submittedInformation,
          ).map((key) => {
            const field = selectedService?.informationFields.find(
              (i) => i.name === key,
            );

            return {
              name: field.name,
              label: field.label,
              value: input.submittedInformation[key],
            };
          });

          onSubmit(input);
        }}
      >
        {(props) => {
          const {
            selectedServiceType,
            selectedCategory,
            selectedService,
          } = selectedServices(serviceTypesById, props.values);

          const isPiercingService =
            selectedService &&
            selectedService.serviceType.type === ServiceTypeOffering.Piercing;

          return (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection="column"
                  onSubmit={props.handleSubmit}
                >
                  <Section flex="none">
                    <Box maxWidth={["100%", "50%"]}>
                      <FormField name="visitServiceType" label="Service Type">
                        {SelectFromData({
                          items: Object.values(serviceTypesById),
                          placeholder: "Select a service type",
                          disabled: !canManageServiceType,
                        })}
                      </FormField>
                    </Box>
                  </Section>

                  {selectedServiceType && (
                    <>
                      <Section flex="none">
                        <Flex>
                          <FormField
                            name="serviceTypeCategoryId"
                            label="Category"
                          >
                            {SelectFromData({
                              items: selectedServiceType.categories,
                              placeholder: "Select a category",
                            })}
                          </FormField>

                          <FormField name="serviceId" label="Service">
                            {SelectFromData({
                              items:
                                selectedCategory?.services.map(
                                  (stcs) => stcs.service,
                                ) || [],
                              placeholder: "Select a service",
                            })}
                          </FormField>
                        </Flex>

                        {selectedService &&
                          chunk(selectedService.informationFields, 2).map(
                            (chunk, i) => (
                              <Flex justifyContent="space-between">
                                {chunk.map((field, j) => (
                                  <>
                                    <FormField
                                      name={`submittedInformation.${field.name}`}
                                      label={field.label}
                                      validate={(value) => {
                                        // We define a custom validator as we can't do this
                                        // dynamically on the validation schema
                                        return field.isRequired &&
                                          (!value || value === "")
                                          ? `${field.name} is required`
                                          : null;
                                      }}
                                    >
                                      {ServiceInformationField(field)}
                                    </FormField>
                                  </>
                                ))}
                              </Flex>
                            ),
                          )}

                        {isPiercingService && (
                          <Box maxW={["100%", "50%"]} flex={1}>
                            <FormField name="inventoryNotes" label="Inventory">
                              {(props) => (
                                <Textarea
                                  {...props}
                                  placeholder="Inventory used during this procedure (the customer will see this)"
                                  variant="filled"
                                />
                              )}
                            </FormField>
                          </Box>
                        )}
                      </Section>
                      <Section flex="none" isLast>
                        <Box maxWidth={["100%", "50%"]}>
                          <FormField
                            name="assignedEmployeeId"
                            label="Select Artist"
                          >
                            {SelectFromData({
                              items: employees,
                              placeholder: "Select an artist",
                              nameSelector: (e) => e.user.name,
                            })}
                          </FormField>
                        </Box>
                      </Section>
                    </>
                  )}
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={isLoading}
                  disabled={!props.isValid}
                  onClick={props.handleSubmit}
                >
                  {mode === "create" ? "Add Service" : "Save Changes"}
                </Button>
              </Flex>
            </>
          );
        }}
      </Formik>
    );
  },
);

export default ManageProcedureForm;
