import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { User } from "../types";
import { RootState } from "../state";
import { CURRENT_USER } from "../screens/RequireAuth";

export default function useCurrentUser() {
  const [user, setUser] = useState<User | null>(null);
  const userId = useSelector((state: RootState) => state.auth.userId);
  const client = useApolloClient();

  useEffect(() => {
    const result = client.readQuery({ query: CURRENT_USER });

    if (result?.viewer) {
      setUser(result.viewer);
    }
  }, [client, userId, setUser]);

  return user;
}
