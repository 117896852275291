import { useQuery } from "@apollo/client";
import { Box, Flex, Heading, Spinner } from "@chakra-ui/core";
import { useQueryParams, StringParam } from "use-query-params";
import React from "react";
import { get } from "lodash";
import { VISITS_FOR_CUSTOMER } from "./gql";
import VisitCard from "../../../../components/VisitCard";
import VisitSheet from "../../../../components/VisitSheet";

const Visits = ({ contactId }) => {
  const { data, loading } = useQuery(VISITS_FOR_CUSTOMER, {
    variables: { customerId: contactId, first: 1000 },
  });
  const [query, setQuery] = useQueryParams({
    visitId: StringParam,
  });
  const { visitId } = query;
  const visits = get(data, "contact.visits.edges", []);

  return (
    <Box>
      {loading && <Spinner color="brandYellow.500" />}

      {!loading && visits.length === 0 && (
        <Flex
          backgroundColor="lightGrey"
          borderWidth={1}
          borderColor="darkGrey"
          borderRadius={5}
          minHeight={165}
          alignItems="center"
          justifyContent="center"
        >
          <Heading textAlign="center" size="md" color="darkGrey">
            No Consent Forms
          </Heading>
        </Flex>
      )}

      {!loading &&
        visits &&
        visits.map((visitEdge) => (
          <VisitCard
            key={visitEdge.node.id}
            visit={visitEdge.node}
            hideCustomerDetails={true}
            onClick={() => setQuery({ visitId: visitEdge.node.id }, "push")}
            isCondensed
          />
        ))}

      <VisitSheet
        visitId={visitId}
        isOpen={!!visitId}
        onClose={() => setQuery({ visitId: undefined }, "push")}
      />
    </Box>
  );
};

export default Visits;
