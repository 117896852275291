import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Flex, Text, Heading } from "@chakra-ui/core";
import MainLayout from "../../components/MainLayout";
import useCurrentUser from "../../hooks/useCurrentUser";
import Accounts from "./Accounts";

const StaffHeader = () => (
  <Link to="/staff">
    <Text color="white" fontSize="lg" fontWeight="black">
      Bodyart Staff
    </Text>
  </Link>
);

const StaffRouter = ({ match }) => {
  const currentUser = useCurrentUser();
  const isStaff = currentUser && currentUser.isStaff;

  return (
    <MainLayout renderHeader={StaffHeader}>
      {isStaff ? (
        <Switch>
          <Route path={`${match.url}/accounts`} component={Accounts} />
          <Redirect to={`${match.url}/accounts`} />
        </Switch>
      ) : (
        <Flex>
          <Heading>Not authorized</Heading>
        </Flex>
      )}
    </MainLayout>
  );
};

export default StaffRouter;
