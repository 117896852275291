import { gql, useQuery } from "@apollo/client";
import { Spinner, Flex } from "@chakra-ui/core";
import * as Sentry from "@sentry/react";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { RootState } from "../state";
import { fullAccount, User } from "../fragments";

export const CURRENT_USER = gql`
  query CurrentUser {
    viewer {
      ...User
      accounts {
        ...fullAccount
      }
    }
  }
  ${User}
  ${fullAccount}
`;

const RequireAuth = (props) => {
  const history = useHistory();
  const token = useSelector((state: RootState) => state.auth.token);
  const location = useLocation();

  const loginParams = {
    next: location.pathname,
  };

  const { loading } = useQuery(CURRENT_USER, {
    skip: !token,
    onCompleted: ({ viewer }) => {
      if (viewer) {
        // Set initial context
        Sentry.setUser({ email: viewer.emailAddress, id: viewer.id });
      }
    },
    onError: () => history.push("/login", loginParams),
  });

  if (!token) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: loginParams,
        }}
      />
    );
  }

  if (loading) {
    return (
      <Flex
        height="100%"
        backgroundColor="lightGrey"
        flex={1}
        alignItems="center"
        justifyContent="center"
      >
        <Flex backgroundColor="brandYellow.500" padding={5} borderRadius={5}>
          <Spinner color="white" size="lg" />
        </Flex>
      </Flex>
    );
  }

  return props.children;
};

export default RequireAuth;
