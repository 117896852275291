import { gql } from "@apollo/client";
import { storeInformation, User, fullAccount } from "../../fragments";

export const INVITE = gql`
  query StoreEmployeeInvite($token: String!) {
    employeeInvite(token: $token) {
      id
      store {
        ...storeInformation
      }
      emailAddress
    }
  }
  ${storeInformation}
`;

export const REALIZE_INVITE = gql`
  mutation RealizeInviteToStore($input: RealizeInviteToStoreInput!) {
    result: realizeInviteToStore(input: $input) {
      storeEmployee {
        id
        roles
        artistServices
        user {
          ...User
          accounts {
            ...fullAccount
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${User}
  ${fullAccount}
`;

export const SIGNUP_USER = gql`
  mutation SignupUser($input: SignupUserInput!) {
    result: signupUser(input: $input) {
      session {
        token
        refreshToken
        user {
          ...User
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${User}
`;
