import React, { useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Spinner,
} from "@chakra-ui/core";
import { useLazyQuery } from "@apollo/client";
import { VISIT } from "./gql";
import VisitDetails from "./VisitDetails";

const VisitSheet = ({
  visitId,
  DetailsComponent = VisitDetails,
  isOpen,
  onClose,
}) => {
  const [fetchVisit, { data, loading, refetch }] = useLazyQuery(VISIT);

  useEffect(() => {
    if (visitId) {
      fetchVisit({ variables: { visitId } });
    }
  }, [visitId, fetchVisit]);

  const visit = data?.visit;

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay height="100%" />
        <DrawerContent height="100%!important">
          <DrawerCloseButton size="lg" />
          {loading && (
            <Box padding={5}>
              <Spinner size="lg" color="brandYellow.500" />
            </Box>
          )}

          <Box
            height="100%"
            backgroundColor="white"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            {!loading && visit && (
              <DetailsComponent
                visit={visit}
                refetchVisit={refetch}
                onClose={onClose}
              />
            )}
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default VisitSheet;
