import { Badge, Box, Flex, Heading, Text } from "@chakra-ui/core";
import React from "react";
import { groupBy, uniq } from "lodash";
import { Visit } from "../../types";
import { VisitStatusMessage, VisitStatusColor } from "../../constants";
import VisitService from "./VisitServices";
import { formatRelative } from "../../lib/dates";

type Props = {
  visit: Visit;
  onClick: () => void;
  isCondensed?: boolean;
  isSelected?: boolean;
  hideCustomerDetails?: boolean;
};

const toArtists = (services) => {
  const artists = services
    .map((vs) => vs.assignedEmployee)
    .filter((ae) => !!ae);

  return uniq(artists);
};

function condensedStyles(isCondensed, styles) {
  return isCondensed ? styles[0] : styles;
}

const VisitCard: React.FC<Props> = ({
  visit,
  onClick,
  hideCustomerDetails = false,
  isSelected = false,
  isCondensed,
}) => {
  const groupedServices = groupBy(
    visit.services || [],
    (visitService) => visitService.service.serviceType.type,
  );

  const groupedServiceKeys = Object.keys(groupedServices);
  const totalGroupedServices = groupedServiceKeys.length;
  const visitDate = visit.scheduledFor || visit.insertedAt;

  return (
    <Flex
      backgroundColor="white"
      borderRadius={2}
      padding={4}
      paddingTop={2}
      paddingBottom={3}
      marginBottom={4}
      border="1px solid"
      borderColor={isSelected ? "brandYellow.500" : "transparent"}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1);"
      position="relative"
      cursor="pointer"
      onClick={onClick}
      flexDirection="column"
      marginX="auto"
    >
      <Flex marginBottom={2}>
        <Box>
          <Badge variantColor={VisitStatusColor[visit.status]}>
            {VisitStatusMessage[visit.status]}
          </Badge>

          {visit.hasMedicalHistoryToReview && (
            <Badge ml={2}>Medical Concerns</Badge>
          )}

          {visit.customer.isMinor && <Badge ml={2}>Minor</Badge>}
        </Box>

        {visitDate && (
          <Text color="midGrey" fontSize={14} marginLeft="auto">
            {formatRelative(visitDate)}
          </Text>
        )}
      </Flex>

      <Flex flexDirection={condensedStyles(isCondensed, ["column", "row"])}>
        {!hideCustomerDetails && (
          <Box
            borderRight="1px solid"
            borderRightColor={condensedStyles(isCondensed, [
              "transparent",
              "secondaryMidGrey",
            ])}
            paddingRight={10}
            minWidth={condensedStyles(isCondensed, ["none", "220px"])}
          >
            {visit.customer.isMinor && (
              <Text fontSize={14} fontWeight={500}>
                Guardian: {visit.customer.parent.name}
              </Text>
            )}

            <Heading fontSize="lg">
              {visit.customer.firstName} {visit.customer.lastName}
            </Heading>
            <Text color="darkGrey" fontSize="md">
              {visit.customer.emailAddress}
            </Text>
          </Box>
        )}
        <Flex
          paddingX={condensedStyles(isCondensed, [0, 5])}
          borderTop={condensedStyles(isCondensed, ["1px solid", null])}
          borderTopColor={condensedStyles(isCondensed, [
            hideCustomerDetails ? "transparent" : "secondaryMidGrey",
            "transparent",
          ])}
          flex={1}
          marginTop={condensedStyles(isCondensed, [
            hideCustomerDetails ? 0 : 3,
            0,
          ])}
          paddingTop={condensedStyles(isCondensed, [
            hideCustomerDetails ? 0 : 4,
            0,
          ])}
        >
          {totalGroupedServices === 0 && (
            <Box>
              <Heading fontSize="md" lineHeight="none" fontWeight={500}>
                No procedures yet
              </Heading>
              <Text fontSize="sm" color="midGrey">
                Waiting on customer
              </Text>
            </Box>
          )}

          {totalGroupedServices < 2 ? (
            groupedServiceKeys.map((type) => {
              const services = groupedServices[type];
              const totalServices = services.length;
              const serviceType = services[0].service.serviceType;
              const artists = toArtists(services);

              return (
                <VisitService
                  key={type}
                  serviceName={serviceType.displayName}
                  totalServices={totalServices}
                  services={services}
                  artists={artists}
                />
              );
            })
          ) : (
            <VisitService
              serviceName={"Procedure"}
              totalServices={totalGroupedServices}
              services={[]}
              artists={uniq(
                groupedServiceKeys.flatMap((serviceType) =>
                  toArtists(groupedServices[serviceType]),
                ),
              )}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VisitCard;
