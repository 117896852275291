import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  Image,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Icon,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../../components/PageHeader";
import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import ImagePicker from "../../../../../components/ImagePicker";
import { useMutation } from "@apollo/client";
import { EDIT_STORE_INFO } from "./gql";
import { phoneRegExp } from "../../../../../lib/form";
import InformationRow from "../../../../../components/VisitSheet/InformationRow";
import { ImageUploadType } from "../../../../../types";
import InputModal from "../../../../../components/InputModal";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  website: Yup.string().url().nullable(),
  emailAddress: Yup.string().email().nullable(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(),
  address: Yup.object().shape({
    addressLineOne: Yup.string().required(),
    addressLineTwo: Yup.string().nullable(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string().required(),
  }),
});

const EditStoreInfo = ({ isOpen, onClose, store }) => {
  const toast = useToast();
  const [updateStore, { loading }] = useMutation(EDIT_STORE_INFO, {
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Store updated",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = {
    storeId: store.id,
    name: store.name,
    website: store.website,
    phoneNumber: store.phoneNumber,
    emailAddress: store.emailAddress,
    profileImageUrl: store.profileImageUrl,
    headerImageUrl: store.headerImageUrl,
    address: {
      addressLineOne: store.address?.addressLineOne,
      addressLineTwo: store.address?.addressLineTwo,
      city: store.address?.city,
      state: store.address?.state,
      zipCode: store.address?.zipCode,
      country: store.address?.country,
    },
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Update Store"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await updateStore({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} maxWidth={["100%", "50%"]} flex={1}>
                    <FormField name="name" label="Name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="website" label="Website">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="phoneNumber" label="Phone Number">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="emailAddress" label="Email">
                      {(props) => (
                        <Input type="email" {...props} variant="filled" />
                      )}
                    </FormField>

                    <FormField
                      name="address.addressLineOne"
                      label="Address Line 1"
                    >
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField
                      name="address.addressLineTwo"
                      label="Address Line 2"
                    >
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="address.city" label="City">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <Flex>
                      <FormField name="address.state" label="State">
                        {(props) => <Input {...props} variant="filled" />}
                      </FormField>

                      <FormField name="address.zipCode" label="Zipcode">
                        {(props) => <Input {...props} variant="filled" />}
                      </FormField>
                    </Flex>
                  </Box>
                  <Box paddingY={5} maxWidth={["100%", "50%"]} flex={1}>
                    <InformationRow label="Profile Image">
                      <Box
                        width={80}
                        height={80}
                        borderRadius={80}
                        backgroundColor="lightGrey"
                        position="relative"
                      >
                        {formProps.values.profileImageUrl && (
                          <Image
                            rounded="full"
                            size="80px"
                            position="absolute"
                            top={0}
                            src={formProps.values.profileImageUrl}
                          />
                        )}

                        {!formProps.values.profileImageUrl && (
                          <Flex
                            position="absolute"
                            top={0}
                            right={0}
                            bottom={0}
                            left={0}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon name="store" fontSize="32px" />
                          </Flex>
                        )}

                        {isOpen && (
                          <Box
                            position="absolute"
                            zIndex={1}
                            backgroundColor="brandYellow.500"
                            borderRadius={60}
                            right={-10}
                            top={-5}
                          >
                            <ImagePicker
                              type={ImageUploadType.ProfileImage}
                              onClear={() => {
                                formProps.setFieldValue(
                                  "profileImageUrl",
                                  null,
                                );
                              }}
                              onUpload={(file) => {
                                formProps.setFieldValue(
                                  "profileImageUrl",
                                  file.ssl_url,
                                );
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </InformationRow>

                    <InformationRow label="Header Image">
                      <Box
                        paddingBottom={"56%"}
                        borderRadius={20}
                        backgroundColor="lightGrey"
                        overflow="hidden"
                        position="relative"
                      >
                        {formProps.values.headerImageUrl && (
                          <Image
                            width="100%"
                            height="100%"
                            position="absolute"
                            objectFit="cover"
                            top={0}
                            src={formProps.values.headerImageUrl}
                          />
                        )}

                        {!formProps.values.headerImageUrl && (
                          <Flex
                            position="absolute"
                            top={0}
                            right={0}
                            bottom={0}
                            left={0}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon name="store" fontSize="60px" />
                          </Flex>
                        )}

                        {isOpen && (
                          <Box
                            position="absolute"
                            zIndex={1}
                            backgroundColor="brandYellow.500"
                            borderRadius={60}
                            right={0}
                            top={0}
                          >
                            <ImagePicker
                              type={ImageUploadType.StoreImage}
                              onClear={() => {
                                formProps.setFieldValue("headerImageUrl", null);
                              }}
                              onUpload={(file) => {
                                formProps.setFieldValue(
                                  "headerImageUrl",
                                  file.ssl_url,
                                );
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </InformationRow>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default EditStoreInfo;
