import { gql } from "@apollo/client";
import {
  serviceTypeCategory,
  serviceForCategoryListing,
  serviceType,
} from "../../../../fragments";

export const SERVICE_TYPE_CATEGORY = gql`
  query ServiceTypeCategory($serviceTypeCategoryId: ID!) {
    serviceTypeCategory: node(id: $serviceTypeCategoryId) {
      ... on ServiceTypeCategory {
        ...serviceTypeCategory
        serviceType {
          ...serviceType
        }
      }
    }
  }
  ${serviceType}
  ${serviceTypeCategory}
`;

export const TOGGLE_VISIBILITY = gql`
  mutation ToggleServiceTypeCategoryServiceForStore(
    $input: ToggleServiceTypeCategoryServiceForStoreInput!
  ) {
    result: toggleServiceTypeCategoryServiceForStore(input: $input) {
      serviceTypeCategoryService {
        id
        disabledAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateServiceForStore($input: UpdateServiceForStoreInput!) {
    result: updateServiceForStore(input: $input) {
      service {
        ...serviceForCategoryListing
      }
      errors {
        field
        message
      }
    }
  }
  ${serviceForCategoryListing}
`;

export const CREATE_SERVICE = gql`
  mutation CreateServiceForCategory($input: CreateServiceForCategoryInput!) {
    result: createServiceForCategory(input: $input) {
      service {
        ...serviceForCategoryListing
      }
      errors {
        field
        message
      }
    }
  }
  ${serviceForCategoryListing}
`;

export const REMOVE_SERVICE = gql`
  mutation RemoveServiceTypeCategoryService(
    $input: removeServiceTypeCategoryServiceInput!
  ) {
    result: removeServiceTypeCategoryService(input: $input) {
      serviceTypeCategoryService {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
