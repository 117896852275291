import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  useToast,
  Textarea,
  Input,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../../components/PageHeader";
import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import { useMutation } from "@apollo/client";
import {
  CREATE_CONSENT_QUESTION_CATEGORY,
  UPDATE_CONSENT_QUESTION_CATEGORY,
} from "./gql";
import InputModal from "../../../../../components/InputModal";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().nullable(),
});

enum Mode {
  Creating = "CREATING",
  Updating = "UPDATING",
}

const Mutations = {
  [Mode.Creating]: CREATE_CONSENT_QUESTION_CATEGORY,
  [Mode.Updating]: UPDATE_CONSENT_QUESTION_CATEGORY,
};

const ManageConsentQuestionCategory = ({
  isOpen,
  onClose,
  store,
  category,
}) => {
  const toast = useToast();
  const mode = category ? Mode.Updating : Mode.Creating;
  const mutation = Mutations[mode];

  const [performMutation, { loading }] = useMutation(mutation, {
    refetchQueries: ["ConsentQuestionCategories"],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: `Consent question ${
            mode === Mode.Creating ? "created" : "updated"
          }`,
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = category
    ? {
        categoryId: category.id,
        name: category.name,
        description: category.description,
        index: category.index,
      }
    : {
        storeId: store.id,
        name: "",
        description: "",
        index: 0,
      };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Manage Consent Question Category"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await performMutation({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} width={["100%", "50%"]}>
                    <FormField name="name" label="Name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="description" label="Description">
                      {(props) => (
                        <Textarea {...props} variant="filled" height={150} />
                      )}
                    </FormField>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageConsentQuestionCategory;
