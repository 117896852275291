import { gql } from "@apollo/client";
import { storeInformation } from "../../../../../fragments";

export const EDIT_STORE_INFO = gql`
  mutation EditStoreInfo($input: UpdateStoreInput!) {
    result: updateStore(input: $input) {
      store {
        ...storeInformation
      }
      errors {
        field
        message
      }
    }
  }
  ${storeInformation}
`;
