import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  storeInformation as storeInformationFragment,
  storeViewerContext as storeViewerContextFragment,
} from "../fragments";
import { useDispatch } from "react-redux";
import { setStoreId } from "../state/context";
import { Store } from "../types";

const STORE_QUERY = gql`
  query StoreForAccount($accountSlug: String!, $storeSlug: String!) {
    account(slug: $accountSlug) {
      store(slug: $storeSlug) {
        ...storeInformation
        ...storeViewerContext
      }
    }
  }
  ${storeInformationFragment}
  ${storeViewerContextFragment}
`;

export default function useQueryStoreFromSlug(
  accountSlug: string,
  storeSlug: string,
): { loading: boolean; store: Store } {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, loading } = useQuery(STORE_QUERY, {
    variables: { accountSlug, storeSlug },
    onCompleted: ({ account }) => {
      dispatch(setStoreId(account?.store?.id));

      if (account?.store) {
        // Set the context for sentry reporting for the store
        Sentry.setContext("store", {
          id: account.store.id,
          name: account.store.name,
          accountId: account.id,
        });
      }
    },
    onError: (error) => {
      history.push("/login", {
        next: "/",
        message: "The requested store could not be accessed",
      });
    },
  });

  return { store: data?.account?.store, loading };
}
