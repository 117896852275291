import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  useToast,
  Input,
} from "@chakra-ui/core";
import PageHeader from "../../../components/PageHeader";
import Button from "../../../components/Button";
import FormField from "../../../components/FormField";
import { useMutation } from "@apollo/client";
import { CREATE_ACCOUNT, UPDATE_ACCOUNT } from "./gql";
import InputModal from "../../../components/InputModal";

enum Mode {
  Creating = "CREATING",
  Updating = "UPDATING",
}

const Mutations = {
  [Mode.Creating]: CREATE_ACCOUNT,
  [Mode.Updating]: UPDATE_ACCOUNT,
};

const ManageAccount = ({ isOpen, onClose, account }) => {
  const toast = useToast();
  const mode = account ? Mode.Updating : Mode.Creating;
  const mutation = Mutations[mode];

  const [performMutation, { loading }] = useMutation(mutation, {
    refetchQueries: ["AllAccounts"],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: `Account ${mode === Mode.Creating ? "created" : "updated"}`,
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = account
    ? {
        accountId: account.id,
        name: account.name,
        slug: account.slug,
        billingEmailAddress: account.billingEmailAddress,
      }
    : {
        name: "",
        slug: "",
        billingEmailAddress: "",
      };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title={`${mode === Mode.Creating ? "Create" : "Update"} Account`}
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await performMutation({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} width={["100%", "50%"]}>
                    <FormField name="name" label="Name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="slug" label="Slug">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField
                      name="billingEmailAddress"
                      label="Billing email address"
                    >
                      {(props) => (
                        <Input
                          {...props}
                          variant="filled"
                          placeholder="user@example.com"
                        />
                      )}
                    </FormField>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageAccount;
