import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import { Global } from "@emotion/core";
import * as Sentry from "@sentry/react";
import {
  QueryParamProvider,
  ExtendedStringifyOptions,
  transformSearchStringJsonSafe,
} from "use-query-params";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "typeface-poppins";

import graphqlClient from "./graphql";
import Routes from "./Routes";
import store, { persistor } from "./state";
import theme from "./theme";

declare global {
  interface Window {
    analytics: any;
  }
}

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

function App() {
  return (
    <ApolloProvider client={graphqlClient}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <Sentry.ErrorBoundary fallback={"An error has occurred"}>
              <CSSReset />
              <Global styles={{ "html, body, #root": { height: "100%" } }} />
              <Router>
                <QueryParamProvider
                  ReactRouterRoute={Route}
                  stringifyOptions={queryStringifyOptions}
                >
                  <Routes />
                </QueryParamProvider>
              </Router>
            </Sentry.ErrorBoundary>
          </PersistGate>
        </ThemeProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
}

export default App;
