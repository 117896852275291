import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../../../../components/Button";
import { useMutation } from "@apollo/client";
import { TOGGLE_VISIBILITY } from "./gql";
import { Body } from "../../../../components/Typography";

const ToggleVisibilityDialog = ({ item, isOpen, onClose }) => {
  const toast = useToast();
  const [toggleVisibility, { loading }] = useMutation(TOGGLE_VISIBILITY, {
    variables: { input: { serviceTypeCategoryId: item?.id } },
    refetchQueries: ["ServiceType"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast({
        title: "Category has been updated",
        status: "success",
        position: "bottom-left",
      });
      onClose();
    },
  });

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {item
            ? item.disabledAt
              ? "Enable Services in Category"
              : "Disable Services in Category"
            : null}
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            {item?.disabledAt
              ? "Are you sure you want to re-enable this category and its services for your store? Doing so will make it available immediately to all customers."
              : "Are you sure you want to disable this category and its services for your store? Doing so will mean no customers will be able to select these services."}
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dangerFilled"
            onClick={() => toggleVisibility()}
            isLoading={loading}
            ml={3}
          >
            {item?.disabledAt ? "Enable" : "Disable"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ToggleVisibilityDialog;
