import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import StoreRouter from "./Store/Router";
import AdminRouter from "./Admin/Router";
import useQueryAccountFromSlug from "../../hooks/useQueryAccountFromSlug";
import { Spinner, Flex } from "@chakra-ui/core";

type AccountParams = {
  account: string;
};

const withAccount = (Component, account) => (props) => (
  <Component {...props} account={account} />
);

const AccountRouter = ({ match }) => {
  const { account: accountSlug } = useParams<AccountParams>();
  const { account, loading } = useQueryAccountFromSlug(accountSlug);

  return (
    <>
      {loading && (
        <Flex
          alignItems="center"
          height="100%"
          justifyContent="center"
          backgroundColor="lightGrey"
        >
          <Spinner color="brandYellow.500" size="lg" />
        </Flex>
      )}
      {!loading && account && (
        <Switch>
          <Route
            path={`${match.url}/admin`}
            render={withAccount(AdminRouter, account)}
          />
          <Route
            path={`${match.url}/stores/:store`}
            render={withAccount(StoreRouter, account)}
          />
          <Redirect
            to={
              account.context.role === "ADMIN"
                ? `${match.url}/admin`
                : `${match.url}/stores/${account.stores[0].slug}`
            }
          />
        </Switch>
      )}
    </>
  );
};

export default AccountRouter;
