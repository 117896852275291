import { useQuery } from "@apollo/client";
import {
  Box,
  Heading,
  Flex,
  Spinner,
  Text,
  Badge,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from "@chakra-ui/core";
import { get } from "lodash";
import React from "react";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import PageHeader from "../../../../components/PageHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/Table";
import Button from "../../../../components/Button";
import ItemMenu from "../../../../components/ItemMenu";
import { MEMBERS_FOR_ACCOUNT } from "./gql";
import { formatDate } from "../../../../lib/dates";
import RemoveSeatDialog from "./RemoveSeatDialog";
import { AccountSeat, AccountSeatEdge } from "../../../../types";
import PaginationControls from "../../../../components/PaginationControls";
import ChangeSeatRoleDialog from "./ChangeSeatRoleDialog";
import InviteNewAdmin from "./InviteNewAdmin";

const PAGE_SIZE = 50;

const TeamPage = ({ account }) => {
  const [query, setQuery] = useQueryParams({
    first: withDefault(NumberParam, PAGE_SIZE),
    last: NumberParam,
    before: StringParam,
    after: StringParam,
  });

  const { data, loading } = useQuery(MEMBERS_FOR_ACCOUNT, {
    variables: {
      accountId: account.id,
      first: query.first,
      last: query.last,
      before: query.before,
      after: query.after,
    },
  });

  const inviteDisclosure = useDisclosure();
  const [deletionId, setDeletionId] = React.useState<string | null>(null);
  const [editing, setEditing] = React.useState<AccountSeat | null>(null);

  const memberEdges = get(data, "account.members.edges", []);
  const pageInfo = get(data, "account.members.pageInfo");

  return (
    <Box padding={5}>
      <PageHeader
        title="Team"
        renderRightControls={() => (
          <Button variant="primary" onClick={inviteDisclosure.onOpen}>
            Invite new admin
          </Button>
        )}
      />

      <RemoveSeatDialog
        isOpen={!!deletionId}
        seatId={deletionId}
        onClose={() => setDeletionId(null)}
      />

      <ChangeSeatRoleDialog
        seat={editing}
        isOpen={!!editing}
        onClose={() => setEditing(null)}
      />

      <InviteNewAdmin
        onClose={inviteDisclosure.onClose}
        isOpen={inviteDisclosure.isOpen}
        account={account}
      />

      {loading && (
        <Flex justifyContent="center" alignItems="center" flex={1} padding={5}>
          <Spinner color="brandYellow.500" size="md" />
        </Flex>
      )}
      <Table marginBottom={10}>
        <TableBody>
          {!loading && memberEdges.length === 0 && (
            <Text py={3}>No members on the account just yet</Text>
          )}

          {memberEdges.map((memberEdge: AccountSeatEdge) => (
            <TableRow
              height={"74px"}
              key={memberEdge.node.id}
              onClick={() => null}
            >
              <TableCell flexBasis={250} flexGrow={0} flexShrink={0}>
                <Box>
                  <Heading fontSize={["md", "xl"]}>
                    {memberEdge.node.user.name}
                  </Heading>
                  <Text fontSize={14} fontWeight="500" color="darkGrey">
                    {memberEdge.node.user.emailAddress}
                  </Text>
                </Box>
              </TableCell>
              <TableCell flexGrow={0} flexBasis={100} hideOnSmall>
                <Badge color="darkGrey">{memberEdge.node.role}</Badge>
              </TableCell>

              <TableCell
                flexGrow={0}
                flexBasis={160}
                marginLeft="auto"
                hideOnSmall
              >
                <Text color="darkGrey">
                  Added {formatDate(memberEdge.node.insertedAt)}
                </Text>
              </TableCell>

              <TableCell
                justifyContent="flex-end"
                flexShrink={0}
                flexGrow={0}
                flexBasis={50}
                marginLeft="auto"
              >
                <ItemMenu>
                  <MenuList placement="bottom-end">
                    <MenuItem onClick={() => setEditing(memberEdge.node)}>
                      Change role
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      color="statusRed.500"
                      onClick={() => setDeletionId(memberEdge.node.id)}
                    >
                      Remove member
                    </MenuItem>
                  </MenuList>
                </ItemMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <PaginationControls
        pageSize={PAGE_SIZE}
        pageInfo={pageInfo}
        updatePageInfo={(props) => setQuery({ ...query, ...props })}
      />
    </Box>
  );
};

export default TeamPage;
