import React from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import TeamPage from "./Team";
import StoresPage from "./Stores";
import SettingsPage from "./Settings";
import { Text } from "@chakra-ui/core";
import MainLayout from "../../../components/MainLayout";
import AdminMenu from "./Menu";

const withAccount = (Component, account) => (props) => (
  <Component {...props} account={account} />
);

const Header = ({ account }) => (
  <Link to={`${account.slug}`}>
    <Text color="white" fontSize="lg" fontWeight="black">
      {account.name}{" "}
      <Text color="brandYellow.500" display="inline">
        Admin
      </Text>
    </Text>
  </Link>
);

const AccountAdminRouter = ({ match, account }) => {
  return (
    <MainLayout
      renderHeader={() => <Header account={account} />}
      renderMenu={(props) => <AdminMenu {...props} account={account} />}
    >
      <Switch>
        <Route
          path={`${match.url}/team`}
          render={withAccount(TeamPage, account)}
        />
        <Route
          path={`${match.url}/stores`}
          render={withAccount(StoresPage, account)}
        />
        <Route
          path={`${match.url}/settings`}
          render={withAccount(SettingsPage, account)}
        />
        <Redirect to={`${match.url}/stores`} />
      </Switch>
    </MainLayout>
  );
};

export default AccountAdminRouter;
