import { gql } from "@apollo/client";
import { customerInformation } from "../../fragments";

export const LOOKUP_CUSTOMER = gql`
  query LookupCustomer(
    $storeId: ID!
    $emailAddress: String!
    $phoneNumber: String!
  ) {
    store: node(id: $storeId) {
      ... on Store {
        id
        lookupCustomer(emailAddress: $emailAddress, phoneNumber: $phoneNumber) {
          ...customerInformation
        }
      }
    }
  }
  ${customerInformation}
`;

export const CREATE_VISIT = gql`
  mutation CreateVisitForCustomer(
    $input: CreateVisitForExistingCustomerInput!
  ) {
    result: createVisitForExistingCustomer(input: $input) {
      visit {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_VISIT_FOR_NEW_CUSTOMER = gql`
  mutation CreateVisitForNewCustomer($input: CreateVisitForNewCustomerInput!) {
    result: createVisitForNewCustomer(input: $input) {
      visit {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
