export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
};

/** A store user */
export type User = {
  __typename?: "User";
  /** Accounts associated with this user */
  accounts?: Maybe<Array<Account>>;
  emailAddress: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  isStaff: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type ReorderServiceTypeCategoryForStoreInput = {
  position: Scalars["Int"];
  serviceTypeCategoryId: Scalars["ID"];
};

export type UpdateProfileInput = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  profileImageUrl?: Maybe<Scalars["String"]>;
};

export type ConsentQuestionConnection = {
  __typename?: "ConsentQuestionConnection";
  edges?: Maybe<Array<Maybe<ConsentQuestionEdge>>>;
  pageInfo: PageInfo;
};

export type AddVisitToQueueInput = {
  visitId: Scalars["ID"];
};

export type MarkIdAsVerifiedOnVisitInput = {
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  parentIdImageUrls?: Maybe<Array<Scalars["String"]>>;
  visitId: Scalars["ID"];
};

export type VisitEdge = {
  __typename?: "VisitEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Visit>;
};

export type SubmittedInformationForServiceInput = {
  label: Scalars["String"];
  name: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type RootMutationType = {
  __typename?: "RootMutationType";
  /**
   * Adds the visit to the current queue for the store
   *
   * Requires the `store:manage_consent` policy
   */
  addVisitToQueue: VisitMutationResponse;
  /** Removes the given procedure from the visit */
  updateProcedure: VisitServiceMutationResponse;
  /** Removes the consent question for the store */
  removeConsentQuestionForStore?: Maybe<ConsentQuestionMutationResponse>;
  /** Creates a new store in the given account, only accessible to admins */
  createStoreForAccount?: Maybe<StoreMutationResponse>;
  /** Creates a new consent question category for the store given */
  createConsentQuestionCategoryForStore?: Maybe<ConsentQuestionCategoryMutationResponse>;
  /** Creates a new inventory item for the store provided */
  createInventoryItemForStore?: Maybe<InventoryItemMutationResponse>;
  /** Login the store user with the supplied credentials */
  login?: Maybe<SessionMutationResponse>;
  /**
   * Logout the current store user, invalidating their refresh token in the process.
   *
   * Note: you must be authenticated to call this endpoint.
   */
  logout?: Maybe<GenericSuccessResponse>;
  /** Resets all aftercare for a given service type back to the default specified on the store */
  resetStoreAftercareForServiceType?: Maybe<GenericSuccessResponse>;
  /** Removes a new service type category */
  removeServiceTypeCategory?: Maybe<ServiceTypeCategoryMutationResponse>;
  /** Deletes a new inventory item for the store */
  removeInventoryItemForStore?: Maybe<InventoryItemMutationResponse>;
  /** Updates the consent question for the store */
  updateConsentQuestionForStore?: Maybe<ConsentQuestionMutationResponse>;
  /** Exchanges a refresh token for a new JWT */
  exchangeRefreshToken?: Maybe<Session>;
  /**
   * Marks the proecedure as approved by the artist performing it, therefore moving
   * it to be in-progress.
   *
   * Requires the `store:manage_consent_procedure` policy
   */
  approveProcedure: VisitServiceMutationResponse;
  /** Generates a billing portal session */
  generateBillingPortalSessionForAccount?: Maybe<BillingPortalSessionResponse>;
  /** Updates the consent question category for the store */
  updateConsentQuestionCategoryForStore?: Maybe<ConsentQuestionCategoryMutationResponse>;
  /** Request a password reset email */
  requestPasswordReset?: Maybe<GenericSuccessResponse>;
  /**
   * Marks the ID as verified for the given visit
   *
   * Requires the `store:manage_consent` policy
   */
  markIdAsVerifiedOnVisit: VisitMutationResponse;
  /** Updates the service type category */
  updateServiceTypeCategory?: Maybe<ServiceTypeCategoryMutationResponse>;
  /**
   * Updates the employee with the given attributes.
   *
   * Requires the `store:manage_employees` permission.
   */
  updateEmployeeForStore?: Maybe<StoreEmployeeResponse>;
  /** Removes the given procedure from the visit */
  addProcedure: VisitServiceMutationResponse;
  /** Creates a new visit for customer who does not yet have a bodyart account. */
  createVisitForNewCustomer: VisitMutationResponse;
  /**
   * Denies the given visit
   *
   * Requires the `store:manage_consent` policy
   */
  denyVisit: VisitMutationResponse;
  /** Removes member from account, can only be enacted by an admin */
  removeMemberFromAccount?: Maybe<AccountSeatMutationResponse>;
  /** Creates a new bodyart account, can only be enacted by a staff member */
  createAccount?: Maybe<AccountMutationResponse>;
  /**
   * Marks the medical history as acknowledged for the given visit.
   *
   * Requires the `store:manage_consent` policy
   */
  acknowledgeMedicalHistoryOnVisit: VisitMutationResponse;
  /**
   * Invites an employee to the store, returning an error in the case
   * where the user is already on the store.
   *
   * Requires the `store:manage_employees` permission.
   */
  inviteEmployeeToStore?: Maybe<InvitedStoreEmployeeResponse>;
  /**
   * Resets the given visit from approved / denied
   *
   * Requires the `store:manage_consent` policy
   */
  resetVisitStatus: VisitMutationResponse;
  /** Removes the given procedure from the visit */
  removeProcedure: VisitServiceMutationResponse;
  /**
   * Marks the procedure as completed, setting any aftercare instructions on it
   *
   * Requires the `store:manage_consent_procedure` policy
   */
  completeProcedure: VisitServiceMutationResponse;
  /** Signs up a new user to Bodyart */
  signupUser?: Maybe<SessionMutationResponse>;
  /**
   * Marks the proecedure as denied by the artist performing it
   *
   * Requires the `store:manage_consent_procedure` policy
   */
  denyProcedure: VisitServiceMutationResponse;
  /** Enables the service for the service type category */
  toggleServiceTypeCategoryServiceForStore?: Maybe<ServiceTypeCategoryServiceMutationResponse>;
  /**
   * Removes the employee from the store, revoking their access.
   *
   * Requires the `store:manage_employees` permission.
   */
  removeEmployeeFromStore?: Maybe<StoreEmployeeResponse>;
  /**
   * Removes the artist from the given procedure
   *
   * Requires the `store:manage_consent` policy
   */
  removeArtistFromProcedure: VisitServiceMutationResponse;
  /** Removes the consent question for the store */
  reorderConsentQuestionForStore?: Maybe<ConsentQuestionMutationResponse>;
  /** Toggles the service type, marking all services as disabled under it. */
  toggleServiceTypeForStore?: Maybe<ServiceTypeMutationResponse>;
  /** Updates the profile for the current user */
  updateProfile?: Maybe<UserMutationResponse>;
  /** Sends the visit to the customer */
  sendVisitToCustomer: VisitMutationResponse;
  /**
   * Realizes an invite by accepting it and adding the user to the store
   * in the process.
   */
  realizeInviteToAccount?: Maybe<AccountSeatMutationResponse>;
  /** Reorders a service type for a store */
  reorderServiceTypeForStore?: Maybe<ServiceTypeMutationResponse>;
  /** Creates a new service type category for the service type given */
  createServiceTypeCategory?: Maybe<ServiceTypeCategoryMutationResponse>;
  /**
   * Realizes an invite by accepting it and adding the user to the store
   * in the process.
   */
  realizeInviteToStore?: Maybe<StoreEmployeeResponse>;
  /** Updates the store aftercare with the instructions and external url given. */
  updateStoreAftercare?: Maybe<StoreAftercareMutationResponse>;
  /**
   * Approves the given visit
   *
   * Requires the `store:manage_consent` policy
   */
  approveVisit: VisitMutationResponse;
  /** Adds the member to the account, can only be enacted by an admin */
  updateMemberInAccount?: Maybe<AccountSeatMutationResponse>;
  /**
   * Invites an employee to the account, returning an error in the case
   * where the user is already on the account.
   *
   * Requires the `account:manage_members` permission.
   */
  inviteToAccount?: Maybe<InvitedAccountSeatResponse>;
  /** Disables the service type category, marking all services as disabled under it. */
  toggleServiceTypeCategoryForStore?: Maybe<ServiceTypeCategoryMutationResponse>;
  /** Reorders the category within a service type */
  reorderServiceTypeCategoryForStore?: Maybe<ServiceTypeCategoryMutationResponse>;
  /** Creates a new service in the provided category */
  createServiceForCategory?: Maybe<ServiceMutationResponse>;
  /**
   * Updates the given procedures with the given properties.
   *
   * Requires the `store:manage_consent` policy
   */
  updateProceduresForVisit: VisitMutationResponse;
  /** Generates a set of upload params for the given request */
  generateUpload?: Maybe<UploadResponse>;
  /**
   * Creates a new visit for an existing bodyart customer, who may or may not have visited this
   * store yet.
   */
  createVisitForExistingCustomer: VisitMutationResponse;
  /** Updates the customer information attached to a visit */
  updateCustomerInformationForVisit: VisitMutationResponse;
  /** Removes the service in a category */
  removeServiceTypeCategoryService?: Maybe<ServiceTypeCategoryServiceMutationResponse>;
  /** Removes the consent question category for the store */
  removeConsentQuestionCategoryForStore?: Maybe<ConsentQuestionCategoryMutationResponse>;
  /** Updates an existing bodyart account, can only be enacted by a staff member */
  updateAccount?: Maybe<AccountMutationResponse>;
  /** Updates the store contact with the information provided */
  updateContactProfile?: Maybe<StoreContactMutationResponse>;
  /** Reorders the service within the category for a store */
  reorderServiceTypeCategoryServiceForStore?: Maybe<ServiceTypeCategoryServiceMutationResponse>;
  /** Updates the service for a store */
  updateServiceForStore?: Maybe<ServiceMutationResponse>;
  /** Resets the password given using the token provided. */
  resetPassword?: Maybe<SessionMutationResponse>;
  /** Updates the store with the attributes given */
  updateStore?: Maybe<StoreMutationResponse>;
  /** Updates an existing inventory item for the store provided */
  updateInventoryItemForStore?: Maybe<InventoryItemMutationResponse>;
  /** Creates a new consent question for the store given */
  createConsentQuestionForStore?: Maybe<ConsentQuestionMutationResponse>;
};

export type RootMutationTypeAddVisitToQueueArgs = {
  input: AddVisitToQueueInput;
};

export type RootMutationTypeUpdateProcedureArgs = {
  input: UpdateProcedureInput;
};

export type RootMutationTypeRemoveConsentQuestionForStoreArgs = {
  input: RemoveConsentQuestionForStoreInput;
};

export type RootMutationTypeCreateStoreForAccountArgs = {
  input: CreateStoreForAccountInput;
};

export type RootMutationTypeCreateConsentQuestionCategoryForStoreArgs = {
  input: CreateConsentQuestionCategoryForStoreInput;
};

export type RootMutationTypeCreateInventoryItemForStoreArgs = {
  input: CreateInventoryItemForStoreInput;
};

export type RootMutationTypeLoginArgs = {
  input: LoginInput;
};

export type RootMutationTypeResetStoreAftercareForServiceTypeArgs = {
  input: ResetStoreAftercareForServiceTypeInput;
};

export type RootMutationTypeRemoveServiceTypeCategoryArgs = {
  input: RemoveServiceTypeCategoryInput;
};

export type RootMutationTypeRemoveInventoryItemForStoreArgs = {
  input: RemoveInventoryItemForStoreInput;
};

export type RootMutationTypeUpdateConsentQuestionForStoreArgs = {
  input: UpdateConsentQuestionForStoreInput;
};

export type RootMutationTypeExchangeRefreshTokenArgs = {
  refreshToken: Scalars["String"];
};

export type RootMutationTypeApproveProcedureArgs = {
  input: ApproveProcedureInput;
};

export type RootMutationTypeGenerateBillingPortalSessionForAccountArgs = {
  input: GenerateBillingPortalSessionForAccountInput;
};

export type RootMutationTypeUpdateConsentQuestionCategoryForStoreArgs = {
  input: UpdateConsentQuestionCategoryForStoreInput;
};

export type RootMutationTypeRequestPasswordResetArgs = {
  emailAddress: Scalars["String"];
};

export type RootMutationTypeMarkIdAsVerifiedOnVisitArgs = {
  input: MarkIdAsVerifiedOnVisitInput;
};

export type RootMutationTypeUpdateServiceTypeCategoryArgs = {
  input: UpdateServiceTypeCategoryInput;
};

export type RootMutationTypeUpdateEmployeeForStoreArgs = {
  input: UpdateEmployeeForStoreInput;
};

export type RootMutationTypeAddProcedureArgs = {
  input: AddProcedureInput;
};

export type RootMutationTypeCreateVisitForNewCustomerArgs = {
  input: CreateVisitForNewCustomerInput;
};

export type RootMutationTypeDenyVisitArgs = {
  input: DenyVisitInput;
};

export type RootMutationTypeRemoveMemberFromAccountArgs = {
  input: RemoveMemberInAccountInput;
};

export type RootMutationTypeCreateAccountArgs = {
  input: CreateAccountInput;
};

export type RootMutationTypeAcknowledgeMedicalHistoryOnVisitArgs = {
  input: AcknowledgeMedicalHistoryOnVisitInput;
};

export type RootMutationTypeInviteEmployeeToStoreArgs = {
  input: InviteStoreEmployeeInput;
};

export type RootMutationTypeResetVisitStatusArgs = {
  input: VisitOperationInput;
};

export type RootMutationTypeRemoveProcedureArgs = {
  input: RemoveProcedureInput;
};

export type RootMutationTypeCompleteProcedureArgs = {
  input: CompleteProcedureInput;
};

export type RootMutationTypeSignupUserArgs = {
  input: SignupUserInput;
};

export type RootMutationTypeDenyProcedureArgs = {
  input: DenyProcedureInput;
};

export type RootMutationTypeToggleServiceTypeCategoryServiceForStoreArgs = {
  input: ToggleServiceTypeCategoryServiceForStoreInput;
};

export type RootMutationTypeRemoveEmployeeFromStoreArgs = {
  input: RemoveEmployeeFromStoreInput;
};

export type RootMutationTypeRemoveArtistFromProcedureArgs = {
  input: RemoveArtistFromProcedureInput;
};

export type RootMutationTypeReorderConsentQuestionForStoreArgs = {
  input: ReorderConsentQuestionForStoreInput;
};

export type RootMutationTypeToggleServiceTypeForStoreArgs = {
  input: ToggleServiceTypeForStoreInput;
};

export type RootMutationTypeUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type RootMutationTypeSendVisitToCustomerArgs = {
  input: SendVisitToCustomerInput;
};

export type RootMutationTypeRealizeInviteToAccountArgs = {
  input: RealizeInviteToAccountInput;
};

export type RootMutationTypeReorderServiceTypeForStoreArgs = {
  input: ReorderServiceTypeForStoreInput;
};

export type RootMutationTypeCreateServiceTypeCategoryArgs = {
  input: CreateServiceTypeCategoryInput;
};

export type RootMutationTypeRealizeInviteToStoreArgs = {
  input: RealizeInviteToStoreInput;
};

export type RootMutationTypeUpdateStoreAftercareArgs = {
  input: UpdateStoreAftercareInput;
};

export type RootMutationTypeApproveVisitArgs = {
  input: VisitOperationInput;
};

export type RootMutationTypeUpdateMemberInAccountArgs = {
  input: UpdateMemberInAccountInput;
};

export type RootMutationTypeInviteToAccountArgs = {
  input: InviteToAccountInput;
};

export type RootMutationTypeToggleServiceTypeCategoryForStoreArgs = {
  input: ToggleServiceTypeCategoryForStoreInput;
};

export type RootMutationTypeReorderServiceTypeCategoryForStoreArgs = {
  input: ReorderServiceTypeCategoryForStoreInput;
};

export type RootMutationTypeCreateServiceForCategoryArgs = {
  input: CreateServiceForCategoryInput;
};

export type RootMutationTypeUpdateProceduresForVisitArgs = {
  input: UpdateProceduresForVisitInput;
};

export type RootMutationTypeGenerateUploadArgs = {
  input: GenerateUploadInput;
};

export type RootMutationTypeCreateVisitForExistingCustomerArgs = {
  input: CreateVisitForExistingCustomerInput;
};

export type RootMutationTypeUpdateCustomerInformationForVisitArgs = {
  input: UpdateCustomerInformationForVisitInput;
};

export type RootMutationTypeRemoveServiceTypeCategoryServiceArgs = {
  input: RemoveServiceTypeCategoryServiceInput;
};

export type RootMutationTypeRemoveConsentQuestionCategoryForStoreArgs = {
  input: RemoveConsentQuestionCategoryForStoreInput;
};

export type RootMutationTypeUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type RootMutationTypeUpdateContactProfileArgs = {
  input: UpdateContactProfileInput;
};

export type RootMutationTypeReorderServiceTypeCategoryServiceForStoreArgs = {
  input: ReorderServiceTypeCategoryServiceForStoreInput;
};

export type RootMutationTypeUpdateServiceForStoreArgs = {
  input: UpdateServiceForStoreInput;
};

export type RootMutationTypeResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type RootMutationTypeUpdateStoreArgs = {
  input: UpdateStoreInput;
};

export type RootMutationTypeUpdateInventoryItemForStoreArgs = {
  input: UpdateInventoryItemForStoreInput;
};

export type RootMutationTypeCreateConsentQuestionForStoreArgs = {
  input: CreateConsentQuestionForStoreInput;
};

export type RemoveEmployeeFromStoreInput = {
  employeeId: Scalars["ID"];
};

export type AcknowledgeMedicalHistoryOnVisitInput = {
  visitId: Scalars["ID"];
  visitServiceIds?: Maybe<Array<Scalars["ID"]>>;
};

export type CreateServiceForCategoryInput = {
  aftercareInstructions?: Maybe<Scalars["String"]>;
  categoryId: Scalars["ID"];
  displayName: Scalars["String"];
  downsizeTime?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  healingTime?: Maybe<Scalars["String"]>;
  icon?: Maybe<ServiceIconInput>;
  minimumAgeRequirement?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type ServiceTypeCategoryMutationResponse = {
  __typename?: "ServiceTypeCategoryMutationResponse";
  errors?: Maybe<Array<Maybe<InputError>>>;
  serviceTypeCategory?: Maybe<ServiceTypeCategory>;
};

export type UpdateEmployeeForStoreInput = {
  artistServices?: Maybe<Array<ServiceTypeOffering>>;
  employeeId: Scalars["ID"];
  roles: Array<Maybe<StoreEmployeeRole>>;
  user?: Maybe<UpdateEmployeeProfileInput>;
};

export type ToggleServiceTypeCategoryServiceForStoreInput = {
  serviceTypeCategoryServiceId: Scalars["ID"];
};

/**
 * The medical history that the customer either added to their
 * profile or submitted in their most recent consent form.
 */
export type MedicalHistory = {
  __typename?: "MedicalHistory";
  additionalNotes?: Maybe<Scalars["String"]>;
  hasAllergyToProducts: Scalars["Boolean"];
  hasBloodConditions?: Maybe<Scalars["Boolean"]>;
  hasHistoryOfComplications?: Maybe<Scalars["Boolean"]>;
  hasIndicatedMedicationUse: Scalars["Boolean"];
  hasSkinCondition: Scalars["Boolean"];
  isGenerallyHealthy?: Maybe<Scalars["Boolean"]>;
  isProneToFainting?: Maybe<Scalars["Boolean"]>;
  medicalConditions?: Maybe<Array<Scalars["String"]>>;
};

export enum ServiceTypeOffering {
  PermanentMakeup = "PERMANENT_MAKEUP",
  Piercing = "PIERCING",
  Tattoo = "TATTOO",
}

export enum VisitFilterStatusType {
  Approved = "APPROVED",
  Completed = "COMPLETED",
  ConsentRequested = "CONSENT_REQUESTED",
  Denied = "DENIED",
  Draft = "DRAFT",
  InProgress = "IN_PROGRESS",
  Past = "PAST",
  Submitted = "SUBMITTED",
  Upcoming = "UPCOMING",
}

/** The various kinds of input validation errors that can occur */
export enum ErrorType {
  Confirmation = "CONFIRMATION",
  Exclusion = "EXCLUSION",
  Format = "FORMAT",
  Inclusion = "INCLUSION",
  Length = "LENGTH",
  Number = "NUMBER",
  Other = "OTHER",
  Required = "REQUIRED",
  Subset = "SUBSET",
}

export type UpdateInventoryItemForStoreInput = {
  inventoryItemId: Scalars["ID"];
  name: Scalars["String"];
  properties?: Maybe<Array<InventoryItemPropertyInput>>;
  serviceIds?: Maybe<Array<Scalars["ID"]>>;
  type: InventoryItemType;
};

export type Node = {
  /** The id of the object. */
  id: Scalars["ID"];
};

export type UpdateConsentQuestionCategoryForStoreInput = {
  categoryId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  index: Scalars["Int"];
  name: Scalars["String"];
};

export type ServiceTypeMutationResponse = {
  __typename?: "ServiceTypeMutationResponse";
  errors?: Maybe<Array<Maybe<InputError>>>;
  serviceType?: Maybe<ServiceType>;
};

export type ServiceIconInput = {
  selectedUrl: Scalars["String"];
  unselectedUrl: Scalars["String"];
};

/** A visit that the customer has or will perform */
export type Visit = Node & {
  __typename?: "Visit";
  consentAnsweredAt?: Maybe<Scalars["DateTime"]>;
  /** The questions answered during consent for the visit */
  consentAnswers?: Maybe<Array<AnsweredConsentQuestion>>;
  customer?: Maybe<Customer>;
  /** The submitted customer information for the visit */
  customerInformation?: Maybe<VisitCustomerInformation>;
  customerSignatureImageUrl?: Maybe<Scalars["String"]>;
  denyReason?: Maybe<Scalars["String"]>;
  hasMedicalHistoryToReview?: Maybe<Scalars["Boolean"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  idVerifiedAt?: Maybe<Scalars["DateTime"]>;
  insertedAt: Scalars["DateTime"];
  /** The medical history submitted with this visit */
  medicalHistory?: Maybe<MedicalHistory>;
  medicalHistoryAcknowledgedAt?: Maybe<Scalars["DateTime"]>;
  /** The submitted **parent** customer information for the visit -- only applicable to minors */
  parentCustomerInformation?: Maybe<VisitCustomerInformation>;
  scheduledFor?: Maybe<Scalars["DateTime"]>;
  serviceStatus?: Maybe<VisitServiceStatus>;
  /**
   * All of the services associated with this visit that the user can see.
   *
   * In the case of artists viewing visits, only the services *assigned to that artist* will
   * be returned.
   */
  services?: Maybe<Array<VisitService>>;
  status?: Maybe<VisitStatus>;
  store: Store;
  updatedAt: Scalars["DateTime"];
};

export type UpdateAccountInput = {
  accountId: Scalars["ID"];
  billingEmailAddress: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type AccountEdge = {
  __typename?: "AccountEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Account>;
};

/** A category of options within a service type */
export type ServiceTypeCategory = Node & {
  __typename?: "ServiceTypeCategory";
  disabledAt?: Maybe<Scalars["DateTime"]>;
  displayName: Scalars["String"];
  icon?: Maybe<ServiceIcon>;
  /** The ID of an object */
  id: Scalars["ID"];
  index: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  serviceType: ServiceType;
  /** The available services for this category (ordered) */
  services?: Maybe<Array<ServiceTypeCategoryService>>;
  updatedAt: Scalars["DateTime"];
};

/** An address, common to entities in the system */
export type Address = {
  __typename?: "Address";
  addressLineOne: Scalars["String"];
  addressLineTwo?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

export type CreateStoreForAccountInput = {
  accountId: Scalars["ID"];
  address: AddressInput;
  emailAddress?: Maybe<Scalars["String"]>;
  headerImageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type ServiceMutationResponse = {
  __typename?: "ServiceMutationResponse";
  errors?: Maybe<Array<Maybe<InputError>>>;
  service?: Maybe<Service>;
};

export type AccountConnection = {
  __typename?: "AccountConnection";
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  pageInfo: PageInfo;
};

/** A customer */
export type Customer = {
  __typename?: "Customer";
  /** The current address for this customer */
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  isMinor?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  /** The parent/guardian for this minor */
  parent?: Maybe<Customer>;
  phoneNumber?: Maybe<PhoneNumber>;
  updatedAt: Scalars["DateTime"];
};

export enum AccountSeatRole {
  Admin = "ADMIN",
  Employee = "EMPLOYEE",
}

export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type AnsweredConsentQuestion = {
  __typename?: "AnsweredConsentQuestion";
  answer?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  isFlagged?: Maybe<Scalars["Boolean"]>;
  question: Scalars["String"];
  questionId: Scalars["ID"];
  value?: Maybe<Scalars["String"]>;
};

/** Represents a generic 'success' response */
export type GenericSuccessResponse = {
  __typename?: "GenericSuccessResponse";
  success: Scalars["Boolean"];
};

export type AccountSeatMutationResponse = {
  __typename?: "AccountSeatMutationResponse";
  errors?: Maybe<Array<InputError>>;
  seat?: Maybe<AccountSeat>;
};

export type VisitOperationInput = {
  visitId: Scalars["ID"];
};

export type ResetPasswordInput = {
  /** The new password */
  password: Scalars["String"];
  /** The confirmation of the new password */
  passwordConfirmation: Scalars["String"];
  /** The reset password token */
  token: Scalars["String"];
};

/**
 * This input type is reused for both updating the customer information and the
 * parent customer information for a visit
 */
export type UpdateCustomerInformationForVisitInput = {
  customerInformation?: Maybe<VisitCustomerInformationInput>;
  parentCustomerInformation?: Maybe<VisitCustomerInformationInput>;
  visitId: Scalars["ID"];
};

export type LoginInput = {
  /** Email address */
  emailAddress: Scalars["String"];
  /** Password */
  password: Scalars["String"];
};

export type ServiceInformationTextField = {
  __typename?: "ServiceInformationTextField";
  isRequired: Scalars["Boolean"];
  label: Scalars["String"];
  name: Scalars["String"];
  placeholder: Scalars["String"];
  type: ServiceInformationTextFieldType;
};

/** A requested or performed service in the visit */
export type VisitService = {
  __typename?: "VisitService";
  additionalInformation?: Maybe<Scalars["String"]>;
  aftercareInstructions?: Maybe<Scalars["String"]>;
  /** The staff member assigned */
  assignedEmployee?: Maybe<StoreEmployee>;
  denyReason?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  /**
   * Selection for inventory when the inventory selections are not being
   * used (structured inventory)
   */
  inventoryNotes?: Maybe<Scalars["String"]>;
  /** The selected pieces of inventory for this procedure */
  inventorySelections?: Maybe<Array<Maybe<ProcedureInventorySelection>>>;
  /** The service to be performed in the visit */
  service: Service;
  staffNotes?: Maybe<Scalars["String"]>;
  status?: Maybe<VisitServiceStatus>;
  sterilizationLogImageUrl?: Maybe<Scalars["String"]>;
  sterilizationNotes?: Maybe<Scalars["String"]>;
  submittedInformation?: Maybe<Array<SubmittedInformationForVisitService>>;
  updatedAt: Scalars["DateTime"];
};

export type InventoryItemPropertyInput = {
  name: Scalars["String"];
  options?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateProceduresForVisitInput = {
  procedures?: Maybe<Array<UpdateProcedureBatchInput>>;
  visitId: Scalars["ID"];
};

export type UpdateServiceTypeCategoryInput = {
  categoryId: Scalars["ID"];
  displayName: Scalars["String"];
  icon?: Maybe<ServiceIconInput>;
  index?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type InventoryItemEdge = {
  __typename?: "InventoryItemEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<InventoryItem>;
};

export type ApproveProcedureInput = {
  inventoryNotes?: Maybe<Scalars["String"]>;
  procedureId: Scalars["ID"];
  staffNotes?: Maybe<Scalars["String"]>;
};

export type ServiceIcon = {
  __typename?: "ServiceIcon";
  selectedUrl: Scalars["String"];
  unselectedUrl: Scalars["String"];
};

export type UpdateEmployeeProfileInput = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

/** A question asked to a customer in the consent flow */
export type ConsentQuestion = {
  __typename?: "ConsentQuestion";
  flagVisitWhenEqual?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  index: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  isRequired?: Maybe<Scalars["Boolean"]>;
  question: Scalars["String"];
  type?: Maybe<ConsentQuestionType>;
  updatedAt: Scalars["DateTime"];
};

export type UpdateMemberInAccountInput = {
  accountRole: AccountSeatRole;
  seatId: Scalars["ID"];
};

export type ProcedureInventorySelectionSelectedValueInput = {
  name: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

/** A return payload for the visit service in a mutation */
export type VisitServiceMutationResponse = {
  __typename?: "VisitServiceMutationResponse";
  errors?: Maybe<Array<InputError>>;
  visitService?: Maybe<VisitService>;
};

export enum InventoryItemType {
  Piercing = "PIERCING",
}

export type InvitedStoreEmployeeResponse = {
  __typename?: "InvitedStoreEmployeeResponse";
  errors?: Maybe<Array<InputError>>;
  invite?: Maybe<InvitedStoreEmployee>;
};

/** Represents a user session, including a token, refresh token and a currrent user */
export type Session = {
  __typename?: "Session";
  /** Long lived refresh token to be persisted */
  refreshToken: Scalars["String"];
  /** JWT for the authed user */
  token: Scalars["String"];
  /** The authed user */
  user: User;
};

export type RemoveMemberInAccountInput = {
  seatId: Scalars["ID"];
};

export type UserMutationResponse = {
  __typename?: "UserMutationResponse";
  errors?: Maybe<Array<InputError>>;
  user?: Maybe<User>;
};

export type RemoveConsentQuestionForStoreInput = {
  consentQuestionId: Scalars["ID"];
};

export type VisitCustomerInformationInput = {
  address?: Maybe<AddressInput>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  lastName: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type InviteToAccountInput = {
  accountId: Scalars["ID"];
  accountRole: AccountSeatRole;
  emailAddress: Scalars["String"];
};

export type ToggleServiceTypeForStoreInput = {
  serviceTypeId: Scalars["ID"];
};

export type InviteStoreEmployeeInput = {
  artistServices?: Maybe<Array<ServiceTypeOffering>>;
  emailAddress: Scalars["String"];
  role: StoreEmployeeRole;
  storeId: Scalars["ID"];
};

export type StoreEmployeeFilters = {
  roles?: Maybe<Array<Maybe<StoreEmployeeRole>>>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type AccountSeatEdge = {
  __typename?: "AccountSeatEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<AccountSeat>;
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  /** Fetches an account by the slug provided */
  account?: Maybe<Account>;
  /** Returns a public invite to an account for an employee. */
  accountInvite?: Maybe<PublicAccountInvite>;
  /**
   * Retrieves all accounts on Bodyart. Can only be performed
   * by staff members.
   */
  accounts?: Maybe<AccountConnection>;
  /** Returns a public invite to a store for an employee. */
  employeeInvite?: Maybe<PublicStoreInvite>;
  /** Returns the given node by its global id. */
  node?: Maybe<Node>;
  /** Takes a reset password token and returns whether or not it's currently valid */
  validateResetPasswordToken?: Maybe<GenericSuccessResponse>;
  /** The currently authenticated viewer */
  viewer: User;
};

export type RootQueryTypeAccountArgs = {
  slug?: Maybe<Scalars["String"]>;
};

export type RootQueryTypeAccountInviteArgs = {
  token: Scalars["String"];
};

export type RootQueryTypeAccountsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<AccountFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type RootQueryTypeEmployeeInviteArgs = {
  token: Scalars["String"];
};

export type RootQueryTypeNodeArgs = {
  id: Scalars["ID"];
};

export type RootQueryTypeValidateResetPasswordTokenArgs = {
  token: Scalars["String"];
};

export type RemoveServiceTypeCategoryServiceInput = {
  serviceTypeCategoryServiceId: Scalars["ID"];
};

export enum ConsentQuestionType {
  Boolean = "BOOLEAN",
  Text = "TEXT",
  YesNo = "YES_NO",
}

export type InvitedAccountSeatResponse = {
  __typename?: "InvitedAccountSeatResponse";
  errors?: Maybe<Array<InputError>>;
  invite?: Maybe<InvitedAccountSeat>;
};

/** A category containing one or more consent questions */
export type ConsentQuestionCategory = {
  __typename?: "ConsentQuestionCategory";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  index: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  /** Returns a list of consent questions for this category */
  questions?: Maybe<Array<ConsentQuestion>>;
  updatedAt: Scalars["DateTime"];
};

export type StoreVisitFilters = {
  scheduledFor?: Maybe<DateRangeInput>;
  searchTerm?: Maybe<Scalars["String"]>;
  status?: Maybe<VisitFilterStatusType>;
};

export type BillingPortalSessionResponse = {
  __typename?: "BillingPortalSessionResponse";
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type InputError = {
  __typename?: "InputError";
  /** The originating field for the error */
  field: Scalars["String"];
  /** A description of the error */
  message: Scalars["String"];
  /** Validation type */
  type?: Maybe<ErrorType>;
};

/** Represents a customer at a store location */
export type StoreContact = Node & {
  __typename?: "StoreContact";
  customer?: Maybe<Customer>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  lastVisitAt?: Maybe<Scalars["DateTime"]>;
  /** Retrieves the minors connected with the customer */
  minors?: Maybe<Array<Maybe<StoreContact>>>;
  /** Retrieves the parent for the minor */
  parent?: Maybe<StoreContact>;
  store: Store;
  updatedAt: Scalars["DateTime"];
  /** The visits for this contact */
  visits?: Maybe<VisitConnection>;
};

/** Represents a customer at a store location */
export type StoreContactVisitsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<ContactVisitFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/** A type of services that the store offers */
export type ServiceType = Node & {
  __typename?: "ServiceType";
  /** The available categories for this service type (ordered) */
  categories?: Maybe<Array<ServiceTypeCategory>>;
  disabledAt?: Maybe<Scalars["DateTime"]>;
  displayName: Scalars["String"];
  /** The ID of an object */
  id: Scalars["ID"];
  index: Scalars["Int"];
  insertedAt: Scalars["DateTime"];
  type: ServiceTypeOffering;
  updatedAt: Scalars["DateTime"];
};

export type SendVisitToCustomerInput = {
  visitId: Scalars["ID"];
};

/** A piece of inventory that the store offers */
export type InventoryItem = {
  __typename?: "InventoryItem";
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  name: Scalars["String"];
  properties?: Maybe<Array<InventoryItemProperty>>;
  services?: Maybe<Array<Service>>;
  type: InventoryItemType;
  updatedAt: Scalars["DateTime"];
};

export type StoreMutationResponse = {
  __typename?: "StoreMutationResponse";
  errors?: Maybe<Array<InputError>>;
  store?: Maybe<Store>;
};

export type AccountViewerContext = {
  __typename?: "AccountViewerContext";
  permissions?: Maybe<Array<Scalars["String"]>>;
  role?: Maybe<AccountSeatRole>;
};

export type CreateConsentQuestionForStoreInput = {
  categoryId: Scalars["ID"];
  flagVisitWhenEqual?: Maybe<Scalars["String"]>;
  index: Scalars["Int"];
  isRequired: Scalars["Boolean"];
  question: Scalars["String"];
  storeId: Scalars["ID"];
  type: ConsentQuestionType;
};

/** An invited account seat */
export type InvitedAccountSeat = {
  __typename?: "InvitedAccountSeat";
  account: Account;
  emailAddress: Scalars["String"];
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  role?: Maybe<AccountSeatRole>;
  updatedAt: Scalars["DateTime"];
};

export enum StoreEmployeeRole {
  Admin = "ADMIN",
  Artist = "ARTIST",
  FrontDesk = "FRONT_DESK",
}

/** A field to be captured during procedure selection */
export type ServiceInformationField =
  | ServiceInformationOptionField
  | ServiceInformationSelectField
  | ServiceInformationTextField;

export type StoreEmployeeConnection = {
  __typename?: "StoreEmployeeConnection";
  edges?: Maybe<Array<Maybe<StoreEmployeeEdge>>>;
  pageInfo: PageInfo;
};

/** A selected piece of inventory for a procedure */
export type ProcedureInventorySelection = {
  __typename?: "ProcedureInventorySelection";
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  inventoryItem?: Maybe<InventoryItem>;
  selectedValues?: Maybe<Array<SelectedVariantForProcedureInventorySelection>>;
  updatedAt: Scalars["DateTime"];
};

export type DateRangeInput = {
  from: Scalars["DateTime"];
  to: Scalars["DateTime"];
};

export type AccountSeat = {
  __typename?: "AccountSeat";
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  role?: Maybe<AccountSeatRole>;
  /** The store employee positions that the seat is attached to */
  storePositions?: Maybe<Array<StoreEmployee>>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type UpdateStoreAftercareInput = {
  aftercareInstructions: Scalars["String"];
  externalAftercareInstructionsUrl: Scalars["String"];
  storeAftercareId: Scalars["ID"];
};

export type InventoryItemFilters = {
  type?: Maybe<InventoryItemType>;
};

/** Public facing store invite for an employee */
export type PublicStoreInvite = {
  __typename?: "PublicStoreInvite";
  emailAddress: Scalars["String"];
  id: Scalars["ID"];
  store: Store;
};

export type PhoneNumber = {
  __typename?: "PhoneNumber";
  formatted?: Maybe<Scalars["String"]>;
  raw?: Maybe<Scalars["String"]>;
};

export type SignupUserInput = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type CreateInventoryItemForStoreInput = {
  name: Scalars["String"];
  properties?: Maybe<Array<InventoryItemPropertyInput>>;
  serviceIds?: Maybe<Array<Scalars["ID"]>>;
  storeId: Scalars["ID"];
  type: InventoryItemType;
};

export type ToggleServiceTypeCategoryForStoreInput = {
  serviceTypeCategoryId: Scalars["ID"];
};

export type CreateServiceTypeCategoryInput = {
  displayName: Scalars["String"];
  icon?: Maybe<ServiceIconInput>;
  index?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  serviceTypeId: Scalars["ID"];
};

export type VisitCustomerInformation = {
  __typename?: "VisitCustomerInformation";
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  idImageUrls?: Maybe<Array<Scalars["String"]>>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<PhoneNumber>;
};

export type InventoryItemConnection = {
  __typename?: "InventoryItemConnection";
  edges?: Maybe<Array<Maybe<InventoryItemEdge>>>;
  pageInfo: PageInfo;
};

export type StoreConsentQuestionFilters = {
  searchTerm?: Maybe<Scalars["String"]>;
};

export type CreateAccountInput = {
  billingEmailAddress: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type ServiceTypeCategoryServiceMutationResponse = {
  __typename?: "ServiceTypeCategoryServiceMutationResponse";
  errors?: Maybe<Array<Maybe<InputError>>>;
  serviceTypeCategoryService?: Maybe<ServiceTypeCategoryService>;
};

/** A service that the store offers */
export type Service = {
  __typename?: "Service";
  aftercareInstructions?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  downsizeTime?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  healingTime?: Maybe<Scalars["String"]>;
  icon?: Maybe<ServiceIcon>;
  id: Scalars["ID"];
  /**
   * A list of fields that should be shown to the customer to collect information
   * about this service when they are stipulating a procedure in their visit.
   */
  informationFields?: Maybe<Array<ServiceInformationField>>;
  insertedAt: Scalars["DateTime"];
  minimumAgeRequirement?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  requiresAdditionalInformation: Scalars["Boolean"];
  /** The service type for this service */
  serviceType: ServiceType;
  updatedAt: Scalars["DateTime"];
};

export type DenyVisitInput = {
  denyReason?: Maybe<Scalars["String"]>;
  visitId: Scalars["ID"];
};

export type AccountSeatConnection = {
  __typename?: "AccountSeatConnection";
  edges?: Maybe<Array<Maybe<AccountSeatEdge>>>;
  pageInfo: PageInfo;
};

export type StoreContactConnection = {
  __typename?: "StoreContactConnection";
  edges?: Maybe<Array<Maybe<StoreContactEdge>>>;
  pageInfo: PageInfo;
};

export type StoreAftercareMutationResponse = {
  __typename?: "StoreAftercareMutationResponse";
  errors?: Maybe<Array<InputError>>;
  storeAftercare?: Maybe<StoreAftercare>;
};

export type VisitConnection = {
  __typename?: "VisitConnection";
  edges?: Maybe<Array<Maybe<VisitEdge>>>;
  pageInfo: PageInfo;
};

export type CreateConsentQuestionCategoryForStoreInput = {
  description?: Maybe<Scalars["String"]>;
  index: Scalars["Int"];
  name: Scalars["String"];
  storeId: Scalars["ID"];
};

export type DenyProcedureInput = {
  denyReason?: Maybe<Scalars["String"]>;
  procedureId: Scalars["ID"];
};

export type AddProcedureInput = {
  assignedEmployeeId?: Maybe<Scalars["ID"]>;
  inventoryNotes?: Maybe<Scalars["String"]>;
  inventorySelections?: Maybe<Array<Maybe<ProcedureInventorySelectionInput>>>;
  serviceId: Scalars["ID"];
  staffNotes?: Maybe<Scalars["String"]>;
  submittedInformation?: Maybe<Array<SubmittedInformationForServiceInput>>;
  visitId: Scalars["ID"];
};

export type ConsentQuestionEdge = {
  __typename?: "ConsentQuestionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<ConsentQuestion>;
};

export type RealizeInviteToStoreInput = {
  inviteId: Scalars["ID"];
};

export type PublicAccountInvite = {
  __typename?: "PublicAccountInvite";
  account: Account;
  emailAddress: Scalars["String"];
  id: Scalars["ID"];
  role?: Maybe<AccountSeatRole>;
};

export type AccountMemberFilters = {
  role?: Maybe<AccountSeatRole>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type StoreEmployeeEdge = {
  __typename?: "StoreEmployeeEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<StoreEmployee>;
};

export type RemoveArtistFromProcedureInput = {
  procedureId: Scalars["ID"];
};

export type RealizeInviteToAccountInput = {
  inviteId: Scalars["ID"];
};

export type ConsentQuestionMutationResponse = {
  __typename?: "ConsentQuestionMutationResponse";
  consentQuestion?: Maybe<ConsentQuestion>;
  errors?: Maybe<Array<InputError>>;
};

export type ReorderServiceTypeForStoreInput = {
  position: Scalars["Int"];
  serviceTypeId: Scalars["ID"];
};

export type AccountMutationResponse = {
  __typename?: "AccountMutationResponse";
  account?: Maybe<Account>;
  errors?: Maybe<Array<InputError>>;
};

export type UpdateContactProfileInput = {
  address: AddressInput;
  contactId: Scalars["ID"];
  dateOfBirth: Scalars["Date"];
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type ServiceInformationOptionField = {
  __typename?: "ServiceInformationOptionField";
  isRequired: Scalars["Boolean"];
  label: Scalars["String"];
  name: Scalars["String"];
  options?: Maybe<Array<Scalars["String"]>>;
};

export type CompleteProcedureInput = {
  aftercareInstructions?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  inventoryNotes?: Maybe<Scalars["String"]>;
  inventorySelections?: Maybe<Array<Maybe<ProcedureInventorySelectionInput>>>;
  procedureId: Scalars["ID"];
  staffNotes?: Maybe<Scalars["String"]>;
  sterilizationLogImageUrl?: Maybe<Scalars["String"]>;
  sterilizationNotes?: Maybe<Scalars["String"]>;
};

export type SelectedVariantForProcedureInventorySelection = {
  __typename?: "SelectedVariantForProcedureInventorySelection";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type CreateVisitForExistingCustomerInput = {
  customerId: Scalars["ID"];
  scheduledFor?: Maybe<Scalars["DateTime"]>;
  storeId: Scalars["ID"];
};

export type ServiceInformationSelectField = {
  __typename?: "ServiceInformationSelectField";
  isRequired: Scalars["Boolean"];
  label: Scalars["String"];
  name: Scalars["String"];
  options?: Maybe<Array<Scalars["String"]>>;
};

export type AddressInput = {
  addressLineOne: Scalars["String"];
  addressLineTwo?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

export type ReorderConsentQuestionForStoreInput = {
  consentQuestionId: Scalars["ID"];
  position: Scalars["Int"];
};

export type RemoveConsentQuestionCategoryForStoreInput = {
  categoryId: Scalars["ID"];
};

export type UpdateStoreInput = {
  address: AddressInput;
  emailAddress?: Maybe<Scalars["String"]>;
  headerImageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  storeId: Scalars["ID"];
  website?: Maybe<Scalars["String"]>;
};

export type RemoveServiceTypeCategoryInput = {
  categoryId: Scalars["ID"];
};

export type Account = Node & {
  __typename?: "Account";
  billingEmailAddress?: Maybe<Scalars["String"]>;
  /** Returns the context for the viewer on the account (if exists) */
  context?: Maybe<AccountViewerContext>;
  delinquentAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  /** All members on this account. Must have the `account:list_members` permission to access. */
  members?: Maybe<AccountSeatConnection>;
  name: Scalars["String"];
  slug: Scalars["String"];
  /**
   * Looks up a store under the account by the slug. Will only return
   * the store if the store exists under this account.
   */
  store?: Maybe<Store>;
  /**
   * Returns all stores under this account that the currently authenticated
   * viewer can see.
   */
  stores?: Maybe<Array<Maybe<Store>>>;
  trialEndAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

export type AccountMembersArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<AccountMemberFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type AccountStoreArgs = {
  slug: Scalars["String"];
};

/** A return payload for the visit in a mutation */
export type VisitMutationResponse = {
  __typename?: "VisitMutationResponse";
  errors?: Maybe<Array<InputError>>;
  visit?: Maybe<Visit>;
};

export type UpdateProcedureInput = {
  assignedEmployeeId?: Maybe<Scalars["ID"]>;
  inventoryNotes?: Maybe<Scalars["String"]>;
  inventorySelections?: Maybe<Array<Maybe<ProcedureInventorySelectionInput>>>;
  procedureId: Scalars["ID"];
  serviceId: Scalars["ID"];
  staffNotes?: Maybe<Scalars["String"]>;
  submittedInformation?: Maybe<Array<SubmittedInformationForServiceInput>>;
};

export type RemoveInventoryItemForStoreInput = {
  inventoryItemId: Scalars["ID"];
};

export type StoreContactEdge = {
  __typename?: "StoreContactEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<StoreContact>;
};

export type UpdateServiceForStoreInput = {
  aftercareInstructions?: Maybe<Scalars["String"]>;
  displayName: Scalars["String"];
  downsizeTime?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  healingTime?: Maybe<Scalars["String"]>;
  icon?: Maybe<ServiceIconInput>;
  minimumAgeRequirement?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  serviceId: Scalars["ID"];
};

export type StoreViewerContext = {
  __typename?: "StoreViewerContext";
  permissions?: Maybe<Array<Scalars["String"]>>;
  role?: Maybe<StoreEmployeeRole>;
};

export type UploadResponse = {
  __typename?: "UploadResponse";
  authKey: Scalars["String"];
  expires: Scalars["String"];
  signature: Scalars["String"];
  templateId: Scalars["String"];
};

export type AccountFilters = {
  name?: Maybe<Scalars["String"]>;
};

/** An employee who has access to this store */
export type StoreEmployee = {
  __typename?: "StoreEmployee";
  artistServices?: Maybe<Array<Maybe<ServiceTypeOffering>>>;
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  roles?: Maybe<Array<Maybe<StoreEmployeeRole>>>;
  store: Store;
  updatedAt: Scalars["DateTime"];
  user: User;
};

/** What kind of text field is it? */
export enum ServiceInformationTextFieldType {
  Text = "TEXT",
  Textarea = "TEXTAREA",
}

export type InventoryItemProperty = {
  __typename?: "InventoryItemProperty";
  name: Scalars["String"];
  options?: Maybe<Array<Scalars["String"]>>;
};

export type StoreContactMutationResponse = {
  __typename?: "StoreContactMutationResponse";
  contact?: Maybe<StoreContact>;
  errors?: Maybe<Array<InputError>>;
};

export type ConsentQuestionCategoryMutationResponse = {
  __typename?: "ConsentQuestionCategoryMutationResponse";
  consentQuestionCategory?: Maybe<ConsentQuestionCategory>;
  errors?: Maybe<Array<InputError>>;
};

export type InventoryItemMutationResponse = {
  __typename?: "InventoryItemMutationResponse";
  errors?: Maybe<Array<InputError>>;
  inventoryItem?: Maybe<InventoryItem>;
};

export type StoreAftercare = {
  __typename?: "StoreAftercare";
  aftercareInstructions?: Maybe<Scalars["String"]>;
  externalAftercareInstructionsUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  serviceType: ServiceTypeOffering;
  updatedAt: Scalars["DateTime"];
};

export type GenerateUploadInput = {
  type: ImageUploadType;
};

export enum ImageUploadType {
  ImageUpload = "IMAGE_UPLOAD",
  ProfileImage = "PROFILE_IMAGE",
  StoreImage = "STORE_IMAGE",
}

export type ResetStoreAftercareForServiceTypeInput = {
  serviceType: ServiceTypeOffering;
  storeId: Scalars["ID"];
};

export type ReorderServiceTypeCategoryServiceForStoreInput = {
  position: Scalars["Int"];
  serviceTypeCategoryServiceId: Scalars["ID"];
};

export type StoreEmployeeResponse = {
  __typename?: "StoreEmployeeResponse";
  errors?: Maybe<Array<InputError>>;
  storeEmployee?: Maybe<StoreEmployee>;
};

export type UpdateConsentQuestionForStoreInput = {
  consentQuestionId: Scalars["ID"];
  flagVisitWhenEqual?: Maybe<Scalars["String"]>;
  index: Scalars["Int"];
  isRequired: Scalars["Boolean"];
  question: Scalars["String"];
  type: ConsentQuestionType;
};

/** An invited store employee */
export type InvitedStoreEmployee = {
  __typename?: "InvitedStoreEmployee";
  artistServices?: Maybe<Array<Maybe<ServiceTypeOffering>>>;
  emailAddress: Scalars["String"];
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  role: StoreEmployeeRole;
  store: Store;
  updatedAt: Scalars["DateTime"];
};

export type UpdateProcedureBatchInput = {
  assignedEmployeeId: Scalars["ID"];
  id: Scalars["ID"];
  inventoryNotes?: Maybe<Scalars["String"]>;
  inventorySelections?: Maybe<Array<Maybe<ProcedureInventorySelectionInput>>>;
  staffNotes?: Maybe<Scalars["String"]>;
};

export type ContactVisitFilters = {
  status?: Maybe<VisitFilterStatusType>;
};

export type StoreCustomerFilters = {
  searchTerm?: Maybe<Scalars["String"]>;
};

export type RemoveProcedureInput = {
  procedureId: Scalars["ID"];
};

export type CreateVisitForNewCustomerInput = {
  customerInformation: VisitCustomerInformationInput;
  scheduledFor?: Maybe<Scalars["DateTime"]>;
  storeId: Scalars["ID"];
};

/** The status a visit is in, for the visit as a whole */
export enum VisitStatus {
  Approved = "APPROVED",
  Completed = "COMPLETED",
  ConsentRequested = "CONSENT_REQUESTED",
  Denied = "DENIED",
  Draft = "DRAFT",
  Pending = "PENDING",
  Reviewing = "REVIEWING",
  Submitted = "SUBMITTED",
}

export type GenerateBillingPortalSessionForAccountInput = {
  accountId: Scalars["ID"];
};

/** A store location for a piercing and or tattoo shop */
export type Store = Node & {
  __typename?: "Store";
  account?: Maybe<Account>;
  /** The associated address for this store */
  address: Address;
  /** Returns a list of aftercare defaults for this store */
  aftercareDefaults?: Maybe<Array<Maybe<StoreAftercare>>>;
  /**
   * Returns all of the category of consent questions that should be answered
   * for this store
   */
  consentQuestionCategories?: Maybe<Array<Maybe<ConsentQuestionCategory>>>;
  /** All the current consent questions for this store. Accessible to everyone. */
  consentQuestions?: Maybe<ConsentQuestionConnection>;
  /** Returns the viewer context for this store */
  context?: Maybe<StoreViewerContext>;
  /** All the customers who have a connection to this store */
  customers?: Maybe<StoreContactConnection>;
  /** Present if the store is not allowed to be visited */
  disabledAt?: Maybe<Scalars["DateTime"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  /**
   * All the employees who have a seat at this store, including system
   * managed ones (account admins).
   */
  employees?: Maybe<StoreEmployeeConnection>;
  headerImageUrl?: Maybe<Scalars["String"]>;
  /** The ID of an object */
  id: Scalars["ID"];
  insertedAt: Scalars["DateTime"];
  /** All of the inventory items for this store. Will hide deleted items. */
  inventoryItems?: Maybe<InventoryItemConnection>;
  /** All invited employees for this store. Not paginated. */
  invitedEmployees?: Maybe<Array<Maybe<InvitedStoreEmployee>>>;
  /** Looks up a customer by their email or phone number (must supply one) */
  lookupCustomer?: Maybe<Customer>;
  name: Scalars["String"];
  /** URL to trigger a new visit as a deep link and place the customer in the side queue */
  newVisitUnscheduledUrl: Scalars["String"];
  /** URL to trigger a new visit as a deep link */
  newVisitUrl: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  /** A list of service types offered at this store */
  serviceTypes: Array<ServiceType>;
  slug: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  /** Returns all visits that the current viewer can see for this store. */
  visits?: Maybe<VisitConnection>;
  website?: Maybe<Scalars["String"]>;
};

/** A store location for a piercing and or tattoo shop */
export type StoreConsentQuestionsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<StoreConsentQuestionFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/** A store location for a piercing and or tattoo shop */
export type StoreCustomersArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<StoreCustomerFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/** A store location for a piercing and or tattoo shop */
export type StoreEmployeesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<StoreEmployeeFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/** A store location for a piercing and or tattoo shop */
export type StoreInventoryItemsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<InventoryItemFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/** A store location for a piercing and or tattoo shop */
export type StoreLookupCustomerArgs = {
  emailAddress?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

/** A store location for a piercing and or tattoo shop */
export type StoreVisitsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<StoreVisitFilters>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type ProcedureInventorySelectionInput = {
  inventoryItemId: Scalars["ID"];
  selectedValues?: Maybe<
    Array<Maybe<ProcedureInventorySelectionSelectedValueInput>>
  >;
};

/** The join of a category and a service */
export type ServiceTypeCategoryService = {
  __typename?: "ServiceTypeCategoryService";
  category: ServiceTypeCategory;
  disabledAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  index: Scalars["Int"];
  service: Service;
  updatedAt: Scalars["DateTime"];
};

/** The status of a visit service */
export enum VisitServiceStatus {
  Approved = "APPROVED",
  Completed = "COMPLETED",
  Denied = "DENIED",
  Reviewing = "REVIEWING",
}

export type SubmittedInformationForVisitService = {
  __typename?: "SubmittedInformationForVisitService";
  label: Scalars["String"];
  name: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

/** A session, but with errors attached */
export type SessionMutationResponse = {
  __typename?: "SessionMutationResponse";
  errors?: Maybe<Array<InputError>>;
  session?: Maybe<Session>;
};
