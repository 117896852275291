import React from "react";
import {
  Checkbox,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
  Flex,
  Box,
  Heading,
} from "@chakra-ui/core";
import PageHeader from "../PageHeader";
import Button from "../Button";
import FormField from "../FormField";
import { Formik } from "formik";
import * as Yup from "yup";
import { pick } from "lodash";
import { useMutation } from "@apollo/client";
import { Header, Section } from "../VisitSheet/Section";
import { MARK_ID_AS_VERIFIED } from "./gql";
import ImagePicker from "../ImagePicker";
import { ImageUploadType } from "../../types";

const schema = Yup.object().shape({
  signature: Yup.boolean()
    .required()
    .equals([true], "You must e-sign to continue"),
});

const IdImage = ({ onChange, value, label }) => (
  <Box mb={3}>
    <Heading
      fontSize={14}
      color="darkGrey"
      fontWeight={500}
      textTransform="uppercase"
      marginBottom={2}
    >
      {label}
    </Heading>

    <Box
      borderRadius={20}
      backgroundColor="lightGrey"
      width={275}
      height={300}
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        zIndex={1}
        backgroundColor="brandYellow.500"
        borderRadius={60}
        right={"20px"}
        top={"20px"}
      >
        <ImagePicker
          onClear={() => onChange({ ssl_url: null })}
          onUpload={onChange}
          type={ImageUploadType.ImageUpload}
        />
      </Box>

      {!value && (
        <Text marginTop={120} marginX={5} textAlign="center">
          Select or take an image of the customers ID
        </Text>
      )}

      {value && (
        <Image src={value} objectFit="cover" height="100%" width="100%" />
      )}
    </Box>
  </Box>
);

const ReviewIdentity = ({ visit, totalSteps, history, onClose }) => {
  const [markIdAsVerified, { loading }] = useMutation(MARK_ID_AS_VERIFIED, {
    onCompleted: (data) => {
      if (data.result.visit) {
        history.push(totalSteps === 3 ? "/medical-concerns" : "/services");
      }
    },
  });

  const isMinorVisit = visit.customer.isMinor;

  return (
    <Formik
      initialValues={{
        visitId: visit.id,
        signature: visit.idVerifiedAt,
        idImageUrls: visit.customerInformation?.idImageUrls || [],
        parentIdImageUrls: visit.parentCustomerInformation?.idImageUrls,
      }}
      validationSchema={schema}
      onSubmit={(input) => {
        markIdAsVerified({
          variables: {
            input: pick(input, ["visitId", "idImageUrls", "parentIdImageUrls"]),
          },
        });
      }}
    >
      {(props) => (
        <Flex
          flexDirection="column"
          position="relative"
          overflow="hidden"
          padding={6}
          flex={1}
        >
          <PageHeader
            title="Review Identity"
            renderSubHeader={() => (
              <Text color="darkGrey" fontSize="md" marginLeft="2">
                Step 1 of {totalSteps}
              </Text>
            )}
            renderRightControls={() => (
              <ModalCloseButton size="lg" right={0} top={-2} />
            )}
          />
          <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
            <Flex
              as="form"
              onSubmit={props.handleSubmit}
              flexDirection={["column", "row"]}
            >
              <Section marginRight={[0, 5]} isLast>
                <Header title="Verify Photo ID" />

                {isMinorVisit && (
                  <IdImage
                    value={(props.values.parentIdImageUrls || [])[0]}
                    label="Guardian Photo ID (optional)"
                    onChange={(file) =>
                      props.setFieldValue("parentIdImageUrls[0]", file.ssl_url)
                    }
                  />
                )}

                <IdImage
                  value={(props.values.idImageUrls || [])[0]}
                  label={isMinorVisit ? "Minor Photo ID" : "Customer Photo ID"}
                  onChange={(file) => {
                    props.setFieldValue("idImageUrls[0]", file.ssl_url);
                  }}
                />
              </Section>
              <Section isLast>
                <Header title="Signature" />

                <FormField label="E-Signature" name="signature">
                  {(props) => (
                    <Checkbox
                      {...props}
                      alignItems="flex-start"
                      variantColor="yellow"
                      fontWeight="medium"
                    >
                      {isMinorVisit
                        ? "I sign that I have reviewed both the legal guardian and minor identities in person. I have verified and approved the legal guardian to submit consent on behalf of the minor who will be having the procedure(s) performed."
                        : "I sign that I have checked the customer’s photo ID in person and verified that they are of legal age for the service they have requested."}
                    </Checkbox>
                  )}
                </FormField>
              </Section>
            </Flex>
          </ModalBody>
          <Flex
            justifyContent="flex-end"
            paddingTop={5}
            borderTop="1px solid"
            borderTopColor="black"
          >
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!props.isValid}
              onClick={props.handleSubmit}
              isLoading={loading}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default ReviewIdentity;
