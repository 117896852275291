import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../../../../components/Button";
import { useMutation } from "@apollo/client";
import { UPDATE_ROLE } from "./gql";
import { Body } from "../../../../components/Typography";
import { AccountSeatRole } from "../../../../types";

const ChangeSeatRoleDialog = ({ seat, isOpen, onClose }) => {
  const toast = useToast();
  const [changeSeatRole, { loading }] = useMutation(UPDATE_ROLE, {
    variables: {
      input: {
        seatId: seat?.id,
        accountRole:
          seat?.role === AccountSeatRole.Admin
            ? AccountSeatRole.Employee
            : AccountSeatRole.Admin,
      },
    },
    refetchQueries: ["MembersForAccount"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast({
        title: "Employee role has been changed",
        status: "success",
        position: "bottom-left",
      });
      onClose();
    },
  });

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Change Role
        </AlertDialogHeader>

        <AlertDialogBody>
          {seat?.role === AccountSeatRole.Employee ? (
            <Body>
              Are you sure you'd like to make this team member an admin? They
              will be granted access to all stores in this account and will be
              able to manage any store.
            </Body>
          ) : (
            <Body>
              Are you sure you'd like to make this admin an employee? They will
              lose their admin rights, but will still have access to all stores
              in the account unless you remove them manually.
            </Body>
          )}
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dangerFilled"
            onClick={() => changeSeatRole()}
            isLoading={loading}
            ml={3}
          >
            Change role
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ChangeSeatRoleDialog;
