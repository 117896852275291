import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  useToast,
  Link,
  Input,
  Text,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import FormField from "../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { CREATE_STORE } from "./gql";
import { phoneRegExp } from "../../../../lib/form";
import InputModal from "../../../../components/InputModal";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  website: Yup.string().url().nullable(),
  emailAddress: Yup.string().email().nullable(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(),
  address: Yup.object().shape({
    addressLineOne: Yup.string().required(),
    addressLineTwo: Yup.string().nullable(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string().required(),
  }),
});

const ManageStore = ({ isOpen, onClose, accountId }) => {
  const toast = useToast();

  const [performMutation, { loading }] = useMutation(CREATE_STORE, {
    refetchQueries: ["Account"],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: `Store Created`,
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = {
    accountId,
    name: "",
    website: "",
    emailAddress: "",
    phoneNumber: "",
    address: {
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      state: "",
      zipCode: "",
      country: "US",
    },
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title={"Create Store"}
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await performMutation({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Text mt={3} p={3} backgroundColor="lightGrey">
                  Please note adding a new store will change your current
                  subscription and may incur an immediate charge to your card on
                  file. See{" "}
                  <Link
                    href="https://www.bodyart.app/pricing"
                    textDecoration="underline"
                  >
                    our pricing
                  </Link>{" "}
                  for more details, or get in touch with{" "}
                  <Link
                    href="mailto:support@bodyart.app"
                    textDecoration="underline"
                  >
                    support
                  </Link>{" "}
                  if you have questions.
                </Text>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} width={["100%", "50%"]}>
                    <FormField name="name" label="Name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="website" label="Website">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="phoneNumber" label="Phone Number">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="emailAddress" label="Email">
                      {(props) => (
                        <Input type="email" {...props} variant="filled" />
                      )}
                    </FormField>

                    <FormField
                      name="address.addressLineOne"
                      label="Address Line 1"
                    >
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField
                      name="address.addressLineTwo"
                      label="Address Line 2"
                    >
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="address.city" label="City">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <Flex>
                      <FormField name="address.state" label="State">
                        {(props) => <Input {...props} variant="filled" />}
                      </FormField>

                      <FormField name="address.zipCode" label="Zipcode">
                        {(props) => <Input {...props} variant="filled" />}
                      </FormField>
                    </Flex>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageStore;
