import {
  VisitStatus,
  VisitServiceStatus,
  StoreEmployeeRole,
  ServiceTypeOffering,
} from "./types";

export const Features = {
  // Enable/disable enhanced inventory selection and management
  inventorySelectionAndManagement: false,
};

export const EmployeeRoleName = {
  [StoreEmployeeRole.Admin]: "Admin",
  [StoreEmployeeRole.Artist]: "Artist",
  [StoreEmployeeRole.FrontDesk]: "Front-desk",
};

export const ServiceTypes = {
  [ServiceTypeOffering.Piercing]: "Piercing",
  [ServiceTypeOffering.Tattoo]: "Tattoo",
  [ServiceTypeOffering.PermanentMakeup]: "Permanent Makeup",
};

export const VisitStatusMessage = {
  [VisitStatus.Pending]: "Pending",
  [VisitStatus.Draft]: "Draft",
  [VisitStatus.Submitted]: "Submitted",
  [VisitStatus.ConsentRequested]: "Consent Requested",
  [VisitStatus.Reviewing]: "Ready for Review",
  [VisitStatus.Approved]: "Approved",
  [VisitStatus.Denied]: "Denied",
  [VisitStatus.Completed]: "Completed",
};

export const VisitStatusColor = {
  [VisitStatus.Pending]: "gray",
  [VisitStatus.Draft]: "gray",
  [VisitStatus.Submitted]: "blue",
  [VisitStatus.ConsentRequested]: "blue",
  [VisitStatus.Reviewing]: "green",
  [VisitStatus.Approved]: "blue",
  [VisitStatus.Denied]: "red",
};

export const VisitServiceStatusMessage = {
  [VisitServiceStatus.Reviewing]: "Ready for Review",
  [VisitServiceStatus.Approved]: "Approved",
  [VisitServiceStatus.Denied]: "Denied",
  [VisitServiceStatus.Completed]: "Completed",
};

export const VisitServiceStatusColor = {
  [VisitServiceStatus.Reviewing]: "green",
  [VisitServiceStatus.Approved]: "blue",
  [VisitServiceStatus.Denied]: "red",
  [VisitServiceStatus.Completed]: "blue",
};

const PrioritizedVisitServiceStatuses = [
  VisitServiceStatus.Reviewing,
  VisitServiceStatus.Approved,
  VisitServiceStatus.Completed,
  VisitServiceStatus.Denied,
];

export function determineVisitServiceStatus(statuses) {
  // The case where there are no statuses here *should* never happen, but
  // this is left as a guard just in case
  if (statuses.length === 0) return PrioritizedVisitServiceStatuses[0];

  if (statuses.length === 1) return statuses[0];

  return statuses.sort(function (a, b) {
    return (
      PrioritizedVisitServiceStatuses.indexOf(a) -
      PrioritizedVisitServiceStatuses.indexOf(b)
    );
  })[0];
}

export const MEDICAL_QUESTIONS = {
  isGenerallyHealthy:
    "Do you believe you are healthy enough to receive and successfully heal body art such as piercing, tattooing or permanent makeup?",
  hasSkinCondition: "Do you have a history of skin conditions?",
  hasIndicatedMedicationUse:
    "Do you use any medications that might affect the healing of your body art?",
  hasAllergyToProducts:
    "Do you have sensitivities or allergies to soaps, cosmetics, antiseptics, natural rubber latex, or metals of any kind?",
  isProneToFainting:
    "Are you prone to fainting, epilepsy, seizures, or narcolepsy?",
  hasBloodConditions:
    "Do you have any medical conditions that might affect the healing of your body art, such as diabetes, high blood pressure, or hemophilia?",
  hasHistoryOfComplications:
    "Do you have a history of complications with body art?",
};
