import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  Image,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../PageHeader";
import Button from "../Button";
import FormField from "../FormField";
import { useMutation } from "@apollo/client";
import { EDIT_PROFILE } from "./gql";
import InformationRow from "../VisitSheet/InformationRow";
import ImagePicker from "../ImagePicker";
import { ImageUploadType } from "../../types";
import InputModal from "../InputModal";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Firstname is required"),
  lastName: Yup.string().required("Lastname is required"),
  emailAddress: Yup.string().email().nullable(),
});

const EditUserProfile = ({ isOpen, onClose, user }) => {
  const toast = useToast();
  const [updateProfile, { loading }] = useMutation(EDIT_PROFILE, {
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Profile updated",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    emailAddress: user.emailAddress,
    profileImageUrl: user.profileImageUrl,
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Edit Profile"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await updateProfile({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} maxWidth={["100%", "50%"]} flex={1}>
                    <FormField name="firstName" label="First name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="lastName" label="Last name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <FormField name="emailAddress" label="Email">
                      {(props) => (
                        <Input type="email" {...props} variant="filled" />
                      )}
                    </FormField>
                  </Box>
                  <Box paddingY={5} maxWidth={["100%", "50%"]} flex={1}>
                    <InformationRow label="Profile Image">
                      <Box
                        width={120}
                        height={120}
                        borderRadius={120}
                        backgroundColor="secondaryMidGrey"
                        position="relative"
                      >
                        {formProps.values.profileImageUrl && (
                          <Image
                            rounded="full"
                            size="120px"
                            position="absolute"
                            top={0}
                            src={formProps.values.profileImageUrl}
                          />
                        )}

                        {isOpen && (
                          <Box
                            position="absolute"
                            zIndex={1}
                            backgroundColor="brandYellow.500"
                            borderRadius={60}
                            right={0}
                          >
                            <ImagePicker
                              type={ImageUploadType.ProfileImage}
                              onClear={() => {
                                formProps.setFieldValue(
                                  "profileImageUrl",
                                  null,
                                );
                              }}
                              onUpload={(file) => {
                                formProps.setFieldValue(
                                  "profileImageUrl",
                                  file.ssl_url,
                                );
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </InformationRow>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default EditUserProfile;
