import {
  Box,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/core";
import { without } from "lodash";
import React, { useState } from "react";

const TagInput = ({ value, name, onChange }) => {
  const [newValue, setNewValue] = useState("");

  const removeItem = (item) => {
    const update = without(value, item);
    onChange({ target: { name, value: update } });
  };

  return (
    <Box>
      {value && value.length > 0 && (
        <Box paddingBottom={2} backgroundColor="white">
          {value.map((option) => (
            <Tag size="sm" mr={2} mb={2}>
              <TagLabel>{option}</TagLabel>
              <TagCloseButton onClick={() => removeItem(option)} />
            </Tag>
          ))}
        </Box>
      )}
      <InputGroup size="sm">
        <Input
          placeholder="Add new value"
          variant="filled"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
        <InputRightElement width="2rem">
          <IconButton
            aria-label="Add"
            icon="add"
            size="sm"
            variant="unstyled"
            display="flex"
            alignItems="center"
            onClick={() => {
              if (newValue !== "") {
                onChange({ target: { name, value: [...value, newValue] } });
                setNewValue("");
              }
            }}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default TagInput;
