import React from "react";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import ReviewProcedure from "./ReviewProcedure";
import Finished from "./Finished";
import InputModal from "../InputModal";

const compose = (Component, p) => (props) => <Component {...p} {...props} />;

const CompleteProceduresFlow = ({ visit, onClose, onCompleted, isOpen }) => {
  const screenProps = {
    visit,
    visitServiceIds: visit.services.map((vs) => vs.id),
    onClose,
    onCompleted,
    totalSteps: 1,
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Router key={`visit-${visit.id}`}>
        <Switch>
          <Route
            exact
            path="/"
            render={compose(ReviewProcedure, screenProps)}
          />
          <Route
            exact
            path="/finished"
            render={compose(Finished, screenProps)}
          />
        </Switch>
      </Router>
    </InputModal>
  );
};

export default CompleteProceduresFlow;
