import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Text,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import FormField from "../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { CREATE_CATEGORY, UPDATE_CATEGORY } from "./gql";
import { Section, Header } from "../../../../components/VisitSheet/Section";
import InputModal from "../../../../components/InputModal";
import SelectServiceIcon from "../../../../components/SelectServiceIcon";
import { prepareForSubmission } from "../../../../lib/form";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  displayName: Yup.string().required("Display name is required"),
});

enum Mode {
  Creating = "CREATING",
  Updating = "UPDATING",
}

const Mutations = {
  [Mode.Creating]: CREATE_CATEGORY,
  [Mode.Updating]: UPDATE_CATEGORY,
};

const ManageServiceTypeCategory = ({
  isOpen,
  onClose,
  serviceType,
  category,
}) => {
  const toast = useToast();
  const mode = category ? Mode.Updating : Mode.Creating;
  const [updateCategory, { loading }] = useMutation(Mutations[mode], {
    refetchQueries: ["ServiceType"],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Service type category saved",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = {
    name: category?.name,
    displayName: category?.displayName,
    index: category?.index,
    icon: category?.icon,
    ...(mode === Mode.Creating
      ? { serviceTypeId: serviceType?.id }
      : { categoryId: category?.id }),
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Manage Category"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const input = prepareForSubmission({
              ...values,
            });

            const { data } = await updateCategory({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection="column"
                  onSubmit={formProps.handleSubmit}
                >
                  <Section>
                    <Header title="Information" />

                    <Flex flexDirection={["column", "row"]}>
                      <Box flex={1} width={["100%", "50%"]}>
                        <Text maxW={350} color="darkGrey" mb={5} fontSize={14}>
                          Add the main details for this category.
                        </Text>
                      </Box>
                      <Box flex={1} width={["100%", "50%"]}>
                        <FormField name="name" label="Name">
                          {(props) => <Input {...props} variant="filled" />}
                        </FormField>
                        <FormField
                          name="displayName"
                          label="Display Name (shown in app)"
                        >
                          {(props) => <Input {...props} variant="filled" />}
                        </FormField>
                        <FormField name="icon" label="Icon">
                          {(props) => <SelectServiceIcon {...props} />}
                        </FormField>
                      </Box>
                    </Flex>
                  </Section>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={loading}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageServiceTypeCategory;
