import { gql } from "@apollo/client";
import {
  inventoryItem,
  serviceForCategoryListing,
  serviceType,
} from "../../../../fragments";

export const INVENTORY_ITEMS = gql`
  query InventoryItemsForStore(
    $storeId: ID!
    $first: Number!
    $after: String
    $filters: InventoryItemFilters!
  ) {
    store: node(id: $storeId) {
      ... on Store {
        id
        inventoryItems(first: $first, after: $after, filterBy: $filters) {
          edges {
            node {
              ...inventoryItem
              services {
                ...serviceForCategoryListing
              }
            }
          }
        }
      }
    }
  }
  ${inventoryItem}
  ${serviceForCategoryListing}
`;

export const REMOVE_INVENTORY_ITEM = gql`
  mutation RemoveInventoryItem($input: RemoveInventoryItemForStoreInput!) {
    result: removeInventoryItemForStore(input: $input) {
      inventoryItem {
        ...inventoryItem
      }
      errors {
        field
        message
      }
    }
  }
  ${inventoryItem}
`;

export const CREATE_INVENTORY_ITEM = gql`
  mutation CreateInventoryItem($input: CreateInventoryItemForStoreInput!) {
    result: createInventoryItemForStore(input: $input) {
      inventoryItem {
        ...inventoryItem
      }
      errors {
        field
        message
      }
    }
  }
  ${inventoryItem}
`;

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem($input: UpdateInventoryItemForStoreInput!) {
    result: updateInventoryItemForStore(input: $input) {
      inventoryItem {
        ...inventoryItem
      }
      errors {
        field
        message
      }
    }
  }
  ${inventoryItem}
`;

export const SERVICE_TYPES = gql`
  query ServiceTypesForInventory($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        id
        serviceTypes {
          ...serviceType
        }
      }
    }
  }
  ${serviceType}
`;
