import React from "react";
import { ModalBody, Flex, Box, Heading, Icon } from "@chakra-ui/core";
import Button from "../Button";
import { Body } from "../Typography";
import { useLazyQuery } from "@apollo/client";
import { VISIT } from "../VisitSheet/gql";

const Finished = ({ visit, onCompleted }) => {
  // Refetch the visit and close the dialog at the end
  const [fetchVisit, { loading }] = useLazyQuery(VISIT);

  return (
    <Flex
      flexDirection="column"
      position="relative"
      overflow="hidden"
      padding={6}
      flex={1}
    >
      <ModalBody
        margin={0}
        padding={0}
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        flex={1}
        display="flex"
      >
        <Box textAlign="center" maxWidth={450}>
          <Icon name="tick" width={"52px"} height={"40px"} mb={3} />

          <Heading size="lg" textAlign="center" mb={3}>
            Visit Completed
          </Heading>

          <Body>
            This visit is now completed and the customer will see any given
            aftercare in their app.
          </Body>
        </Box>
      </ModalBody>
      <Flex
        justifyContent="flex-end"
        paddingTop={5}
        borderTop="1px solid"
        borderTopColor="black"
      >
        <Button
          variant="primary"
          type="submit"
          isLoading={loading}
          onClick={async () => {
            await fetchVisit({ variables: { visitId: visit.id } });
            onCompleted();
          }}
        >
          Done
        </Button>
      </Flex>
    </Flex>
  );
};

export default Finished;
