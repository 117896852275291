import React from "react";
import {
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Flex,
  Box,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../PageHeader";
import Button from "../Button";
import FormField from "../FormField";
import { Formik } from "formik";
import { prepareForSubmission } from "../../lib/form";
import { useMutation } from "@apollo/client";
import { CREATE_VISIT_FOR_NEW_CUSTOMER } from "./gql";
import { Header, Section } from "../VisitSheet/Section";
import { phoneRegExp } from "../../lib/form";
import { useLocation } from "react-router-dom";

export type FormInputs = {
  firstName: string;
  lastName: string;
  emailAddress: string;
};

type LocationState = {
  emailAddress: string;
  phoneNumber: string;
  currentlyInStore: boolean;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required("First name is required"),
  lastName: Yup.string().nullable().required("Last name is required"),
  emailAddress: Yup.string().email().nullable().required("Email is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(),
});

const NewCustomerInformation = ({ onClose, store, onVisitCreated }) => {
  const toast = useToast();
  const location = useLocation<LocationState>();
  const [createVisit] = useMutation(CREATE_VISIT_FOR_NEW_CUSTOMER, {
    onCompleted: (data) => {
      if (!data.result.errors) {
        toast({
          title: "Visit created for customer",
          status: "success",
          position: "bottom-left",
        });
        onClose();
        onVisitCreated(data.result.visit);
      }
    },
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    emailAddress: location?.state?.emailAddress || "",
    phoneNumber: location?.state?.phoneNumber || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        return createVisit({
          variables: {
            input: {
              storeId: store.id,
              customerInformation: prepareForSubmission(values),
            },
          },
        });
      }}
    >
      {(props) => (
        <Flex
          flexDirection="column"
          position="relative"
          overflow="hidden"
          height={"100%"}
          padding={6}
        >
          <PageHeader
            title="New Customer Information"
            renderRightControls={() => (
              <ModalCloseButton size="lg" right={0} top={-2} />
            )}
          />
          <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
            <Box as="form" onSubmit={props.handleSubmit}>
              <Box width={["100%", "50%"]}>
                <Section>
                  <Header title="Contact Info" />

                  <FormField name="firstName" label="First name">
                    {(props) => <Input {...props} variant="filled" />}
                  </FormField>
                  <FormField name="lastName" label="Last name">
                    {(props) => <Input {...props} variant="filled" />}
                  </FormField>
                  <FormField name="emailAddress" label="Email Address">
                    {(props) => (
                      <Input {...props} type="email" variant="filled" />
                    )}
                  </FormField>
                  <FormField name="phoneNumber" label="Phone Number">
                    {(props) => <Input {...props} variant="filled" />}
                  </FormField>
                </Section>
              </Box>
            </Box>
          </ModalBody>
          <Flex
            justifyContent="flex-end"
            paddingTop={5}
            borderTop="1px solid"
            borderTopColor="black"
          >
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={props.handleSubmit}
              isLoading={props.isSubmitting}
            >
              Create visit
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default NewCustomerInformation;
