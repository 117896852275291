import React from "react";
import { Text, BoxProps } from "@chakra-ui/core";

export const SectionHeader = ({ children }) => (
  <Text
    fontWeight="medium"
    fontSize={15}
    textTransform="uppercase"
    color="darkGrey"
    marginBottom={3}
  >
    {children}
  </Text>
);

export const Label = ({ children, ...rest }) => (
  <Text fontSize="sm" textTransform="uppercase" color="darkGrey" {...rest}>
    {children}
  </Text>
);

type BodyProps = {
  color?: string;
};

export const Body: React.FC<BodyProps & BoxProps> = ({
  children,
  color = "black",
  ...rest
}) => (
  <Text fontSize="md" lineHeight="shorter" color={color} {...rest}>
    {children}
  </Text>
);
