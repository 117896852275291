import { useQuery } from "@apollo/client";
import { Box, Text, useDisclosure } from "@chakra-ui/core";
import React from "react";
import { get, includes } from "lodash";
import Button from "../Button";
import { INVENTORY } from "./gql";
import SelectInventoryDialog from "./SelectInventoryDialog";

/*
  Allows inventory to be selected for proecedures.
*/

const SelectInventoryForProcedure = ({
  storeId,
  serviceId,
  name,
  onChange,
  value,
}) => {
  const disclosure = useDisclosure();

  const { data, loading } = useQuery(INVENTORY, {
    variables: { storeId },
  });

  const inventoryItems = get(data, "store.inventoryItems.edges", [])
    .map((edge) => edge.node)
    .filter((ii) =>
      includes(
        ii.services.map((s) => s.id),
        serviceId,
      ),
    );

  const selectedItem = inventoryItems.find(
    (item) => item.id === value?.inventoryItemId,
  );

  return (
    <Box>
      <SelectInventoryDialog
        onClose={disclosure.onClose}
        isOpen={disclosure.isOpen}
        inventoryItems={inventoryItems}
        value={value}
        onSubmit={(selected) => {
          onChange({ target: { name, value: selected } });
          disclosure.onClose();
        }}
      />
      {selectedItem && (
        <Box mb={3}>
          <Text>Type: {selectedItem.name}</Text>

          {value.selectedValues?.map((v) => (
            <Text key={v.name}>
              {v.name}: {v.value}
            </Text>
          ))}
        </Box>
      )}

      {value ? (
        <>
          <Button
            isLoading={loading}
            variant="ghostSmall"
            onClick={disclosure.onOpen}
            mr={2}
          >
            Edit
          </Button>
          <Button
            isLoading={loading}
            variant="ghostSmall"
            onClick={() => onChange({ target: { name, value: null } })}
          >
            Clear
          </Button>
        </>
      ) : (
        <Button
          isLoading={loading}
          variant="ghostSmall"
          onClick={disclosure.onOpen}
        >
          Select inventory
        </Button>
      )}
    </Box>
  );
};

export default SelectInventoryForProcedure;
