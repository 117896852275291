import React, { useRef, useState } from "react";
import Uppy from "@uppy/core";
import Transloadit from "@uppy/transloadit";
import {
  Flex,
  Icon,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";
import { useMutation } from "@apollo/client";
import { get } from "lodash";
import useUppy from "../../hooks/useUppy";
import { GENERATE_UPLOAD } from "./gql";

type File = {
  ssl_url: string;
};

type Props = {
  onClear?: () => void;
  onUpload: (file: File) => void;
  type: string;
};

const ImagePicker: React.FC<Props> = ({ onUpload, onClear, type }) => {
  const fileRef = useRef<HTMLInputElement>();
  const [isUploading, setIsUploading] = useState(false);
  const [generateUpload] = useMutation(GENERATE_UPLOAD);

  const uppy = useUppy(() =>
    Uppy({ autoProceed: true })
      .use(Transloadit, {
        getAssemblyOptions: async () => {
          const { data } = await generateUpload({
            variables: { input: { type } },
          });

          return {
            signature: data.result.signature,
            params: {
              auth: {
                key: data.result.authKey,
                expires: data.result.expires,
              },
              template_id: data.result.templateId,
            },
          };
        },
        waitForEncoding: true,
      })
      .on("file-added", () => {
        setIsUploading(true);
      })
      .on("transloadit:complete", (assembly) => {
        // TODO: revisit the assumption that everything will end with this `compress_image` step
        const result = get(assembly, "results.compress_image[0]");

        if (result) {
          onUpload(result);
        } else {
          alert("There was an error with the upload, please try again");
        }

        uppy.reset();
        fileRef.current.value = null;
        setIsUploading(false);
      }),
  );

  return (
    <Flex alignItems="center" justifyContent="center">
      <input
        type="file"
        ref={fileRef}
        style={{ visibility: "hidden", position: "absolute" }}
        onChange={(e) => {
          const file = e.target.files[0];

          uppy.addFile({
            source: "file input",
            name: file.name,
            type: file.type,
            data: file,
          });
        }}
      />

      <Flex
        width="40px"
        height="40px"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
      >
        {isUploading && <Spinner size="sm" color="black" />}

        {!isUploading && (
          <Menu>
            <MenuButton as={Icon} {...{ name: "camera" }} fontSize="25px" />
            <MenuList placement="bottom-end">
              <MenuItem onClick={() => fileRef.current?.click()}>
                Select image
              </MenuItem>
              {onClear && <MenuItem onClick={onClear}>Clear image</MenuItem>}
            </MenuList>
          </Menu>
        )}
      </Flex>
    </Flex>
  );
};

export default ImagePicker;
