import {
  Badge,
  Box,
  Flex,
  Heading,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  useDisclosure,
  Text,
} from "@chakra-ui/core";
import React from "react";
import { uniq, map } from "lodash";
import Button from "../Button";
import HealthInformation from "./HealthInformation";
import InformationRow from "./InformationRow";
import Procedures from "./Procedures";
import ReviewProceduresFlow from "../ReviewProceduresFlow";
import CompleteProceduresFlow from "../CompleteProceduresFlow";
import { VisitServiceStatus, VisitStatus } from "../../types";
import {
  VisitServiceStatusMessage,
  VisitServiceStatusColor,
  determineVisitServiceStatus,
} from "../../constants";
import DenyProcedureDialogue from "./DenyProcedureDialogue";
import { formatDateOfBirth } from "../../lib/customer";
import ConsentAnswers from "./ConsentAnswers";
import { useMutation } from "@apollo/client";
import { COMPLETE_PROCEDURE } from "../CompleteProceduresFlow/gql";
import { defaultAftercareForVisitService } from "../../lib/services";

enum VisitActions {
  Approve = "Approve",
  Deny = "Deny",
  Complete = "Complete",
}

const visitPermissions = {
  [VisitServiceStatus.Reviewing]: {
    [VisitActions.Deny]: true,
    [VisitActions.Approve]: true,
    [VisitActions.Complete]: false,
  },
  [VisitServiceStatus.Approved]: {
    [VisitActions.Deny]: false,
    [VisitActions.Approve]: false,
    [VisitActions.Complete]: true,
  },
  [VisitServiceStatus.Denied]: {
    [VisitActions.Deny]: false,
    [VisitActions.Approve]: false,
    [VisitActions.Complete]: false,
  },
  [VisitServiceStatus.Completed]: {
    [VisitActions.Deny]: false,
    [VisitActions.Approve]: false,
    [VisitActions.Complete]: false,
  },
};

const ArtistVisitDetails = ({ visit, refetchVisit, onClose: onCloseSheet }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDenyOpen,
    onOpen: onDenyOpen,
    onClose: onDenyClose,
  } = useDisclosure();
  const {
    isOpen: isCompleteOpen,
    onOpen: onCompleteOpen,
    onClose: onCompleteClose,
  } = useDisclosure();
  const [completeProcedure] = useMutation(COMPLETE_PROCEDURE);

  const visitStatuses = uniq(visit.services.map((vs) => vs.status));
  const visitStatus = determineVisitServiceStatus(visitStatuses);
  const permissions = visitPermissions[visitStatus];

  const quickComplete = async () => {
    const filteredVisitServices = visit.services.filter(
      (vs) => vs.status === VisitServiceStatus.Approved,
    );
    const promises = map(filteredVisitServices, (visitService) => {
      const aftercare = defaultAftercareForVisitService(
        visitService,
        visit.store,
      );

      const initialValues = {
        procedureId: visitService.id,
        aftercareInstructions: aftercare.aftercareInstructions,
        externalAftercareInstructionsUrl:
          aftercare.externalAftercareInstructionsUrl,
        staffNotes: visitService.staffNotes,
        sterilizationNotes: visitService.sterilizationNotes,
        sterilizationLogImageUrl: visitService.sterilizationLogImageUrl,
        inventoryNotes: visitService.inventoryNotes,
        inventorySelections: visitService.inventorySelections.map((is) => ({
          inventoryItemId: is.inventoryItem.id,
          selectedValues: (is.selectedValues || []).map((v) => ({
            name: v.name,
            value: v.value,
          })),
        })),
      };

      return completeProcedure({ variables: { input: initialValues } });
    });

    await Promise.all(promises);

    onCloseSheet();
  };

  return (
    <>
      <ReviewProceduresFlow
        visit={visit}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
      <CompleteProceduresFlow
        visit={visit}
        isOpen={isCompleteOpen}
        onClose={onCompleteClose}
        onCompleted={() => {
          onClose();
          onCloseSheet();
        }}
      />
      <DenyProcedureDialogue
        procedures={visit.services}
        isOpen={isDenyOpen}
        onClose={onDenyClose}
      />
      <DrawerHeader padding={0} paddingBottom={0}>
        <Flex
          direction="row"
          alignItems="center"
          padding={5}
          paddingTop={3}
          borderBottom={"2px solid"}
          borderBottomColor="black"
        >
          <Box>
            <Badge variantColor={VisitServiceStatusColor[visitStatus]}>
              {VisitServiceStatusMessage[visitStatus]}
            </Badge>
            {visit.customer.isMinor && <Badge>Minor</Badge>}

            <Flex
              justifyContent="flex-start"
              alignItems={["flex-start", "center"]}
              flexDirection={["column", "row"]}
            >
              <Heading fontSize="2xl">{visit.customer.name}</Heading>
              {visit.customer.isMinor && (
                <Text fontSize={16} fontWeight="normal" ml={[0, 2]}>
                  Guardian: {visit.customer.parent.name}
                </Text>
              )}
            </Flex>
            <Text fontSize={14} fontWeight={400}>
              DOB: {formatDateOfBirth(visit.customer.dateOfBirth)}
            </Text>
          </Box>
        </Flex>
      </DrawerHeader>
      <DrawerBody paddingX={0} paddingY={0} overflowY="scroll">
        {visit.status === VisitStatus.Denied && visit.denyReason && (
          <Box padding={5} paddingBottom={1} backgroundColor="lightGrey">
            <InformationRow
              label="Denial Reason"
              labelProps={{ color: "statusRed.500" }}
            >
              <Text>{visit.denyReason}</Text>
            </InformationRow>
          </Box>
        )}
        <Box px={5}>
          <Procedures
            visit={visit}
            services={visit.services}
            refetchVisit={refetchVisit}
            onCloseSheet={onCloseSheet}
            showArtist={false}
          />
          {visit.medicalHistory && <HealthInformation visit={visit} />}
          {visit.consentAnsweredAt && <ConsentAnswers visit={visit} />}
        </Box>
      </DrawerBody>
      {(permissions[VisitActions.Deny] ||
        permissions[VisitActions.Approve] ||
        permissions[VisitActions.Complete]) && (
        <DrawerFooter backgroundColor="black" paddingY={6}>
          <ButtonGroup>
            {permissions[VisitActions.Deny] && (
              <Button variant="danger" marginRight={2} onClick={onDenyOpen}>
                Deny
              </Button>
            )}
            {permissions[VisitActions.Approve] && (
              <Button variant="primary" onClick={onOpen}>
                Review Consent
              </Button>
            )}

            {permissions[VisitActions.Complete] && (
              <>
                <Button
                  variant="secondary"
                  mr={2}
                  onClick={async () => await quickComplete()}
                >
                  Quick Complete
                </Button>
                <Button variant="primary" onClick={onCompleteOpen}>
                  Complete Visit
                </Button>
              </>
            )}
          </ButtonGroup>
        </DrawerFooter>
      )}
    </>
  );
};

export default ArtistVisitDetails;
