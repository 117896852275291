import React from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from "@chakra-ui/core";
import { useApolloClient } from "@apollo/client";
import { useDispatch } from "react-redux";
import useCurrentUser from "../../hooks/useCurrentUser";
import { reset as resetAuth } from "../../state/auth";
import { useHistory } from "react-router-dom";
import EditUserProfile from "./EditUserProfile";

const UserMenu = () => {
  const currentUser = useCurrentUser();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onLogoutClick = () => {
    dispatch(resetAuth());
    client.clearStore();
  };

  return (
    <Menu>
      {currentUser && (
        <EditUserProfile user={currentUser} onClose={onClose} isOpen={isOpen} />
      )}

      <MenuButton
        as={Button}
        {...{
          rightIcon: "chevron-down",
          variant: "link",
          variantColor: "brandYellow",
        }}
        marginLeft="auto"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Flex marginLeft="auto" alignItems="center">
          <Avatar
            name={currentUser?.name}
            src={currentUser?.profileImageUrl}
            size="sm"
            borderColor="white"
            borderWidth={1}
            marginRight={[0, 3]}
          />
          <Box textAlign="left" display={["none", "block"]}>
            <Text fontWeight="normal" color="white" lineHeight="base">
              {currentUser?.name}
            </Text>
            <Text
              fontSize="sm"
              color="darkGrey"
              fontWeight="normal"
              lineHeight="none"
            >
              {currentUser?.emailAddress}
            </Text>
          </Box>
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onOpen}>Edit Profile</MenuItem>
        {currentUser?.isStaff && (
          <>
            <MenuItem onClick={() => history.push("/staff")}>Staff</MenuItem>
            <MenuDivider />
          </>
        )}
        <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
