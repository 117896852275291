import { Address } from "../types";

export function summarizeAddress(address: Address) {
  return [
    address.addressLineOne,
    address.addressLineTwo,
    address.city,
    address.state,
    address.zipCode,
  ]
    .filter((a) => a)
    .join(", ");
}
