import React, { useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  Text,
} from "@chakra-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { get } from "lodash";
import { QUERY_VISITS_FOR_STORE, ADD_VISIT_TO_QUEUE } from "./gql";
import { Store, Visit, VisitStatus } from "../../../../types";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import VisitCard from "../../../../components/VisitCard";

type Props = {
  onClose: (e: React.MouseEvent) => void;
  isOpen: boolean;
  store: Store;
  onVisitAdded: (visit: Visit) => void;
};

const AddVisitToQueue: React.FC<Props> = ({
  store,
  isOpen,
  onClose,
  onVisitAdded,
}) => {
  const [selected, setSelected] = React.useState(null);
  const [fetchVisits, { data }] = useLazyQuery(QUERY_VISITS_FOR_STORE, {
    fetchPolicy: "network-only",
    variables: {
      storeId: store.id,
      filters: { status: VisitStatus.Submitted },
      first: 999,
    },
  });

  const [addVisitToQueue, { loading: isAdding }] = useMutation(
    ADD_VISIT_TO_QUEUE,
    {
      onCompleted: ({ result }) => {
        if (!result.errors) {
          onVisitAdded(result.visit);
        }
      },
    },
  );

  useEffect(() => {
    fetchVisits();
    setSelected(null);
  }, [isOpen, fetchVisits]);

  const visitEdges = get(data, "store.visits.edges", []);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="lg">
      <DrawerOverlay height="100%" />
      <DrawerContent height="100%!important">
        <DrawerCloseButton size="lg" />
        <DrawerHeader>
          <PageHeader title="Add consent to queue" />
        </DrawerHeader>
        <DrawerBody paddingY={0} overflowY="scroll">
          {visitEdges.length === 0 && (
            <Text>No visits ready to add to todays queue.</Text>
          )}

          {visitEdges.map((edge) => {
            const isSelected = selected && selected.id === edge.node.id;

            return (
              <VisitCard
                key={edge.node.id}
                visit={edge.node}
                isSelected={isSelected}
                onClick={() => {
                  setSelected(isSelected ? null : edge.node);
                }}
              />
            );
          })}
        </DrawerBody>
        <DrawerFooter backgroundColor="black" paddingY={6}>
          <ButtonGroup>
            <Button variant="danger" marginRight={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!selected}
              isLoading={isAdding}
              onClick={() => {
                addVisitToQueue({
                  variables: { input: { visitId: selected.id } },
                });
              }}
            >
              Add to queue
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddVisitToQueue;
