import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn:
    "https://ab390de1d4c941c9becf13d84cf8b32e@o498544.ingest.sentry.io/5576203",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
