import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  userId: string | null;
  refreshToken: string | null;
  token: string | null;
};

const initialState: AuthState = {
  userId: null,
  refreshToken: null,
  token: null,
};

type SetAuthSessionFromSessionPayload = {
  userId: string;
  refreshToken: string;
  token: string;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthFromSession: (
      state,
      action: PayloadAction<SetAuthSessionFromSessionPayload>,
    ) => (state = action.payload),
    reset: (state) => initialState,
  },
});

// Exported for convienience
export const { setAuthFromSession, reset } = authSlice.actions;

export default authSlice;
