import { gql } from "@apollo/client";
import { storeInformation } from "../../../../fragments";

export const CREATE_STORE = gql`
  mutation CreateStoreForAccount($input: CreateStoreForAccountInput!) {
    result: createStoreForAccount(input: $input) {
      store {
        ...storeInformation
      }
      errors {
        field
        message
      }
    }
  }
  ${storeInformation}
`;
