import { gql } from "@apollo/client";
import { fullVisit, visitCustomerInformation } from "../../fragments";

export const VISIT = gql`
  query FetchVisit($visitId: ID!) {
    visit: node(id: $visitId) {
      ... on Visit {
        ...fullVisit
      }
    }
  }
  ${fullVisit}
`;

export const UPDATE_CUSTOMER_INFORMATION = gql`
  mutation UpdateCustomerInformationForVisit(
    $input: UpdateCustomerInformationForVisitInput!
  ) {
    result: updateCustomerInformationForVisit(input: $input) {
      visit {
        id
        customerInformation {
          ...visitCustomerInformation
        }
        parentCustomerInformation {
          ...visitCustomerInformation
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${visitCustomerInformation}
`;

export const DENY_VISIT = gql`
  mutation DenyVisit($input: DenyVisitInput!) {
    result: denyVisit(input: $input) {
      visit {
        id
        status
        denyReason
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DENY_PROCEDURE = gql`
  mutation DenyProcedure($input: DenyProcedureInput!) {
    result: denyProcedure(input: $input) {
      visitService {
        id
        status
        denyReason
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const SEND_VISIT = gql`
  mutation SendVisitToCustomer($input: SendVisitToCustomerInput!) {
    result: sendVisitToCustomer(input: $input) {
      visit {
        id
        status
        updatedAt
      }
      errors {
        field
        message
      }
    }
  }
`;
