import React, { useState } from "react";
import {
  Box,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  InputGroup,
  IconButton,
  InputRightElement,
} from "@chakra-ui/core";
import { get, without } from "lodash";
import { useApolloClient, useQuery } from "@apollo/client";
import { SERVICE_TYPES } from "./gql";
import { serviceForCategoryListing } from "../../../../fragments";

const ServiceSelect = ({
  isOpen,
  storeId,
  serviceIds,
  selectedServiceType,
  name,
  onChange,
}) => {
  const [value, setValue] = useState("");
  const client = useApolloClient();

  const { data } = useQuery(SERVICE_TYPES, {
    skip: !isOpen,
    variables: { storeId },
  });

  const serviceTypes = get(data, "store.serviceTypes", []);
  const serviceCategories = serviceTypes
    .filter((st) => st.type === selectedServiceType)
    .flatMap((st) => st.categories);

  const onRemoveClick = (serviceId) => {
    const update = without(serviceIds, serviceId);
    onChange({ target: { name, value: update } });
  };

  return (
    <Box>
      <Box paddingBottom={2}>
        {serviceIds.map((sid) => {
          const service = client.readFragment({
            id: `Service:${sid}`,
            fragmentName: "serviceForCategoryListing",
            fragment: serviceForCategoryListing,
          });

          return (
            <Tag size="sm" mr={2} mb={2}>
              <TagLabel>{service.displayName}</TagLabel>
              <TagCloseButton onClick={() => onRemoveClick(sid)} />
            </Tag>
          );
        })}
      </Box>

      <InputGroup>
        <Select mr={8} value={value} onChange={(e) => setValue(e.target.value)}>
          <option>Select a service</option>
          {serviceCategories.flatMap((stc) =>
            stc.services.map((stcs) => (
              <option key={stcs.service.id} value={stcs.service.id}>
                {stc.displayName} -{"  "}
                {stcs.service.displayName}
              </option>
            )),
          )}
        </Select>
        <InputRightElement width="2rem">
          <IconButton
            aria-label="Add"
            icon="add"
            size="sm"
            variant="unstyled"
            display="flex"
            alignItems="center"
            onClick={() => {
              if (value !== "") {
                setValue("");
                onChange({ target: { name, value: [...serviceIds, value] } });
              }
            }}
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default ServiceSelect;
