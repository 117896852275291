import React from "react";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Avatar,
  Text,
  ModalCloseButton,
  ModalBody,
  Icon,
  Box,
  Spinner,
} from "@chakra-ui/core";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import InputModal from "../InputModal";
import { STORE_ACCESS } from "./gql";
import PageHeader from "../PageHeader";
import Button from "../Button";
import { summarizeAddress } from "../../lib/address";
import { Label } from "../Typography";

const StoreSwitcher = ({ isOpen, onClose, currentStore, hideMenu }) => {
  const { loading, data } = useQuery(STORE_ACCESS, {});
  const history = useHistory();
  const accounts = get(data, "viewer.accounts", []);
  const [selectedStore, setSelectedStore] = React.useState(currentStore);

  React.useEffect(() => {
    setSelectedStore(currentStore);
  }, [currentStore]);

  const onStoreSelect = () => {
    history.push(`/${selectedStore.account.slug}/stores/${selectedStore.slug}`);

    hideMenu();
    onClose();
  };

  return (
    <InputModal size="lg" isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Switch Store"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
          {loading && <Spinner color="brandYellow" />}

          {!loading && accounts.length === 0 && (
            <Flex height="100%" alignItems="center" justifyContent="center">
              <Text mx={5} textAlign="center">
                There are no accounts connected to your user account.
              </Text>
            </Flex>
          )}

          {accounts.map((account) => (
            <Box key={account.id}>
              <Box
                color="midGrey"
                borderBottom="1px solid"
                borderBottomColor="midGrey"
                paddingY={3}
              >
                <Label>{account.name}</Label>
              </Box>

              {account.stores.map((store) => (
                <Flex
                  key={store.id}
                  borderBottom="1px solid"
                  borderBottomColor="midGrey"
                  paddingY={3}
                  cursor="pointer"
                  alignItems="center"
                  onClick={() => setSelectedStore(store)}
                >
                  <Avatar
                    size="sm"
                    src={store.profileImageUrl}
                    marginRight={3}
                    name={store.name}
                  />

                  <Box>
                    <Text
                      color="black"
                      fontSize="lg"
                      fontWeight="bold"
                      lineHeight={1}
                    >
                      {store?.name}
                    </Text>
                    <Text fontSize="sm" color="darkGrey">
                      {summarizeAddress(store.address) || "N/A"}
                    </Text>
                  </Box>

                  {selectedStore?.id === store.id && (
                    <Icon
                      name="tick"
                      size="24px"
                      marginLeft="auto"
                      marginRight={2}
                    />
                  )}
                </Flex>
              ))}
            </Box>
          ))}
        </ModalBody>

        <Flex
          justifyContent="flex-end"
          paddingTop={5}
          borderTop="1px solid"
          borderTopColor="black"
        >
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isLoading={loading}
            disabled={!selectedStore}
            onClick={onStoreSelect}
          >
            Done
          </Button>
        </Flex>
      </Flex>
    </InputModal>
  );
};

export default StoreSwitcher;
