import React from "react";
import {
  Box,
  ModalBody,
  ModalCloseButton,
  Text,
  Textarea,
  Flex,
  Link,
  Icon,
  Image,
} from "@chakra-ui/core";
import PageHeader from "../PageHeader";
import Button from "../Button";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { Header, Section } from "../VisitSheet/Section";
import { Body } from "../Typography";
import FormField from "../FormField";
import InformationRow from "../VisitSheet/InformationRow";
import {
  ImageUploadType,
  ServiceTypeOffering,
  Visit,
  VisitService,
} from "../../types";
import { COMPLETE_PROCEDURE } from "./gql";
import SelectInventoryForProcedure from "../SelectInventoryForProcedure";
import { Features } from "../../constants";
import {
  defaultAftercareForVisitService,
  serviceName,
} from "../../lib/services";
import ImagePicker from "../ImagePicker";

type Props = {
  visit: Visit;
  visitService: VisitService;
  onClose: () => void;
  onComplete: (input: any) => void;
  hasNext: boolean;
};

const CompleteProcedureForm: React.FC<Props> = ({
  visit,
  visitService,
  onClose,
  onComplete,
  hasNext,
}) => {
  const [completeProcedure, { loading }] = useMutation(COMPLETE_PROCEDURE, {
    onCompleted: ({ result }) => {
      if (!result.errors) {
        onComplete(result);
      }
    },
  });

  const aftercare = defaultAftercareForVisitService(visitService, visit.store);

  const initialValues = {
    procedureId: visitService.id,
    aftercareInstructions: aftercare.aftercareInstructions,
    externalAftercareInstructionsUrl:
      aftercare.externalAftercareInstructionsUrl,
    staffNotes: visitService.staffNotes,
    sterilizationNotes: visitService.sterilizationNotes,
    sterilizationLogImageUrl: visitService.sterilizationLogImageUrl,
    inventoryNotes: visitService.inventoryNotes,
    inventorySelections: visitService.inventorySelections.map((is) => ({
      inventoryItemId: is.inventoryItem.id,
      selectedValues: (is.selectedValues || []).map((v) => ({
        name: v.name,
        value: v.value,
      })),
    })),
  };

  const isPiercing =
    visitService.service.serviceType.type === ServiceTypeOffering.Piercing;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(input) => completeProcedure({ variables: { input } })}
    >
      {(formProps) => (
        <Flex
          flexDirection="column"
          position="relative"
          overflow="hidden"
          padding={6}
          flex={1}
        >
          <PageHeader
            title={`Complete ${visitService.service.serviceType.displayName} Service`}
            renderRightControls={() => (
              <ModalCloseButton size="lg" right={0} top={-2} />
            )}
          />
          <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
            <Flex
              as="form"
              flexDirection="column"
              onSubmit={formProps.handleSubmit}
            >
              <Section>
                <Header title={serviceName(visitService)} />

                <Flex flexDirection={["column", "row"]}>
                  <Box maxW={["100%", "50%"]} flex={1}>
                    <InformationRow label="Service Type">
                      <Body>
                        {visitService.service.serviceType.displayName}
                      </Body>
                    </InformationRow>
                    <InformationRow label="Service Name">
                      <Body>{visitService.service.displayName}</Body>
                    </InformationRow>

                    {visitService.submittedInformation.map((info) => (
                      <InformationRow label={info.label} key={info.name}>
                        <Body>{info.value || "N/A"}</Body>
                      </InformationRow>
                    ))}
                  </Box>
                  <Box maxW={["100%", "50%"]} flex={1}>
                    {Features.inventorySelectionAndManagement && isPiercing && (
                      <FormField
                        name={`inventorySelections[0]`}
                        label="Inventory"
                      >
                        {(props) => (
                          <SelectInventoryForProcedure
                            {...props}
                            serviceId={visitService.service.id}
                            storeId={visit.store.id}
                          />
                        )}
                      </FormField>
                    )}

                    {!Features.inventorySelectionAndManagement && isPiercing && (
                      <FormField name="inventoryNotes" label="Inventory">
                        {(props) => (
                          <Textarea
                            {...props}
                            placeholder="Inventory used during this procedure (the customer will see this)"
                            variant="filled"
                          />
                        )}
                      </FormField>
                    )}

                    <FormField
                      name={`staffNotes`}
                      label="Additional Notes (Optional)"
                    >
                      {(props) => (
                        <Textarea
                          {...props}
                          placeholder="Anything to be kept on file (only staff members can see this)"
                          variant="filled"
                        />
                      )}
                    </FormField>
                  </Box>
                </Flex>
              </Section>

              <Section>
                <Header title="Aftercare" />

                <Flex flexDirection={["column", "row"]}>
                  <Box maxW={["100%", "50%"]} flex={1}>
                    <Text maxW={350} color="darkGrey" mb={5} fontSize={14}>
                      Add any customer specific aftercare instructions here. The
                      default aftercare instructions for this service are
                      pre-populated.
                    </Text>

                    {aftercare.externalAftercareInstructionsUrl && (
                      <InformationRow label="External Instructions">
                        <Link
                          href={aftercare.externalAftercareInstructionsUrl}
                          color="blue.500"
                          display="inline-flex"
                          alignItems="center"
                          target="_blank"
                        >
                          <Icon name="document" mr={1} fontSize="22px" />
                          Aftercare Instructions
                        </Link>
                      </InformationRow>
                    )}
                  </Box>
                  <Box maxW={["100%", "50%"]} flex={1}>
                    <FormField
                      name={`aftercareInstructions`}
                      label="Aftercare Instructions"
                    >
                      {(props) => (
                        <Textarea
                          {...props}
                          placeholder="Please write some notes for the customer to use while healing..."
                          variant="filled"
                          height={180}
                        />
                      )}
                    </FormField>
                  </Box>
                </Flex>
              </Section>

              <Section>
                <Header title="Sterilization" />

                <Flex flexDirection={["column", "row"]}>
                  <Box maxW={["100%", "50%"]} flex={1}>
                    <FormField
                      name="sterilizationNotes"
                      label="Notes (Optional)"
                    >
                      {(props) => (
                        <Textarea
                          {...props}
                          placeholder="Please add any additional information here..."
                          variant="filled"
                          height={120}
                        />
                      )}
                    </FormField>
                  </Box>
                  <Box maxW={["100%", "50%"]} flex={1}>
                    <FormField
                      name="sterilizationLogImageUrl"
                      label="Photo of sterilization log (optional)"
                    >
                      {(props) => (
                        <Box
                          borderRadius={20}
                          backgroundColor="#EDF2F7"
                          width={275}
                          height={300}
                          position="relative"
                          overflow="hidden"
                        >
                          <Box
                            position="absolute"
                            zIndex={1}
                            backgroundColor="brandYellow.500"
                            borderRadius={60}
                            right={"20px"}
                            top={"20px"}
                          >
                            <ImagePicker
                              onClear={() => {
                                formProps.setFieldValue(
                                  "sterilizationLogImageUrl",
                                  null,
                                );
                              }}
                              onUpload={(file) =>
                                formProps.setFieldValue(
                                  "sterilizationLogImageUrl",
                                  file.ssl_url,
                                )
                              }
                              type={ImageUploadType.ImageUpload}
                            />
                          </Box>

                          {!formProps.values.sterilizationLogImageUrl && (
                            <Text
                              marginTop={120}
                              marginX={5}
                              textAlign="center"
                            >
                              Select or take an image
                            </Text>
                          )}

                          {formProps.values.sterilizationLogImageUrl && (
                            <Image
                              src={formProps.values.sterilizationLogImageUrl}
                              objectFit="cover"
                              height="100%"
                              width="100%"
                            />
                          )}
                        </Box>
                      )}
                    </FormField>
                  </Box>
                </Flex>
              </Section>
            </Flex>
          </ModalBody>
          <Flex
            justifyContent="flex-end"
            paddingTop={5}
            borderTop="1px solid"
            borderTopColor="black"
          >
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formProps.isValid}
              isLoading={loading}
              onClick={formProps.handleSubmit}
            >
              {hasNext ? "Next" : "Finish"}
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default CompleteProcedureForm;
