import { format, differenceInYears, parseISO } from "date-fns";

export function formatDateOfBirth(dob) {
  if (!dob) {
    return;
  }

  const date = parseISO(dob);
  const years = differenceInYears(new Date(), date);

  return format(date, "MM/dd/yyyy") + ` - ${years} yrs old`;
}
