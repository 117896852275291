import React from "react";
import { Heading, Box, Link, useToast } from "@chakra-ui/core";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import useDocumentTitle from "@rehooks/document-title";
import { useHistory, Link as RouterLink, useParams } from "react-router-dom";
import { RESET_PASSWORD } from "./gql";
import Form, { FormInputs } from "./Form";
import { setAuthFromSession } from "../../state/auth";
import { Session } from "../../types";

type ResetParams = {
  token: string;
};

function buildPayloadFromSession(session: Session) {
  return {
    userId: session.user.id,
    token: session.token,
    refreshToken: session.refreshToken,
  };
}

const ResetPassword = () => {
  useDocumentTitle("Reset Password - Bodyart");

  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams<ResetParams>();
  const toast = useToast();

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ result }) => {
      const sessionPayload = buildPayloadFromSession(result.session);
      dispatch(setAuthFromSession(sessionPayload));

      toast({
        title: "Your password has been reset",
        status: "success",
        position: "bottom-left",
      });

      history.push("/");
    },
  });

  return (
    <Box
      display="flex"
      flex={1}
      height="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor="black"
    >
      <Box maxW="md" rounded="lg" padding={4} flex={1}>
        <Box mb={4} textAlign="center">
          <Heading as="h1" size="lg" color="white" mb={2}>
            Reset your password
          </Heading>
        </Box>

        <Form
          token={token}
          onSubmit={async (input: FormInputs, actions) => {
            try {
              const {
                data: { result },
              } = await resetPassword({ variables: { input } });

              if (result.errors) {
                return result.errors.forEach((error) => {
                  actions.setFieldError(error.field, error.message);
                });
              }
            } catch (e) {
              actions.setFieldError(
                "password",
                "That reset token is no longer valid",
              );
            }
          }}
        />

        <Box textAlign="center" mt={5}>
          <Link
            color="brandYellow.500"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing={1}
            fontSize={12}
            as={RouterLink as any}
            {...{ to: "/login" }}
          >
            Login to your account
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
