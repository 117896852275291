import { useQuery } from "@apollo/client";
import { Box, Flex, Heading, Spinner, Text } from "@chakra-ui/core";
import React from "react";
import { get } from "lodash";
import { CUSTOMERS } from "./gql";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "@rehooks/document-title";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import PageHeader from "../../../../components/PageHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/Table";
import SearchField from "../../../../components/SearchField";
import { format, parseISO } from "date-fns";
import PaginationControls from "../../../../components/PaginationControls";

const PAGE_SIZE = 50;

const Customers = ({ store }) => {
  useDocumentTitle(`Customers - ${store.name} - Bodyart`);

  const [query, setQuery] = useQueryParams({
    searchTerm: StringParam,
    first: withDefault(NumberParam, PAGE_SIZE),
    last: NumberParam,
    before: StringParam,
    after: StringParam,
  });

  const { searchTerm } = query;
  const { data, loading } = useQuery(CUSTOMERS, {
    variables: {
      storeId: store.id,
      first: query.first,
      last: query.last,
      before: query.before,
      after: query.after,
      filters: {
        searchTerm,
      },
    },
  });

  const history = useHistory();
  const customerEdges = get(data, "store.customers.edges", []);
  const pageInfo = get(data, "store.customers.pageInfo");

  return (
    <Box padding={5}>
      <PageHeader
        title="Customers"
        renderRightControls={() => (
          <>
            <SearchField
              value={searchTerm || ""}
              placeholder="Search for a customer"
              onSubmit={(term) =>
                setQuery({ ...query, searchTerm: term }, "push")
              }
            />
          </>
        )}
      />
      <Box>
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            flex={1}
            padding={5}
          >
            <Spinner color="brandYellow.500" size="md" />
          </Flex>
        )}
        {!loading && customerEdges.length === 0 && (
          <Text marginY={5}>0 results found</Text>
        )}
        <Table marginBottom={10}>
          <TableBody>
            {customerEdges.map((edge) => (
              <TableRow
                height={"74px"}
                key={edge.node.id}
                onClick={() => history.push(`customers/${edge.node.id}`)}
              >
                <TableCell>
                  <Box>
                    <Heading fontSize={["md", "xl"]}>
                      {edge.node.customer.name}
                    </Heading>
                    <Text fontSize={14} fontWeight="500" color="darkGrey">
                      {edge.node.customer.emailAddress}
                    </Text>
                  </Box>
                </TableCell>
                <TableCell display={["none", "flex"]}> </TableCell>
                <TableCell display={["none", "flex"]} justifyContent="flex-end">
                  <Text color="darkGrey">
                    {edge.node.lastVisitAt
                      ? `Last visited ${format(
                          parseISO(edge.node.lastVisitAt),
                          "M/d/yyyy",
                        )}`
                      : "N/A"}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <PaginationControls
          pageSize={PAGE_SIZE}
          pageInfo={pageInfo}
          updatePageInfo={(props) => setQuery({ ...query, ...props })}
        />
      </Box>
    </Box>
  );
};

export default Customers;
