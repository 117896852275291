import { gql } from "@apollo/client";

export const GENERATE_BILLING_SESSION = gql`
  mutation GenerateBillingPortalSessionForAccount(
    $input: GenerateBillingPortalSessionForAccountInput!
  ) {
    result: generateBillingPortalSessionForAccount(input: $input) {
      redirectUrl
    }
  }
`;
