import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../../../../components/Button";
import { useMutation } from "@apollo/client";
import { REMOVE_INVENTORY_ITEM } from "./gql";
import { Body } from "../../../../components/Typography";

const DeleteDialog = ({ inventoryItemId, isOpen, onClose }) => {
  const toast = useToast();
  const [removeInventoryItem, { loading }] = useMutation(
    REMOVE_INVENTORY_ITEM,
    {
      variables: { input: { inventoryItemId } },
      refetchQueries: ["InventoryItemsForStore"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast({
          title: "Inventory item has been removed",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      },
    },
  );

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Remove Inventory Item
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            Are you sure you’d like to remove this inventory item? Deleting it
            will make it unavailable to be selected in any procedures and cannot
            be undone.
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dangerFilled"
            onClick={() => removeInventoryItem()}
            isLoading={loading}
            ml={3}
          >
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteDialog;
