import { gql } from "@apollo/client";
import {
  partialStoreEmployee,
  invitedStoreEmployee,
} from "../../../../../fragments";

export const LIST_EMPLOYEES = gql`
  query ListEmployeesForStoreSettings(
    $storeId: ID!
    $first: Number!
    $after: String
    $filters: StoreEmployeeFilters!
  ) {
    store: node(id: $storeId) {
      ... on Store {
        id
        employees(first: $first, after: $after, filterBy: $filters) {
          edges {
            node {
              ...partialStoreEmployee
            }
          }
        }
      }
    }
  }
  ${partialStoreEmployee}
`;

export const LIST_INVITED_EMPLOYEES = gql`
  query ListInvitedEmployeesForStore($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        id
        invitedEmployees {
          ...invitedStoreEmployee
        }
      }
    }
  }
  ${invitedStoreEmployee}
`;

export const INVITE_EMPLOYEE = gql`
  mutation InviteStoreEmployee($input: InviteStoreEmployeeInput!) {
    result: inviteEmployeeToStore(input: $input) {
      invite {
        ...invitedStoreEmployee
      }
      errors {
        field
        message
      }
    }
  }
  ${invitedStoreEmployee}
`;

export const REMOVE_EMPLOYEE = gql`
  mutation RemoveStoreEmployee($input: RemoveEmployeeFromStoreInput!) {
    result: removeEmployeeFromStore(input: $input) {
      storeEmployee {
        ...partialStoreEmployee
      }
      errors {
        field
        message
      }
    }
  }
  ${partialStoreEmployee}
`;

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateStoreEmployee($input: UpdateEmployeeForStoreInput!) {
    result: updateEmployeeForStore(input: $input) {
      storeEmployee {
        ...partialStoreEmployee
      }
      errors {
        field
        message
      }
    }
  }
  ${partialStoreEmployee}
`;
