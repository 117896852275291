import { gql } from "@apollo/client";
import { serviceType, visitService } from "../../fragments";

export const SERVICES = gql`
  query ListServices($storeId: ID!) {
    store: node(id: $storeId) {
      ... on Store {
        id
        serviceTypes {
          ...serviceType
        }
      }
    }
  }
  ${serviceType}
`;

export const ADD_PROCEDURE = gql`
  mutation AddProcedure($input: AddProcedureInput!) {
    addProcedure(input: $input) {
      visitService {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const REMOVE_PROCEDURE = gql`
  mutation RemoveProcedure($input: RemoveProcedureInput!) {
    removeProcedure(input: $input) {
      visitService {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_PROCEDURE = gql`
  mutation UpdateProcedure($input: UpdateProcedureInput!) {
    updateProcedure(input: $input) {
      visitService {
        ...visitService
      }
      errors {
        field
        message
      }
    }
  }
  ${visitService}
`;
