import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../../../../../components/Button";
import { useMutation } from "@apollo/client";
import { REMOVE_CONSENT_QUESTION } from "./gql";
import { Body } from "../../../../../components/Typography";

const RemoveConsentQuestionDialog = ({
  consentQuestionId,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const [removeConsentQuestion, { loading }] = useMutation(
    REMOVE_CONSENT_QUESTION,
    {
      variables: { input: { consentQuestionId } },
      refetchQueries: ["ConsentQuestionCategories"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast({
          title: "Consent question has been removed",
          status: "success",
          position: "bottom-left",
        });
        onClose();
      },
    },
  );

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Remove Consent Question
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            Are you sure you’d like to remove this consent question? Any
            existing answers submitted will still be captured in past consent
            forms, but new forms will no longer have this question going
            forward. Please note, this action cannot be reversed.
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dangerFilled"
            onClick={() => removeConsentQuestion()}
            isLoading={loading}
            ml={3}
          >
            Remove
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveConsentQuestionDialog;
