import React from "react";
import { Flex, Box, Icon } from "@chakra-ui/core";
import UserMenu from "./UserMenu";

type LayoutProps = {
  hideMenu: () => void;
};

type Props = {
  renderHeader: (props: LayoutProps) => JSX.Element;
  renderMenu?: (props: LayoutProps) => JSX.Element;
};

const MainLayout: React.FC<Props> = ({
  children,
  renderHeader,
  renderMenu,
}) => {
  const [menuVisible, setMenuVisibility] = React.useState(false);
  const toggleMenuVisibility = () => setMenuVisibility(!menuVisible);

  return (
    <Flex height="100%" direction="column" overflowY="hidden">
      <Flex
        backgroundColor="black"
        paddingX={5}
        paddingY={[3, 5]}
        alignItems="center"
      >
        <Icon
          name={menuVisible ? "close" : "menu"}
          color="white"
          onClick={() => toggleMenuVisibility()}
          cursor="pointer"
          display={["block", "none"]}
        />
        <Box display={["none", "block"]}>
          {renderHeader({ hideMenu: toggleMenuVisibility })}
        </Box>
        <UserMenu />
      </Flex>
      <Flex flex={1} overflow="hidden">
        {renderMenu && (
          <Flex
            width={["100%", "237px"]}
            height={"100%"}
            backgroundColor="black"
            borderTop={1}
            borderTopStyle="solid"
            borderTopColor="darkestGrey"
            display={[menuVisible ? "flex" : "none", "flex"]}
            flexDirection="column"
            padding={4}
            zIndex={1}
          >
            <Box mb={1} display={["block", "none"]}>
              {renderHeader({
                hideMenu: toggleMenuVisibility,
              })}
            </Box>
            <Flex flex={1}>
              {renderMenu({
                hideMenu: toggleMenuVisibility,
              })}
            </Flex>
          </Flex>
        )}
        <Flex
          flex={1}
          flexDirection="column"
          overflowY="scroll"
          backgroundColor="white"
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MainLayout;
