import { parseISO, format, formatDistance, isThisWeek } from "date-fns";

export function formatDate(date) {
  return format(parseISO(date), "MM/dd/yyyy");
}

export function formatDateTime(dt) {
  return format(parseISO(dt), "MM/dd/yyyy p");
}

export function formatRelative(dt) {
  const parsed = parseISO(dt);
  const now = new Date();

  return isThisWeek(parsed)
    ? formatDistance(parsed, now, { addSuffix: true })
    : format(parsed, "MM/dd/yyyy");
}
