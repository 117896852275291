import { Avatar, Box, Flex, Heading, Text } from "@chakra-ui/core";
import React from "react";
import { take, uniq } from "lodash";
import pluralize from "pluralize";

const AvatarStack = ({ users, totalToDisplay }) => {
  const totalUsers = users.length;
  const usersToDisplay = take(users, totalToDisplay);
  const remainder = usersToDisplay.length - totalUsers;

  return (
    <Flex alignItems="center">
      {usersToDisplay.map((user, idx) => {
        return (
          <Avatar
            key={idx}
            size="sm"
            src={user?.profileImageUrl}
            name={user?.name}
            marginLeft={idx > 0 ? -3 : 0}
            borderWidth={2}
            borderColor="white"
          />
        );
      })}
      {remainder > 0 && (
        <Box>
          <Text>+{remainder}</Text>
        </Box>
      )}
    </Flex>
  );
};

const MAX_TO_DISPLAY = 3;

const VisitService = ({ artists, totalServices, serviceName, services }) => {
  const uniqArtists = uniq(artists);
  const totalArtists = uniqArtists.length;
  const multipleArtists = totalArtists > MAX_TO_DISPLAY - 1;
  const usersForAvatars =
    totalArtists === 1
      ? uniqArtists.map((ae) => ae.user)
      : new Array(totalServices).fill(null).map((v, idx) => {
          return uniqArtists[idx]?.user;
        });

  const serviceNames = uniq(services.map((s) => s.service.displayName));

  return (
    <Flex alignItems="center" marginRight={6}>
      <AvatarStack totalToDisplay={MAX_TO_DISPLAY} users={usersForAvatars} />
      <Box marginLeft={3}>
        <Heading fontSize="md" lineHeight="none" fontWeight={500}>
          {pluralize(serviceName, totalServices, true)}
          {serviceNames.length > 0 ? `(${serviceNames.join(", ")})` : ""}
        </Heading>
        <Text
          color={artists.length > 0 ? "darkGrey" : "midGrey"}
          fontSize="sm"
          fontStyle={artists.length > 0 ? "regular" : "italic"}
        >
          {totalArtists === 0
            ? `Unassigned ${pluralize("artist", totalServices)}`
            : multipleArtists
            ? "Multiple artists"
            : uniqArtists.map((a) => a.user.name).join(", ")}
        </Text>
      </Box>
    </Flex>
  );
};

export default VisitService;
