import React from "react";
import { useToast } from "@chakra-ui/core";
import { isEqual } from "lodash";
import { useApolloClient } from "@apollo/client";
import { visitService as visitServiceFragment } from "../../fragments";
import { Visit, VisitServiceStatus } from "../../types";
import CompleteProcedureForm from "./CompleteProcedureForm";

type Props = {
  history: any;
  visit: Visit;
  visitServiceIds: string[];
  totalSteps: number;
  onClose: () => void;
};

const arePropsEqual = (prev, next) =>
  isEqual(prev.visitServiceIds, next.visitServiceIds);

const ReviewProcedure: React.FC<Props> = React.memo(
  ({ history, visit, visitServiceIds, onClose }) => {
    const toast = useToast();
    const client = useApolloClient();
    const [currentIdx, setCurrentIdx] = React.useState(0);
    const [servicesToReview, setServicesToReview] = React.useState([]);

    /*
    These visits can get in weird states, which is why we want to only operate
    on any of the services that have not yet been approved in this flow.

    We read these into state to prevent us from shifting items as we're updating
    them, which can happen when there are multiple services
    */
    React.useEffect(() => {
      const services = visitServiceIds.map((id) =>
        client.readFragment({
          fragment: visitServiceFragment,
          fragmentName: "visitService",
          id: `VisitService:${id}`,
        }),
      );

      const filteredServices = services.filter(
        (vs) => vs.status === VisitServiceStatus.Approved,
      );

      setServicesToReview(filteredServices);
    }, [visitServiceIds, client, setServicesToReview]);

    const totalVisitServices = servicesToReview.length - 1;
    const visitService = servicesToReview[currentIdx];

    if (!visitService) return null;

    return (
      <CompleteProcedureForm
        key={visitService.id}
        visit={visit}
        visitService={visitService}
        hasNext={currentIdx < totalVisitServices}
        onClose={onClose}
        onComplete={() => {
          toast({
            title: "Procedure saved",
            status: "success",
            position: "bottom-left",
          });

          if (currentIdx < totalVisitServices) {
            setCurrentIdx(currentIdx + 1);
            return;
          }

          history.push("/finished");
        }}
      />
    );
  },
  arePropsEqual,
);

export default ReviewProcedure;
