import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
} from "@chakra-ui/core";
import React, { useEffect } from "react";
import { useQueryParams, StringParam } from "use-query-params";
import useDocumentTitle from "@rehooks/document-title";
import Employees from "./Employees";
import StoreInfo from "./StoreInfo";
import Links from "./Links";
import ConsentQuestions from "./ConsentQuestions";
import Aftercare from "./Aftercare";

const tabs = [
  {
    name: "shop",
    text: "Shop Info",
    render: (props) => <StoreInfo {...props} />,
  },
  {
    name: "employees",
    text: "Employees",
    render: (props) => <Employees {...props} />,
  },
  { name: "links", text: "Links", render: (props) => <Links {...props} /> },
  {
    name: "consent-questions",
    text: "Consent",
    render: (props) => <ConsentQuestions {...props} />,
  },
  {
    name: "aftercare",
    text: "Aftercare",
    render: (props) => <Aftercare {...props} />,
  },
];

const Settings = ({ store }) => {
  useDocumentTitle(`Settings - ${store.name} - Bodyart`);

  const [query, setQuery] = useQueryParams({
    page: StringParam,
  });

  useEffect(() => {
    if (!query.page) {
      setQuery({ ...query, page: tabs[0].name });
    }
  }, [query, setQuery]);

  const currentTab = tabs.findIndex((t) => t.name === query.page) || 0;
  const onTabChange = (index) => setQuery({ ...query, page: tabs[index].name });

  return (
    <Box padding={5}>
      <Tabs
        variant="unstyled"
        variantColor="black"
        index={currentTab === -1 ? 0 : currentTab}
        onChange={onTabChange}
      >
        <Box
          borderBottom="1px solid"
          borderBottomColor="black"
          display={["block", "none"]}
        >
          <Select
            value={currentTab}
            onChange={(e) => onTabChange(e.target.selectedIndex)}
            marginBottom={5}
            variant="unstyled"
            fontSize={18}
            fontWeight="600"
            width="150px"
          >
            {tabs.map((tab, idx) => (
              <option value={idx} key={tab.name}>
                {tab.text}
              </option>
            ))}
          </Select>
        </Box>
        <TabList
          borderBottom="1px solid"
          borderBottomColor="black"
          display={["none", "flex"]}
        >
          {tabs.map((tab) => (
            <Tab
              paddingX={0}
              paddingY={5}
              mr={5}
              fontSize={24}
              fontWeight="600"
              color="midGrey"
              _selected={{ color: "black" }}
              _active={{ color: "black" }}
              key={tab.name}
            >
              {tab.text}
            </Tab>
          ))}
        </TabList>
        <Box>
          <TabPanels>
            {tabs.map(({ name, render }) => (
              <TabPanel key={name}>{render({ store })}</TabPanel>
            ))}
          </TabPanels>
        </Box>
      </Tabs>
    </Box>
  );
};

export default Settings;
