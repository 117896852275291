import { useQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from "@chakra-ui/core";
import { useHistory, useParams } from "react-router-dom";
import useDocumentTitle from "@rehooks/document-title";
import pluralize from "pluralize";
import React, { useState } from "react";
import { get } from "lodash";
import { SERVICE_TYPE } from "./gql";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/Table";
import ItemMenu from "../../../../components/ItemMenu";
import { formatDate } from "../../../../lib/dates";
import ToggleVisibilityDialog from "./ToggleVisibilityDialog";
import ManageServiceTypeCategory from "./ManageServiceTypeCategory";
import DeleteDialog from "./DeleteDialog";

type ServiceTypeCategoryParams = {
  id: string;
};

const ServiceTypeCategories = ({ store }) => {
  useDocumentTitle(`Services - ${store.name} - Bodyart`);

  const history = useHistory();
  const params = useParams<ServiceTypeCategoryParams>();
  const [toggleVisibility, setToggleVisibility] = useState(null);
  const [editing, setEditing] = useState(null);
  const [removing, setRemoving] = useState(null);
  const addingDisclosure = useDisclosure();

  const { data, loading } = useQuery(SERVICE_TYPE, {
    variables: {
      serviceTypeId: params.id,
    },
  });

  const serviceType = data && data.serviceType;
  const serviceTypeCategories = get(data, "serviceType.categories", []);

  return (
    <Box padding={5}>
      <ManageServiceTypeCategory
        serviceType={serviceType}
        category={editing}
        isOpen={!!editing || addingDisclosure.isOpen}
        onClose={() => {
          setEditing(null);
          addingDisclosure.onClose();
        }}
      />

      <DeleteDialog
        isOpen={!!removing}
        onClose={() => setRemoving(null)}
        category={removing}
      />

      <PageHeader
        title={`${
          serviceType ? serviceType.displayName : "Loading"
        } - Categories`}
        hasBackButton
      />

      <ToggleVisibilityDialog
        isOpen={!!toggleVisibility}
        item={toggleVisibility}
        onClose={() => setToggleVisibility(null)}
      />

      <Box>
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            flex={1}
            padding={5}
          >
            <Spinner color="brandYellow.500" size="md" />
          </Flex>
        )}
        <Table>
          <TableBody>
            {serviceTypeCategories.map((category) => (
              <TableRow key={category.id}>
                <TableCell
                  onClick={() =>
                    history.push(`${serviceType.id}/${category.id}`)
                  }
                >
                  <Heading
                    fontSize={["md", "xl"]}
                    color={category.disabledAt ? "midGrey" : "black"}
                  >
                    {category.displayName}
                  </Heading>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  marginLeft="auto"
                  flexBasis={150}
                  flexGrow={0}
                  hideOnSmall
                >
                  <Text color="darkGrey" fontWeight="500" fontSize={14}>
                    {pluralize("service", category.services.length, true)}
                  </Text>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  flexBasis={200}
                  flexGrow={0}
                  hideOnSmall
                >
                  <Text color="midGrey" fontSize={14}>
                    Last Updated: {formatDate(category.updatedAt) || "N/A"}
                  </Text>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  flexShrink={0}
                  flexGrow={0}
                  flexBasis={50}
                >
                  <ItemMenu>
                    <MenuList placement="bottom-end">
                      <MenuItem onClick={() => setEditing(category)}>
                        Edit category
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        color="red.500"
                        onClick={() => setRemoving(category)}
                      >
                        Delete category
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        color="red.500"
                        onClick={() => setToggleVisibility(category)}
                      >
                        {category.disabledAt
                          ? "Enable services"
                          : "Disable services"}
                      </MenuItem>
                    </MenuList>
                  </ItemMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button
          variant="primary"
          position="absolute"
          bottom={5}
          right={5}
          onClick={addingDisclosure.onOpen}
        >
          Add new category
        </Button>
      </Box>
    </Box>
  );
};

export default ServiceTypeCategories;
