import React from "react";
import {
  Box,
  Flex,
  useDisclosure,
  MenuList,
  MenuItem,
  Link,
} from "@chakra-ui/core";
import { Body } from "../Typography";
import InformationRow from "./InformationRow";
import { Section, Header } from "./Section";
import ItemMenu from "../ItemMenu";
import { summarizeAddress } from "../../lib/address";
import EditInformation from "./EditInformation";
import { formatDateOfBirth } from "../../lib/customer";
import { Visit, VisitCustomerInformation } from "../../types";

type mode = "minor" | "parent";

type Props = {
  visit: Visit;
  mode?: mode;
  customerInformation: VisitCustomerInformation;
  canEdit: boolean;
};

const BasicInfo: React.FC<Props> = ({
  visit,
  mode = "parent",
  customerInformation,
  canEdit,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const idImageUrls = customerInformation.idImageUrls || [];
  const isMinorVisit = visit.customer?.isMinor;

  return (
    <Section>
      {canEdit && (
        <EditInformation
          visitId={visit.id}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          customerInformation={customerInformation}
          mode={isMinorVisit && mode === "parent" ? "parent" : "customer"}
        />
      )}

      <Header
        title={
          isMinorVisit
            ? mode === "minor"
              ? "Minor Basic Info"
              : "Guardian Basic Info"
            : "Basic Info"
        }
      >
        <Flex alignItems="flex-end">
          {canEdit && (
            <ItemMenu>
              <MenuList>
                <MenuItem onClick={onOpen}>Edit</MenuItem>
              </MenuList>
            </ItemMenu>
          )}
        </Flex>
      </Header>
      <Box>
        <Flex flexDirection={["column", "row"]}>
          <InformationRow label="Customer Name">
            <Body>
              {customerInformation.firstName &&
                `${customerInformation.firstName} ${customerInformation.lastName}`}

              {!customerInformation.firstName && "N/A"}
            </Body>
          </InformationRow>
          <InformationRow label="Date of Birth">
            <Body>
              {formatDateOfBirth(customerInformation.dateOfBirth) || "N/A"}
            </Body>
          </InformationRow>
        </Flex>

        <Flex flexDirection={["column", "row"]}>
          <InformationRow label="Email address">
            <Body>{customerInformation.emailAddress || "N/A"}</Body>
          </InformationRow>
          <InformationRow label="Photo ID">
            {idImageUrls.length > 0 ? (
              idImageUrls.map((imageUrl, idx) => (
                <Link
                  key={idx}
                  href={imageUrl}
                  target="_blank"
                  color="statusBlue"
                >
                  Upload {idx + 1}
                </Link>
              ))
            ) : (
              <Body color="statusRed">No photo ID uploaded</Body>
            )}
          </InformationRow>
        </Flex>

        <Flex flexDirection={["column", "row"]}>
          {customerInformation.address && (
            <InformationRow label="Address">
              <Body style={{ width: "70%" }}>
                {summarizeAddress(customerInformation.address)}
              </Body>
            </InformationRow>
          )}
          {customerInformation.phoneNumber && (
            <InformationRow label="Phone Number">
              <Body>{customerInformation.phoneNumber.formatted}</Body>
            </InformationRow>
          )}
        </Flex>
      </Box>
    </Section>
  );
};

export default BasicInfo;
