import React from "react";
import {
  Box,
  Heading,
  InputGroup,
  InputRightElement,
  Button,
  Input,
  useClipboard,
  useTheme,
} from "@chakra-ui/core";
import QRCode from "react-qr-code";
import { Body } from "../../../../../components/Typography";

const Links = ({ store }) => {
  const newVisitURL = store.newVisitUrl;
  const newVisitUnscheduledUrl = store.newVisitUnscheduledUrl;
  const {
    onCopy: onCopyNewVisitURL,
    hasCopied: hasCopiedNewVisitURL,
  } = useClipboard(newVisitURL);
  const {
    onCopy: onCopyNewVisitSideQueueURL,
    hasCopied: hasCopiedNewVisitSideQueueURL,
  } = useClipboard(newVisitUnscheduledUrl);
  const theme = useTheme();

  return (
    <Box paddingY={5} maxW={["100%", "35rem"]}>
      <Box>
        <Box mb={5}>
          <Heading mb={3} size="md">
            In Store New Visit Link
          </Heading>
          <Body mb={5}>
            This link can be used by customers to start a new visit within the
            Bodyart app for your store.
          </Body>

          <Body>
            Customers using this link will appear in the main queue once they
            complete their consent forms.
          </Body>
        </Box>

        <Box mb={5}>
          <Heading size="sm">QR Code</Heading>

          <Box mt={5}>
            <QRCode
              value={newVisitURL}
              size={200}
              fgColor={theme.colors.black}
            />
          </Box>
        </Box>

        <Box mb={20}>
          <Heading size="sm">New Visit Link</Heading>

          <InputGroup mt={5}>
            <Input value={newVisitURL} isReadOnly pr="4.5rem" />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={onCopyNewVisitURL}>
                {hasCopiedNewVisitURL ? "Copied!" : "Copy"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
      </Box>

      <Box>
        <Box mb={5}>
          <Heading mb={3} size="md">
            Not In Store New Visit Link
          </Heading>
          <Body mb={5}>
            This link can be used by customers to start a new visit within the
            Bodyart app for your store.
          </Body>

          <Body>
            Customers using this link will appear in the side queue once they
            complete their consent forms.
          </Body>
        </Box>

        <Box>
          <Heading size="sm">New Visit Link (Not In Store)</Heading>
          <InputGroup mt={5}>
            <Input value={newVisitUnscheduledUrl} isReadOnly pr="4.5rem" />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="sm"
                onClick={onCopyNewVisitSideQueueURL}
              >
                {hasCopiedNewVisitSideQueueURL ? "Copied!" : "Copy"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default Links;
