import React from "react";
import {
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Flex,
  Box,
} from "@chakra-ui/core";
import PageHeader from "../PageHeader";
import Button from "../Button";
import FormField from "../FormField";
import { Address } from "../../types";
import { Formik } from "formik";
import { prepareForSubmission } from "../../lib/form";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_INFORMATION } from "./gql";
import { Header, Section } from "./Section";
import InputModal from "../InputModal";

export type FormInputs = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  address: Address;
  phoneNumber: string;
  dateOfBirth: string;
};

const EditInformation = ({
  isOpen,
  onClose,
  onOpen,
  mode = "customer",
  visitId,
  customerInformation,
}) => {
  const toast = useToast();
  const [updateInformation, { loading }] = useMutation(
    UPDATE_CUSTOMER_INFORMATION,
    {
      onCompleted: (data) => {
        if (!data.result.errors) {
          toast({
            title: "Basic Info Updated",
            description: "We've updated the information for the visit.",
            status: "success",
            position: "bottom-left",
          });
          onClose();
        }
      },
    },
  );

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          ...customerInformation,
          phoneNumber: customerInformation.phoneNumber?.raw,
        }}
        onSubmit={(values) => {
          // When we're dealing with editing the parent information here we need
          // to only send that over so that we don't trample data
          const input =
            mode === "customer"
              ? {
                  visitId,
                  customerInformation: prepareForSubmission(values),
                }
              : {
                  visitId,
                  parentCustomerInformation: prepareForSubmission(values),
                };

          return updateInformation({ variables: { input } });
        }}
      >
        {(props) => (
          <Flex
            flexDirection="column"
            position="relative"
            overflow="hidden"
            height={"100%"}
            padding={6}
          >
            <PageHeader
              title="Edit Basic Info"
              renderRightControls={() => (
                <ModalCloseButton size="lg" right={0} top={-2} />
              )}
            />
            <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
              <Box as="form" onSubmit={props.handleSubmit}>
                <Box width={["100%", "50%"]}>
                  <Section>
                    <Header title="Contact Info" />

                    <FormField name="firstName" label="First name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="lastName" label="Last name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="emailAddress" label="Email Address">
                      {(props) => (
                        <Input {...props} type="email" variant="filled" />
                      )}
                    </FormField>
                    <FormField name="phoneNumber" label="Phone Number">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="dateOfBirth" label="Date of Birth">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                  </Section>
                </Box>
                <Section>
                  <Header title="Address"></Header>
                  <FormField
                    name="address.addressLineOne"
                    label="Address Line 1"
                  >
                    {(props) => <Input {...props} variant="filled" />}
                  </FormField>
                  <FormField
                    name="address.addressLineTwo"
                    label="Address Line 2"
                  >
                    {(props) => <Input {...props} variant="filled" />}
                  </FormField>
                  <FormField name="address.city" label="City">
                    {(props) => <Input {...props} variant="filled" />}
                  </FormField>

                  <Flex>
                    <FormField name="address.state" label="State">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <Box width={4} />
                    <FormField name="address.zipCode" label="Zipcode">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                  </Flex>
                </Section>
              </Box>
            </ModalBody>
            <Flex
              justifyContent="flex-end"
              paddingTop={5}
              borderTop="1px solid"
              borderTopColor="black"
            >
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={props.handleSubmit}
                isLoading={loading}
              >
                Save Changes
              </Button>
            </Flex>
          </Flex>
        )}
      </Formik>
    </InputModal>
  );
};

export default EditInformation;
