import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { gql, useApolloClient } from "@apollo/client";
import { Store } from "../types";
import { RootState } from "../state";
import {
  storeInformation as storeInformationFragment,
  storeViewerContext as storeViewerContextFragment,
} from "../fragments";

const storeFragment = gql`
  fragment fetchedStore on Store {
    ...storeInformation
    ...storeViewerContext
  }
  ${storeInformationFragment}
  ${storeViewerContextFragment}
`;

export default function useCurrentStore() {
  const [store, setStore] = useState<Store | null>(null);
  const storeId = useSelector((state: RootState) => state.context.storeId);
  const client = useApolloClient();

  useEffect(() => {
    const store = client.readFragment({
      fragment: storeFragment,
      fragmentName: "fetchedStore",
      id: `Store:${storeId}`,
    });

    store && setStore(store);
  }, [client, storeId, setStore]);

  return store;
}
