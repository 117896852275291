import { ProcedureInventorySelection } from "../types";

export function summarizeInventorySelection(
  inventorySelection: ProcedureInventorySelection,
) {
  const properties = inventorySelection.selectedValues.map((sv) => sv.value);

  return `${inventorySelection.inventoryItem.name} ${
    properties.length > 0 && `(${properties.join(", ")})`
  }`;
}
