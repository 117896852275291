import { useQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
  List,
  ListItem,
  Link,
  Text,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";
import React from "react";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import useDocumentTitle from "@rehooks/document-title";
import Button from "../../../../components/Button";
import PageHeader from "../../../../components/PageHeader";
import { Label, Body } from "../../../../components/Typography";
import { summarizeAddress } from "../../../../lib/address";
import { formatDateOfBirth } from "../../../../lib/customer";
import EditCustomer from "./EditCustomer";
import { CUSTOMER } from "./gql";
import Visits from "./Visits";
import ItemMenu from "../../../../components/ItemMenu";

const Cell = ({ label, children }) => (
  <Box mb={4} flex={1}>
    <Label>{label}</Label>
    <Box mt={1}>{children}</Box>
  </Box>
);

const Customer = ({ store, account }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: contactId } = useParams();
  const history = useHistory();
  const { data, loading } = useQuery(CUSTOMER, {
    variables: {
      customerId: contactId,
    },
  });

  const contact = data?.contact;
  const customer = contact?.customer;
  const minors = contact?.minors || [];
  const parent = contact?.parent;
  const isMinor = customer?.isMinor;

  useDocumentTitle(`${customer?.name} - ${store.name} - Bodyart`);

  const onBackClick = () =>
    history.push(`/${account.slug}/stores/${store.slug}/customers`);

  return (
    <Box flex={1} padding={5} backgroundColor="lightGrey">
      {loading && <Spinner color="brandYellow.500" />}

      {!loading && customer && (
        <>
          <EditCustomer
            contactId={contactId}
            customer={customer}
            onClose={onClose}
            isOpen={isOpen}
          />
          <PageHeader
            title={customer?.name}
            hasBackButton
            onBackClick={onBackClick}
            renderRightControls={() => (
              <>
                <Box display={["none", "block"]}>
                  <Button variant="default" leftIcon="edit" onClick={onOpen}>
                    Edit Customer
                  </Button>
                </Box>
                <Flex display={["flex", "none"]} alignItems="center">
                  <ItemMenu>
                    <MenuList>
                      <MenuItem onClick={onOpen}>Edit Customer</MenuItem>
                    </MenuList>
                  </ItemMenu>
                </Flex>
              </>
            )}
          />
          <Flex
            paddingY={5}
            flexDirection={["column", "row"]}
            maxWidth="1024px"
            marginX="auto"
          >
            <Box flex={1} mb={[5, 0]}>
              <Heading size="md" mb={5}>
                Basic Info
              </Heading>

              <Cell label="Customer Name">
                <Body>{customer?.name}</Body>
              </Cell>

              <Cell label="Email Address">
                <Body>{customer?.emailAddress || "N/A"}</Body>
              </Cell>

              <Cell label="Date of Birth">
                <Body>{formatDateOfBirth(customer?.dateOfBirth)}</Body>
              </Cell>

              <Cell label="Phone Number">
                <Body>{customer?.phoneNumber?.formatted || "N/A"}</Body>
              </Cell>

              {customer?.address && (
                <Cell label="Address">
                  <Body>{summarizeAddress(customer?.address)}</Body>
                </Cell>
              )}

              {!isMinor && minors.length > 0 && (
                <>
                  <Heading size="md" mb={5}>
                    Minors
                  </Heading>

                  <List>
                    {minors.map((minor) => (
                      <ListItem mb={3}>
                        <Link
                          color="statusBlue"
                          as={RouterLink as any}
                          {...{ to: minor.id }}
                        >
                          {minor.customer.name}
                        </Link>{" "}
                        <Text color="midGrey">
                          ({formatDateOfBirth(minor.customer.dateOfBirth)})
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}

              {isMinor && parent && (
                <>
                  <Heading size="md" mb={5}>
                    Parent
                  </Heading>

                  <Link
                    color="statusBlue"
                    as={RouterLink as any}
                    {...{ to: parent.id }}
                  >
                    {parent.customer.name}
                  </Link>
                </>
              )}
            </Box>
            <Box flex={1} ml={[0, 5]} pl={[0, 5]}>
              <Heading size="md" mb={5}>
                Consent Forms
              </Heading>
              <Visits contactId={contactId} />
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Customer;
