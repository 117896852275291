import React from "react";
import { ModalBody, Flex, Box, Heading, Icon } from "@chakra-ui/core";
import Button from "../Button";
import { Body } from "../Typography";

const Finished = ({ onClose }) => {
  return (
    <Flex
      flexDirection="column"
      position="relative"
      overflow="hidden"
      padding={6}
      flex={1}
    >
      <ModalBody
        margin={0}
        padding={0}
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        flex={1}
        display="flex"
      >
        <Box textAlign="center">
          <Icon name="tick" width={"52px"} height={"40px"} mb={3} />

          <Heading size="lg" textAlign="center" mb={3}>
            Consent Form Approved
          </Heading>

          <Body>You are now able to begin the procedure.</Body>
        </Box>
      </ModalBody>
      <Flex
        justifyContent="flex-end"
        paddingTop={5}
        borderTop="1px solid"
        borderTopColor="black"
      >
        <Button variant="primary" type="submit" onClick={onClose}>
          Done
        </Button>
      </Flex>
    </Flex>
  );
};

export default Finished;
