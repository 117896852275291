import React from "react";
import {
  Box,
  Heading,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  IconButton,
} from "@chakra-ui/core";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useQueryParams, StringParam } from "use-query-params";
import { get, groupBy, includes } from "lodash";
import { format, endOfDay, startOfDay } from "date-fns";
import useDocumentTitle from "@rehooks/document-title";

import { VisitEdge, VisitStatus } from "../../../../types";
import { QUERY_VISITS_FOR_STORE } from "./gql";

import AddVisitToQueue from "./AddVisitToQueue";

import {
  VisitCard,
  VisitSheet,
  PageHeader,
  Button,
  SendNewConsentFlow,
} from "../../../../components";

const ActiveStatuses = [
  VisitStatus.Reviewing,
  VisitStatus.ConsentRequested,
  VisitStatus.Approved,
];

const Visits = ({ store }) => {
  useDocumentTitle(`Visit Queue - ${store.name} - Bodyart`);

  const toast = useToast();
  const now = new Date();
  const [query, setQuery] = useQueryParams({
    visitId: StringParam,
  });
  const { visitId } = query;
  const sendConsentDisclosure = useDisclosure();
  const addToQueueDisclosure = useDisclosure();

  const { data, loading, refetch, networkStatus } = useQuery(
    QUERY_VISITS_FOR_STORE,
    {
      pollInterval: parseInt(
        process.env.REACT_APP_POLL_INTERVAL || "10000",
        10,
      ),
      notifyOnNetworkStatusChange: true,
      variables: {
        storeId: store.id,
        filters: {
          scheduledFor: {
            from: startOfDay(now),
            to: endOfDay(now),
          },
        },
        first: 999,
      },
    },
  );

  const { data: scheduledVisitsData } = useQuery(QUERY_VISITS_FOR_STORE, {
    pollInterval: parseInt(process.env.REACT_APP_POLL_INTERVAL || "10000", 10),
    notifyOnNetworkStatusChange: true,
    variables: {
      storeId: store.id,
      filters: { status: VisitStatus.Submitted },
      first: 999,
    },
  });

  const visitEdges = get(data, "store.visits.edges", []);
  const totalVisits = visitEdges.length;

  const scheduledVisitsEdges = get(
    scheduledVisitsData,
    "store.visits.edges",
    [],
  );

  const groupedVisitEdges = groupBy(visitEdges, (visitEdge) =>
    includes(ActiveStatuses, visitEdge.node.status) ? "active" : "completed",
  );

  const { completed: completedEdges, active: activeEdges } = groupedVisitEdges;

  return (
    <Box padding={5} backgroundColor="lightGrey" minHeight="100%">
      <PageHeader
        title="Store Queue"
        renderSubHeader={() => (
          <Text color="darkGrey" fontSize="md" fontWeight="bold" marginLeft="2">
            ({format(now, "MM/dd/yyyy")})
          </Text>
        )}
        renderRightControls={() => (
          <Box display={"flex"}>
            <IconButton
              icon="repeat"
              aria-label="Refresh"
              variant="ghost"
              size="md"
              fontSize={20}
              onClick={() => refetch()}
              isLoading={networkStatus === NetworkStatus.refetch}
              marginRight={2}
            />
            <Box display={["block", "block"]} position="relative">
              <Button variant="default" onClick={addToQueueDisclosure.onOpen}>
                Side queue
              </Button>
              {scheduledVisitsEdges.length > 0 && (
                <Box
                  backgroundColor={"brandYellow.500"}
                  borderRadius="100%"
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  top="-0.75rem"
                  right="-0.75rem"
                  width="1.75rem"
                  height="1.75rem"
                >
                  <Text color="darkGrey" fontWeight="bold">
                    {scheduledVisitsEdges.length}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        )}
      />

      <Box paddingY={5} maxWidth={1024} marginX="auto" marginBottom={5}>
        {networkStatus === NetworkStatus.loading && (
          <Spinner color="brandYellow.500" size="sm" />
        )}

        {networkStatus !== NetworkStatus.loading && totalVisits === 0 && (
          <Text>Nothing to process in your queue right now.</Text>
        )}

        {!loading && totalVisits > 0 && (
          <Box mb={10}>
            <Heading
              size="xs"
              fontWeight="medium"
              textTransform="uppercase"
              color="darkGrey"
              mb={5}
              borderBottom="1px solid"
              borderBottomColor="lightGrey"
            >
              Active ({activeEdges?.length || 0})
            </Heading>

            {activeEdges?.map((edge: VisitEdge) => (
              <VisitCard
                key={edge.node.id}
                visit={edge.node}
                onClick={() => {
                  setQuery({ visitId: edge.node.id }, "push");
                }}
              />
            ))}

            {!activeEdges && (
              <Text>Nothing to process in your queue right now.</Text>
            )}
          </Box>
        )}

        {!loading && completedEdges && (
          <Box>
            <Heading
              size="xs"
              fontWeight="medium"
              textTransform="uppercase"
              color="darkGrey"
              mb={5}
            >
              Completed ({completedEdges.length})
            </Heading>

            {completedEdges.map((edge: VisitEdge) => (
              <VisitCard
                key={edge.node.id}
                visit={edge.node}
                onClick={() => {
                  setQuery({ visitId: edge.node.id }, "push");
                }}
              />
            ))}
          </Box>
        )}
      </Box>

      <AddVisitToQueue
        store={store}
        isOpen={addToQueueDisclosure.isOpen}
        onClose={addToQueueDisclosure.onClose}
        onVisitAdded={(visit) => {
          toast({
            title: "Visit has been added to the queue",
            position: "bottom-left",
            status: "success",
          });

          refetch();
          setQuery({ visitId: visit.id }, "push");
        }}
      />

      <VisitSheet
        visitId={visitId}
        isOpen={!!visitId}
        onClose={() => setQuery({ visitId: undefined }, "push")}
      />

      <SendNewConsentFlow
        isOpen={sendConsentDisclosure.isOpen}
        onClose={sendConsentDisclosure.onClose}
        store={store}
        onVisitCreated={(visit) => {
          toast({
            title: "Visit has been created successfully",
            position: "bottom-left",
            status: "success",
          });

          setQuery({ visitId: visit.id }, "push");
        }}
      />

      <Button
        variant="primary"
        position="absolute"
        bottom={5}
        right={5}
        onClick={sendConsentDisclosure.onOpen}
        disabled={!!store.disabledAt}
      >
        Send new consent
      </Button>
    </Box>
  );
};

export default Visits;
