import { gql } from "@apollo/client";
import {
  customerInformation,
  address,
  pageInfo,
  visitListingInformation,
} from "../../../../fragments";

export const CUSTOMER = gql`
  query CustomersForStore($customerId: ID) {
    contact: node(id: $customerId) {
      ... on StoreContact {
        id
        customer {
          ...customerInformation
          address {
            ...address
          }
        }
        minors {
          id
          customer {
            name
            dateOfBirth
          }
        }
        parent {
          id
          customer {
            name
          }
        }
        lastVisitAt
        updatedAt
        insertedAt
      }
    }
  }
  ${customerInformation}
  ${address}
`;

export const VISITS_FOR_CUSTOMER = gql`
  query VisitsForCustomer($customerId: ID!, $first: Integer!) {
    contact: node(id: $customerId) {
      ... on StoreContact {
        id
        visits(first: $first) {
          edges {
            node {
              ...visitListingInformation
            }
          }
          pageInfo {
            ...pageInfo
          }
        }
      }
    }
  }
  ${pageInfo}
  ${visitListingInformation}
`;

export const UPDATE_CUSTOMER_INFORMATION = gql`
  mutation UpdateStoreContact($input: UpdateContactProfileInput!) {
    result: updateContactProfile(input: $input) {
      contact {
        id
        customer {
          ...customerInformation
          address {
            ...address
          }
        }
        updatedAt
        insertedAt
      }
      errors {
        field
        message
      }
    }
  }
  ${customerInformation}
  ${address}
`;
