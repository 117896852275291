import { useQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  MenuList,
  MenuItem,
} from "@chakra-ui/core";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "@rehooks/document-title";
import React, { useState } from "react";
import { get } from "lodash";
import { SERVICE_TYPES } from "./gql";
import PageHeader from "../../../../components/PageHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/Table";
import ItemMenu from "../../../../components/ItemMenu";
import { formatDate } from "../../../../lib/dates";
import ToggleVisibilityDialog from "./ToggleVisibilityDialog";

const ServiceTypes = ({ store }) => {
  useDocumentTitle(`Services - ${store.name} - Bodyart`);

  const history = useHistory();
  const [toggleVisibility, setToggleVisibility] = useState(null);

  const { data, loading } = useQuery(SERVICE_TYPES, {
    variables: {
      storeId: store.id,
    },
  });

  const serviceTypes = get(data, "store.serviceTypes", []);

  return (
    <Box padding={5}>
      <PageHeader title="Service Types" />

      <ToggleVisibilityDialog
        isOpen={!!toggleVisibility}
        item={toggleVisibility}
        onClose={() => setToggleVisibility(null)}
      />

      <Box>
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            flex={1}
            padding={5}
          >
            <Spinner color="brandYellow.500" size="md" />
          </Flex>
        )}
        <Table>
          <TableBody>
            {serviceTypes.map((serviceType) => (
              <TableRow key={serviceType.id}>
                <TableCell
                  onClick={() => history.push(`services/${serviceType.id}`)}
                >
                  <Heading
                    fontSize={["md", "xl"]}
                    color={serviceType.disabledAt ? "midGrey" : "black"}
                  >
                    {serviceType.displayName}
                  </Heading>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  marginLeft="auto"
                  flexBasis={150}
                  flexGrow={0}
                  hideOnSmall
                >
                  <Text color="darkGrey" fontWeight="500" fontSize={14}>
                    {pluralize("category", serviceType.categories.length, true)}
                  </Text>
                </TableCell>
                <TableCell
                  flexBasis={200}
                  flexGrow={0}
                  justifyContent="flex-end"
                  hideOnSmall
                >
                  <Text color="midGrey" fontSize={14}>
                    Last Updated: {formatDate(serviceType.updatedAt) || "N/A"}
                  </Text>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  flexShrink={0}
                  flexGrow={0}
                  flexBasis={50}
                >
                  <ItemMenu>
                    <MenuList placement="bottom-end">
                      <MenuItem
                        onClick={() => setToggleVisibility(serviceType)}
                      >
                        {serviceType.disabledAt
                          ? "Enable service"
                          : "Disable service"}
                      </MenuItem>
                    </MenuList>
                  </ItemMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default ServiceTypes;
