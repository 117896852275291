import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/core";
import Button from "../../../../../components/Button";
import { useMutation } from "@apollo/client";
import { RESET_FOR_SERVICE_TYPE } from "./gql";
import { Body } from "../../../../../components/Typography";

const ResetAftercareDialog = ({ serviceType, storeId, isOpen, onClose }) => {
  const toast = useToast();
  const [resetAftercare, { loading }] = useMutation(RESET_FOR_SERVICE_TYPE, {
    variables: { input: { serviceType, storeId } },
    refetchQueries: ["ConsentQuestionsForStore"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast({
        title: "Aftercare has been reset",
        status: "success",
        position: "bottom-left",
      });
      onClose();
    },
  });

  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay zIndex={1403} />
      <AlertDialogContent borderRadius={20} zIndex={1404}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Reset Aftercare
        </AlertDialogHeader>

        <AlertDialogBody>
          <Body>
            Are you sure you’d like to reset the aftercare for all services
            under this service type?
          </Body>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button variant="ghost" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="dangerFilled"
            onClick={() => resetAftercare()}
            isLoading={loading}
            ml={3}
          >
            Reset
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ResetAftercareDialog;
