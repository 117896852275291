import React from "react";
import { Input, Textarea } from "@chakra-ui/core";
import SelectFromData from "../SelectFromData";
import {
  ServiceInformationField,
  ServiceInformationTextFieldType,
} from "../../types";

const InformationField = (field: ServiceInformationField) => {
  switch (field.__typename) {
    case "ServiceInformationTextField":
      return field.type === ServiceInformationTextFieldType.Textarea
        ? (props) => <Textarea {...props} variant="filled" />
        : (props) => <Input {...props} variant="filled" />;
    case "ServiceInformationOptionField":
      return SelectFromData({
        placeholder: "Select an option",
        items: field.options,
        valueSelector: (i) => i,
        nameSelector: (i) => i,
      });
    case "ServiceInformationSelectField":
      return SelectFromData({
        placeholder: "Select an item",
        items: field.options,
        valueSelector: (i) => i,
        nameSelector: (i) => i,
      });
  }
};

export default InformationField;
