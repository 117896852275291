import React from "react";

export default function useUppy(factory: () => any) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uppy = React.useMemo(factory, []);

  React.useEffect(() => {
    return () => uppy.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return uppy;
}
