import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createHttpLink } from "apollo-link-http";
import { isArray } from "lodash";
import { setContext } from "apollo-link-context";
import * as Sentry from "@sentry/react";
import store from "./state";
import typePolicies from "./graphql/typePolicies";

const apiHost = process.env.REACT_APP_API_HOST || "http://localhost:4000";

const httpLink = createHttpLink({
  uri: apiHost + "/store/graphql",
});

const linkOnError = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors && isArray(graphQLErrors)) {
    graphQLErrors.map(({ message, locations, path }) =>
      Sentry.captureMessage(message),
    );
  }

  if (networkError) {
    Sentry.captureException(networkError);
  }
});

const authLink = setContext((_: any, { headers }: { headers: Object }) => {
  const state = store.getState();
  const token = state.auth.token;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const authedHttpLink = authLink.concat(httpLink);

const client = new ApolloClient({
  link: from([linkOnError, authedHttpLink as any]),
  cache: new InMemoryCache({ typePolicies }),
});

export default client;
