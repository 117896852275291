import React from "react";
import { Redirect } from "react-router-dom";
import { Spinner } from "@chakra-ui/core";
import { useLocalStorage } from "@rehooks/local-storage";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useQueryAccountFromSlug from "../../../hooks/useQueryAccountFromSlug";

const StoreRedirect = ({ accountSlug }) => {
  const { account, loading } = useQueryAccountFromSlug(accountSlug);

  if (loading) {
    return <Spinner />;
  }

  if (account.stores.length === 0) {
    return <Redirect to={`/${account.slug}/stores/not-found`} />;
  }

  return <Redirect to={`/${account.slug}/stores/${account.stores[0].slug}`} />;
};

const ActiveStoreRedirect = () => {
  const currentUser = useCurrentUser();
  const [lastAccountSlug] = useLocalStorage("@bodyart/last-account");
  const [lastStoreSlug] = useLocalStorage("@bodyart/last-store");

  if (lastAccountSlug && lastStoreSlug) {
    return <Redirect to={`/${lastAccountSlug}/stores/${lastStoreSlug}`} />;
  }

  if (!currentUser) return <Spinner />;

  if (currentUser.isStaff) {
    return <Redirect to="/staff" />;
  }

  const account = currentUser.accounts[0];

  if (!account) {
    return <Redirect to="/no-account" />;
  }

  return <StoreRedirect accountSlug={account.slug} />;
};

export default ActiveStoreRedirect;
