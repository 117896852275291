import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Spinner,
  Link,
  useToast,
  useDisclosure,
} from "@chakra-ui/core";
import React from "react";
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import useDocumentTitle from "@rehooks/document-title";
import Button from "../../components/Button";
import { Body } from "../../components/Typography";
import { summarizeAddress } from "../../lib/address";
import { CURRENT_USER } from "../RequireAuth";
import { useSelector } from "react-redux";
import { RootState } from "../../state";
import { INVITE, REALIZE_INVITE } from "./gql";
import SetupAccount from "./SetupAccount";

const StoreInvite = () => {
  useDocumentTitle("Store Invite - Bodyart");

  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const userToken = useSelector((state: RootState) => state.auth.token);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { token } = useParams();

  const { data: currentUserData } = useQuery(CURRENT_USER, {
    skip: !userToken,
  });

  const { data, loading } = useQuery(INVITE, {
    variables: { token },
  });

  const [realizeInvite, { loading: isAccepting }] = useMutation(
    REALIZE_INVITE,
    {
      onCompleted: () => {
        toast({
          title: "Successfully joined store",
          position: "bottom-left",
          status: "success",
        });

        history.push("/");
      },
    },
  );

  if (loading) {
    return <Spinner color="brandYellow.500" />;
  }

  const invite = data?.employeeInvite;

  if (!invite) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="black"
        height="100%"
      >
        <Heading color="white" fontSize={["md", "lg"]} mt={3} mb={3}>
          Sorry, this invite has expired
        </Heading>

        <Body
          color="lightGrey"
          mx="auto"
          mb={5}
          maxW="240px"
          textAlign="center"
        >
          Please contact your admin if you need assistance
        </Body>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="black"
      height="100%"
    >
      <SetupAccount
        emailAddress={invite.emailAddress}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
      <Box maxW={400} textAlign="center">
        <Box
          textAlign="center"
          borderBottom="1px solid"
          borderColor="darkestGrey"
          pb={3}
          mb={3}
        >
          <Avatar
            src={invite.store.profileImageUrl}
            name={invite.store.name}
            size="md"
          />
          <Heading color="white" fontSize={["md", "lg"]} mt={3}>
            {invite.store.name}
          </Heading>
          <Body color="darkGrey">{summarizeAddress(invite.store.address)}</Body>
        </Box>

        <Body
          color="lightGrey"
          mx="auto"
          mb={5}
          maxW="240px"
          textAlign="center"
        >
          You have been invited to join the shops BodyArt account as an
          employee.
        </Body>

        {currentUserData && (
          <>
            <Button
              variant="primary"
              isLoading={isAccepting}
              onClick={() => {
                const input = { inviteId: invite.id };
                realizeInvite({ variables: { input } });
              }}
            >
              Join Store
            </Button>
            <Body color="darkGrey" mt={5}>
              You're currently logged in as{" "}
              {currentUserData.viewer.emailAddress}
            </Body>
          </>
        )}

        {!currentUserData && (
          <>
            <Button variant="primary" onClick={onOpen}>
              Setup Account
            </Button>

            <Body color="white" mt={5}>
              Existing account?{" "}
              <Link
                color="brandYellow.500"
                as={RouterLink as any}
                {...{
                  to: {
                    pathname: "/login",
                    state: { next: location.pathname },
                  },
                }}
              >
                Login now
              </Link>
            </Body>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default StoreInvite;
