import React from "react";
import {
  ModalBody,
  ModalCloseButton,
  Input,
  useToast,
  Flex,
  Box,
} from "@chakra-ui/core";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import FormField from "../../../../components/FormField";
import { Address } from "../../../../types";
import { UPDATE_CUSTOMER_INFORMATION } from "./gql";
import { Section, Header } from "../../../../components/VisitSheet/Section";
import { phoneRegExp } from "../../../../lib/form";
import InputModal from "../../../../components/InputModal";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Firstname is required"),
  lastName: Yup.string().required("Lastname is required"),
  emailAddress: Yup.string().email().nullable(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(),
  address: Yup.object().shape({
    addressLineOne: Yup.string().required(),
    addressLineTwo: Yup.string().nullable(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string().required(),
  }),
});

export type FormInputs = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  address: Address;
  phoneNumber: string;
  dateOfBirth: string;
};

const EditCustomer = ({ isOpen, onClose, customer, contactId }) => {
  const toast = useToast();
  const [updateInformation, { loading }] = useMutation(
    UPDATE_CUSTOMER_INFORMATION,
    {
      onCompleted: (data) => {
        if (!data.result.errors) {
          toast({
            title: "Customer updated",
            status: "success",
            position: "bottom-left",
          });
          onClose();
        }
      },
    },
  );

  const initialValues = {
    contactId,
    firstName: customer.firstName,
    lastName: customer.lastName,
    emailAddress: customer.emailAddress,
    phoneNumber: customer.phoneNumber?.raw,
    dateOfBirth: customer.dateOfBirth,
    address: {
      addressLineOne: customer.address?.addressLineOne,
      addressLineTwo: customer.address?.addressLineTwo,
      city: customer.address?.city,
      state: customer.address?.state,
      zipCode: customer.address?.zipCode,
      country: customer.address?.country,
    },
  };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (input, actions) => {
          const { data } = await updateInformation({ variables: { input } });

          if (data.result.errors) {
            data.result.errors.forEach((err) =>
              actions.setFieldError(err.field, err.message),
            );
          }
        }}
      >
        {(props) => (
          <Flex
            flexDirection="column"
            position="relative"
            overflow="hidden"
            height={"100%"}
            padding={6}
          >
            <PageHeader
              title="Edit Customer"
              renderRightControls={() => (
                <ModalCloseButton size="lg" right={0} top={-2} />
              )}
            />
            <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
              <Box as="form" onSubmit={props.handleSubmit}>
                <Box width={["100%", "50%"]}>
                  <Section>
                    <Header title="Contact Info" />

                    <FormField name="firstName" label="First name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="lastName" label="Last name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="emailAddress" label="Email Address">
                      {(props) => (
                        <Input
                          {...props}
                          type="email"
                          variant="filled"
                          isReadOnly
                        />
                      )}
                    </FormField>
                    <FormField name="phoneNumber" label="Phone Number">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="dateOfBirth" label="Date of Birth">
                      {(props) => (
                        <Input {...props} variant="filled" isReadOnly />
                      )}
                    </FormField>
                  </Section>
                </Box>
                <Box width={["100%", "50%"]}>
                  <Section>
                    <Header title="Address"></Header>
                    <FormField
                      name="address.addressLineOne"
                      label="Address Line 1"
                    >
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField
                      name="address.addressLineTwo"
                      label="Address Line 2"
                    >
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="address.city" label="City">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>

                    <Flex>
                      <FormField name="address.state" label="State">
                        {(props) => <Input {...props} variant="filled" />}
                      </FormField>
                      <Box width={4} />
                      <FormField name="address.zipCode" label="Zipcode">
                        {(props) => <Input {...props} variant="filled" />}
                      </FormField>
                    </Flex>
                  </Section>
                </Box>
              </Box>
            </ModalBody>
            <Flex
              justifyContent="flex-end"
              paddingTop={5}
              borderTop="1px solid"
              borderTopColor="black"
            >
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={props.handleSubmit}
                disabled={!props.isValid}
                isLoading={loading}
              >
                Save Changes
              </Button>
            </Flex>
          </Flex>
        )}
      </Formik>
    </InputModal>
  );
};

export default EditCustomer;
