import { useQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from "@chakra-ui/core";
import { useParams } from "react-router-dom";
import useDocumentTitle from "@rehooks/document-title";
import React, { useState } from "react";
import { get } from "lodash";
import { SERVICE_TYPE_CATEGORY } from "./gql";
import PageHeader from "../../../../components/PageHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../components/Table";
import ItemMenu from "../../../../components/ItemMenu";
import Button from "../../../../components/Button";
import { formatDate } from "../../../../lib/dates";
import ToggleVisibilityDialog from "./ToggleVisibilityDialog";
import ManageService from "./ManageService";
import DeleteDialog from "./DeleteDialog";
import { Service, ServiceTypeCategoryService } from "../../../../types";

type ServiceTypeCategoryParams = {
  id: string;
};

const ServiceTypeCategories = ({ store }) => {
  useDocumentTitle(`Services - ${store.name} - Bodyart`);

  const params = useParams<ServiceTypeCategoryParams>();
  const [
    toggleVisibility,
    setToggleVisibility,
  ] = useState<ServiceTypeCategoryService | null>(null);
  const [editService, setEditingService] = useState<Service | null>(null);
  const [removing, setRemoving] = useState<ServiceTypeCategoryService | null>(
    null,
  );
  const addingDisclosure = useDisclosure();

  const { data, loading } = useQuery(SERVICE_TYPE_CATEGORY, {
    variables: {
      serviceTypeCategoryId: params.id,
    },
  });

  const serviceTypeCategory = data && data.serviceTypeCategory;
  const services = get(data, "serviceTypeCategory.services", []);

  return (
    <Box padding={5}>
      <PageHeader
        title={`${
          serviceTypeCategory ? serviceTypeCategory.displayName : "Loading"
        } - Services`}
        hasBackButton
      />

      <ToggleVisibilityDialog
        isOpen={!!toggleVisibility}
        item={toggleVisibility}
        onClose={() => setToggleVisibility(null)}
      />

      <ManageService
        isOpen={!!editService || addingDisclosure.isOpen}
        service={editService}
        category={serviceTypeCategory}
        onClose={() => {
          setEditingService(null);
          addingDisclosure.onClose();
        }}
      />

      <DeleteDialog
        isOpen={!!removing}
        onClose={() => setRemoving(null)}
        categoryService={removing}
      />

      <Box>
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            flex={1}
            padding={5}
          >
            <Spinner color="brandYellow.500" size="md" />
          </Flex>
        )}
        <Table>
          <TableBody>
            {services.map((categoryService) => (
              <TableRow key={categoryService.id}>
                <TableCell onClick={() => {}}>
                  <Heading
                    fontSize={["md", "xl"]}
                    color={categoryService.disabledAt ? "midGrey" : "black"}
                  >
                    {categoryService.service.displayName}
                  </Heading>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  flexBasis={200}
                  flexGrow={0}
                  hideOnSmall
                >
                  <Text color="darkGrey" fontWeight="500" fontSize={14}>
                    {categoryService.service.minimumAgeRequirement
                      ? `${categoryService.service.minimumAgeRequirement}+ yrs`
                      : "All ages"}
                  </Text>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  flexBasis={200}
                  flexGrow={0}
                  hideOnSmall
                >
                  <Text color="midGrey" fontSize={14}>
                    Last Updated:{" "}
                    {formatDate(categoryService.updatedAt) || "N/A"}
                  </Text>
                </TableCell>
                <TableCell
                  justifyContent="flex-end"
                  flexShrink={0}
                  flexGrow={0}
                  flexBasis={50}
                >
                  <ItemMenu>
                    <MenuList placement="bottom-end">
                      <MenuItem
                        onClick={() =>
                          setEditingService(categoryService.service)
                        }
                      >
                        Edit service
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        onClick={() => setRemoving(categoryService)}
                        color="red.500"
                      >
                        Remove service
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        onClick={() => setToggleVisibility(categoryService)}
                        color="red.500"
                      >
                        {categoryService.disabledAt
                          ? "Enable service"
                          : "Disable service"}
                      </MenuItem>
                    </MenuList>
                  </ItemMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button
          variant="primary"
          position="absolute"
          bottom={5}
          right={5}
          onClick={addingDisclosure.onOpen}
        >
          Add new service
        </Button>
      </Box>
    </Box>
  );
};

export default ServiceTypeCategories;
