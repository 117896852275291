import { gql } from "@apollo/client";

export const GENERATE_UPLOAD = gql`
  mutation GenerateUpload($input: GenerateUploadInput!) {
    result: generateUpload(input: $input) {
      authKey
      signature
      expires
      templateId
    }
  }
`;
