import { useQuery } from "@apollo/client";
import { Box, Flex, Spinner } from "@chakra-ui/core";
import useDocumentTitle from "@rehooks/document-title";
import React, { useState } from "react";
import { get } from "lodash";
import { CONSENT_QUESTION_CATEGORIES } from "./gql";
import ConsentQuestions from "./ConsentQuestions";
import ConsentQuestionCategories from "./ConsentQuestionCategories";

const Page = ({ store }) => {
  useDocumentTitle(`Consent Questions - ${store.name} - Bodyart`);

  const [categoryId, setCategoryId] = useState(null);
  const { data, loading } = useQuery(CONSENT_QUESTION_CATEGORIES, {
    variables: {
      storeId: store.id,
    },
  });

  const categories = get(data, "store.consentQuestionCategories", []);
  const selectedCategory =
    categoryId && categories.find((c) => c.id === categoryId);

  return (
    <Box>
      {loading && (
        <Flex justifyContent="center" alignItems="center" flex={1} padding={5}>
          <Spinner color="brandYellow.500" size="md" />
        </Flex>
      )}

      {!loading && selectedCategory && (
        <ConsentQuestions
          category={selectedCategory}
          questions={selectedCategory.questions}
          store={store}
        />
      )}

      {!loading && !selectedCategory && (
        <ConsentQuestionCategories
          store={store}
          categories={categories}
          onSelectCategory={(category) => setCategoryId(category.id)}
        />
      )}
    </Box>
  );
};

export default Page;
