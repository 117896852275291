import React from "react";
import { Flex, ModalCloseButton, useToast } from "@chakra-ui/core";
import { get } from "lodash";
import PageHeader from "../PageHeader";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_PROCEDURE, SERVICES } from "./gql";
import useCurrentStore from "../../hooks/useCurrentStore";
import ManageProcedureForm from "./ManageProcedureForm";
import InputModal from "../InputModal";

const AddProcedure = ({ visit, refetchVisit, onOpen, onClose, isOpen }) => {
  const store = useCurrentStore();
  const toast = useToast();
  const { data } = useQuery(SERVICES, {
    skip: !isOpen,
    variables: { storeId: store?.id },
  });

  const [addProcedure, { loading: isAdding }] = useMutation(ADD_PROCEDURE, {
    onCompleted: () => {
      toast({
        title: "Service added",
        status: "success",
        position: "bottom-left",
      });
      refetchVisit();
      onClose();
    },
  });

  const serviceTypes = get(data, "store.serviceTypes", []);

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Add new service"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />
        <ManageProcedureForm
          store={store}
          onClose={onClose}
          onSubmit={(values) =>
            addProcedure({
              variables: { input: { ...values, visitId: visit.id } },
            })
          }
          isLoading={isAdding}
          serviceTypes={serviceTypes}
        />
      </Flex>
    </InputModal>
  );
};

export default AddProcedure;
