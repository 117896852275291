import { gql } from "@apollo/client";
import { visitService } from "../../fragments";

export const COMPLETE_PROCEDURE = gql`
  mutation CompleteProcedure($input: CompleteProcedureInput!) {
    result: completeProcedure(input: $input) {
      visitService {
        ...visitService
      }
      errors {
        field
        message
      }
    }
  }
  ${visitService}
`;
