import React from "react";
import { Link } from "react-router-dom";
import { Flex, Avatar, Text, Icon, useDisclosure } from "@chakra-ui/core";
import StoreSwitcher from "../StoreSwitcher";

const StoreHeader = ({ store, storePath, hideMenu }) => {
  const storeSwitcher = useDisclosure();

  return (
    <>
      <StoreSwitcher
        currentStore={store}
        hideMenu={hideMenu}
        {...storeSwitcher}
      />

      <Link to={storePath} onClick={storeSwitcher.onOpen}>
        <Flex alignItems="center">
          <Avatar
            size="sm"
            src={store?.profileImageUrl}
            marginRight={3}
            name={store?.name}
          />
          <Text color="white" fontSize="lg" fontWeight="black">
            {store?.name}
          </Text>
          <Icon name="chevron-down" color="brandYellow.500" marginLeft={2} />
        </Flex>
      </Link>
    </>
  );
};

export default StoreHeader;
