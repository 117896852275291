import React from "react";
import {
  ModalBody,
  ModalCloseButton,
  Text,
  Textarea,
  Flex,
  Select,
  useToast,
} from "@chakra-ui/core";
import { get } from "lodash";
import PageHeader from "../PageHeader";
import Button from "../Button";
import * as Yup from "yup";
import { Formik } from "formik";
import { Header, Section } from "../VisitSheet/Section";
import { Body } from "../Typography";
import FormField from "../FormField";
import InformationRow from "../VisitSheet/InformationRow";
import { useMutation, useQuery } from "@apollo/client";
import { APPROVE_VISIT, EMPLOYEES_FOR_STORE, UPDATE_PROCEDURES } from "./gql";
import { ServiceTypeOffering, StoreEmployeeRole } from "../../types";
import SelectInventoryForProcedure from "../SelectInventoryForProcedure";
import { prepareForSubmission } from "../../lib/form";
import { Features } from "../../constants";

const ArtistSelect = (artists) => (props) => (
  <Select placeholder="Select an artist" {...props}>
    {artists.map((artist) => (
      <option key={artist.id} value={artist.id}>
        {artist.user.name}
      </option>
    ))}
  </Select>
);

const procedureSchema = Yup.object().shape({
  assignedEmployeeId: Yup.string().required("must select artist"),
});

const validationSchema = Yup.object().shape({
  procedures: Yup.array().of(procedureSchema),
});

const ReviewServices = ({ history, visit, totalSteps, onClose }) => {
  const toast = useToast();

  const [approveVisit, { loading: approving }] = useMutation(APPROVE_VISIT, {
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: "Consent form approved!",
          status: "success",
          position: "bottom-left",
        });
        history.push("/finished");
      }
    },
  });

  const [updateProcedures, { loading: updatingProcedures }] = useMutation(
    UPDATE_PROCEDURES,
    {
      onCompleted: ({ result }) => {
        if (!result.errors) {
          approveVisit({ variables: { input: { visitId: visit.id } } });
        }
      },
    },
  );

  const { data } = useQuery(EMPLOYEES_FOR_STORE, {
    variables: {
      storeId: visit.store.id,
      first: 999,
      filters: {
        roles: [StoreEmployeeRole.Artist],
      },
    },
  });

  const employeeEdges = get(data, "store.employees.edges", []);
  const employees = employeeEdges.map((edge) => edge.node);

  const initialValues = {
    visitId: visit.id,
    procedures: visit.services.map((visitService) => ({
      id: visitService.id,
      assignedEmployeeId: visitService.assignedEmployee?.id,
      staffNotes: visitService.staffNotes,
      inventorySelections: (visitService.inventorySelections || []).map(
        (is) => ({
          inventoryItemId: is.inventoryItem.id,
          selectedValues: prepareForSubmission(is.selectedValues),
        }),
      ),
    })),
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        updateProcedures({ variables: { input: values } });
      }}
    >
      {(props) => (
        <Flex
          flexDirection="column"
          position="relative"
          overflow="hidden"
          padding={6}
          flex={1}
        >
          <PageHeader
            title="Assign Artist"
            renderSubHeader={() => (
              <Text color="darkGrey" fontSize="md" marginLeft="2">
                Step {totalSteps} of {totalSteps}
              </Text>
            )}
            renderRightControls={() => (
              <ModalCloseButton size="lg" right={0} top={-2} />
            )}
          />
          <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
            <Flex
              as="form"
              flexDirection="column"
              onSubmit={props.handleSubmit}
            >
              {visit.services.map((visitService, idx) => {
                const isPiercing =
                  visitService.service.serviceType.type ===
                  ServiceTypeOffering.Piercing;

                return (
                  <Flex flexDirection={["column", "row"]}>
                    <Section maxWidth={["100%", "50%"]}>
                      <Header
                        title={`Procedure ${idx + 1} - ${
                          visitService.service.serviceType.displayName
                        }`}
                      />

                      <InformationRow label="Service Name">
                        <Body>{visitService.service.displayName}</Body>
                      </InformationRow>

                      {visitService.submittedInformation.map((info) => (
                        <InformationRow label={info.label} key={info.name}>
                          <Body>{info.value || "N/A"}</Body>
                        </InformationRow>
                      ))}
                    </Section>
                    <Section maxWidth={["100%", "50%"]}>
                      <FormField
                        name={`procedures[${idx}].assignedEmployeeId`}
                        label="Select Artist"
                      >
                        {ArtistSelect(employees)}
                      </FormField>

                      {isPiercing && Features.inventorySelectionAndManagement && (
                        <FormField
                          name={`procedures[${idx}].inventorySelections[0]`}
                          label="Inventory"
                        >
                          {(props) => (
                            <SelectInventoryForProcedure
                              {...props}
                              serviceId={visitService.service.id}
                              storeId={visit.store.id}
                            />
                          )}
                        </FormField>
                      )}

                      {isPiercing && !Features.inventorySelectionAndManagement && (
                        <FormField
                          name={`procedures[${idx}].inventoryNotes`}
                          label="Inventory"
                        >
                          {(props) => (
                            <Textarea
                              {...props}
                              variant="filled"
                              placeholder="List any inventory used in this procedure – customers will see this."
                            />
                          )}
                        </FormField>
                      )}

                      <FormField
                        name={`procedures[${idx}].staffNotes`}
                        label="Additional Details (optional)"
                      >
                        {(props) => (
                          <Textarea
                            {...props}
                            variant="filled"
                            placeholder="Only staff members will see these"
                          />
                        )}
                      </FormField>
                    </Section>
                  </Flex>
                );
              })}
            </Flex>
          </ModalBody>
          <Flex
            justifyContent="flex-end"
            paddingTop={5}
            borderTop="1px solid"
            borderTopColor="black"
          >
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!props.isValid}
              isLoading={updatingProcedures || approving}
              onClick={props.handleSubmit}
            >
              Assign and Approve
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default ReviewServices;
