import { gql } from "@apollo/client";
import { User } from "../../fragments";

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    result: resetPassword(input: $input) {
      session {
        token
        refreshToken
        user {
          ...User
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${User}
`;
