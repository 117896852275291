import { gql } from "@apollo/client";

export const User = gql`
  fragment User on User {
    id
    name
    firstName
    lastName
    emailAddress
    isStaff
    profileImageUrl
  }
`;

export const minimalUserProfile = gql`
  fragment minimalUserProfile on User {
    id
    name
    firstName
    lastName
    emailAddress
    profileImageUrl
  }
`;

export const customerInformation = gql`
  fragment customerInformation on Customer {
    id
    name
    firstName
    lastName
    emailAddress
    phoneNumber {
      raw
      formatted
    }
    dateOfBirth
    isMinor
    parent {
      id
      name
      emailAddress
    }
  }
`;

export const address = gql`
  fragment address on Address {
    addressLineOne
    addressLineTwo
    city
    state
    zipCode
    country
  }
`;

export const fullAccount = gql`
  fragment fullAccount on Account {
    id
    name
    billingEmailAddress
    slug
    insertedAt
    updatedAt
    delinquentAt
  }
`;

export const accountViewerContext = gql`
  fragment accountViewerContext on Account {
    context {
      role
    }
  }
`;

export const storeInformation = gql`
  fragment storeInformation on Store {
    id
    slug
    name
    phoneNumber
    emailAddress
    website
    profileImageUrl
    headerImageUrl
    newVisitUrl
    newVisitUnscheduledUrl
    address {
      ...address
    }
    account {
      id
      slug
    }
    insertedAt
    updatedAt
    disabledAt
  }
  ${address}
`;

export const storeViewerContext = gql`
  fragment storeViewerContext on Store {
    context {
      role
      permissions
    }
  }
`;

export const partialStoreEmployee = gql`
  fragment partialStoreEmployee on StoreEmployee {
    id
    roles
    artistServices
    user {
      ...minimalUserProfile
    }
  }
  ${minimalUserProfile}
`;

export const visitService = gql`
  fragment visitService on VisitService {
    id
    staffNotes
    status
    denyReason
    aftercareInstructions
    externalAftercareInstructionsUrl
    sterilizationNotes
    sterilizationLogImageUrl
    service {
      id
      displayName
      aftercareInstructions
      externalAftercareInstructionsUrl
      serviceType {
        id
        type
        displayName
      }
    }
    assignedEmployee {
      ...partialStoreEmployee
    }
    submittedInformation {
      name
      label
      value
    }
    inventoryNotes
    inventorySelections {
      id
      inventoryItem {
        id
        name
      }
      selectedValues {
        name
        value
      }
    }
  }
  ${partialStoreEmployee}
`;

export const visitListingInformation = gql`
  fragment visitListingInformation on Visit {
    id
    status
    denyReason
    scheduledFor
    hasMedicalHistoryToReview
    consentAnsweredAt
    idImageUrls
    customerSignatureImageUrl
    customer {
      ...customerInformation
    }
    services {
      ...visitService
    }
    insertedAt
  }
  ${customerInformation}
  ${visitService}
`;

export const visitCustomerInformation = gql`
  fragment visitCustomerInformation on VisitCustomerInformation {
    firstName
    lastName
    emailAddress
    phoneNumber {
      raw
      formatted
    }
    dateOfBirth
    address {
      ...address
    }
    idImageUrls
  }
  ${address}
`;

export const pageInfo = gql`
  fragment pageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const accountSeat = gql`
  fragment accountSeat on AccountSeat {
    id
    role
    user {
      ...User
    }
    insertedAt
    updatedAt
  }
  ${User}
`;

export const visitMedicalHistory = gql`
  fragment visitMedicalHistory on Visit {
    medicalHistory {
      additionalNotes
      medicalConditions
      hasSkinCondition
      hasIndicatedMedicationUse
      hasAllergyToProducts
      isGenerallyHealthy
      isProneToFainting
      hasBloodConditions
      hasHistoryOfComplications
    }
  }
`;

export const serviceForCategoryListing = gql`
  fragment serviceForCategoryListing on Service {
    id
    name
    displayName
    description
    requiresAdditionalInformation
    aftercareInstructions
    externalAftercareInstructionsUrl
    healingTime
    downsizeTime
    minimumAgeRequirement
    informationFields {
      ... on ServiceInformationTextField {
        name
        label
        type
        placeholder
        isRequired
      }
      ... on ServiceInformationOptionField {
        name
        label
        isRequired
        options
      }
      ... on ServiceInformationSelectField {
        name
        label
        isRequired
        options
      }
    }
    icon {
      unselectedUrl
      selectedUrl
    }
    serviceType {
      id
      displayName
      type
    }
  }
`;

export const serviceTypeCategory = gql`
  fragment serviceTypeCategory on ServiceTypeCategory {
    id
    name
    displayName
    index
    services {
      id
      service {
        ...serviceForCategoryListing
      }
      disabledAt
      updatedAt
    }
    icon {
      unselectedUrl
      selectedUrl
    }
    disabledAt
    updatedAt
  }
  ${serviceForCategoryListing}
`;

export const serviceType = gql`
  fragment serviceType on ServiceType {
    id
    displayName
    type
    categories {
      ...serviceTypeCategory
    }
    disabledAt
    updatedAt
  }
  ${serviceTypeCategory}
`;

export const invitedStoreEmployee = gql`
  fragment invitedStoreEmployee on InvitedStoreEmployee {
    id
    emailAddress
    role
    artistServices
    insertedAt
    updatedAt
  }
`;

export const invitedAccountSeat = gql`
  fragment invitedAccountSeat on InvitedAccountSeat {
    id
    emailAddress
    role
    insertedAt
    updatedAt
  }
`;

export const inventoryItem = gql`
  fragment inventoryItem on InventoryItem {
    id
    name
    type
    properties {
      name
      options
    }
    services {
      id
      displayName
    }
    insertedAt
    updatedAt
  }
`;

export const storeAftercare = gql`
  fragment storeAftercare on StoreAftercare {
    id
    aftercareInstructions
    externalAftercareInstructionsUrl
    serviceType
    insertedAt
    updatedAt
  }
`;

export const consentQuestion = gql`
  fragment consentQuestion on ConsentQuestion {
    id
    type
    index
    question
    isRequired
    flagVisitWhenEqual
    insertedAt
    updatedAt
  }
`;

export const consentQuestionCategory = gql`
  fragment consentQuestionCategory on ConsentQuestionCategory {
    id
    name
    description
    index
    questions {
      ...consentQuestion
    }
    insertedAt
    updatedAt
  }
  ${consentQuestion}
`;

export const answeredConsentQuestion = gql`
  fragment answeredConsentQuestion on AnsweredConsentQuestion {
    questionId
    question
    categoryId
    category
    value
    isFlagged
  }
`;

export const fullVisit = gql`
  fragment fullVisit on Visit {
    ...visitListingInformation
    customerInformation {
      ...visitCustomerInformation
    }
    parentCustomerInformation {
      ...visitCustomerInformation
    }
    consentAnswers {
      ...answeredConsentQuestion
    }
    ...visitMedicalHistory
    store {
      id
      aftercareDefaults {
        ...storeAftercare
      }
    }
  }
  ${visitListingInformation}
  ${visitCustomerInformation}
  ${visitMedicalHistory}
  ${storeAftercare}
  ${answeredConsentQuestion}
`;
