import React from "react";
import {
  Box,
  Flex,
  Spinner,
  Text,
  Heading,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../../components/Table";
import ItemMenu from "../../../../../components/ItemMenu";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { AFTERCARE } from "./gql";
import { ServiceTypes } from "../../../../../constants";
import { formatDate } from "../../../../../lib/dates";
import ResetAftercareDialog from "./ResetDialog";
import ManageAftercare from "./ManageAftercare";

const Aftercare = ({ store }) => {
  const [resetAftercareType, setResetAftercareType] = React.useState(null);
  const [aftercareForUpdate, setAftercareForUpdate] = React.useState(null);

  const { data, loading } = useQuery(AFTERCARE, {
    variables: { storeId: store.id },
  });

  const aftercare = get(data, "store.aftercareDefaults", []);

  return (
    <Box>
      <ResetAftercareDialog
        storeId={store.id}
        onClose={() => setResetAftercareType(null)}
        isOpen={!!resetAftercareType}
        serviceType={resetAftercareType}
      />

      <ManageAftercare
        isOpen={!!aftercareForUpdate}
        onClose={() => setAftercareForUpdate(null)}
        aftercare={aftercareForUpdate}
      />

      {loading && (
        <Flex justifyContent="center" alignItems="center" flex={1} padding={5}>
          <Spinner color="brandYellow.500" size="md" />
        </Flex>
      )}

      <Table>
        <TableBody>
          {aftercare.map((aftercare) => (
            <TableRow key={aftercare.id}>
              <TableCell>
                <Heading fontSize={["md", "lg"]}>
                  {ServiceTypes[aftercare.serviceType]}
                </Heading>
              </TableCell>
              <TableCell
                flexBasis={250}
                flexGrow={0}
                justifyContent="flex-end"
                hideOnSmall
              >
                <Text color="midGrey" fontSize={14}>
                  Last Updated: {formatDate(aftercare.updatedAt) || "N/A"}
                </Text>
              </TableCell>
              <TableCell
                justifyContent="flex-end"
                flexShrink={0}
                flexGrow={0}
                flexBasis={50}
              >
                <ItemMenu>
                  <MenuList>
                    <MenuItem onClick={() => setAftercareForUpdate(aftercare)}>
                      Edit
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      color="statusRed.500"
                      onClick={() =>
                        setResetAftercareType(aftercare.serviceType)
                      }
                    >
                      Reset for all services
                    </MenuItem>
                  </MenuList>
                </ItemMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Aftercare;
