import React from "react";
import { Heading, Text, Box, Alert, AlertIcon, Link } from "@chakra-ui/core";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import useDocumentTitle from "@rehooks/document-title";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { LOGIN_VIA_EMAIL } from "./gql";
import Form, { FormInputs } from "./Form";
import { setAuthFromSession } from "../../state/auth";
import { Session } from "../../types";

function buildPayloadFromSession(session: Session) {
  return {
    userId: session.user.id,
    token: session.token,
    refreshToken: session.refreshToken,
  };
}

type LocationProps = {
  next?: string;
  message?: string;
};

const Login = () => {
  useDocumentTitle("Login - Bodyart");

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const [login] = useMutation(LOGIN_VIA_EMAIL);

  return (
    <Box
      display="flex"
      flex={1}
      height="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor="black"
    >
      <Box maxW="md" rounded="lg" padding={4}>
        <Box mb={4} textAlign="center">
          <Heading as="h1" size="lg" color="white" mb={2}>
            Welcome back
          </Heading>
          <Text color="midGrey">
            Login with your Bodyart employee account to continue to your store.
          </Text>
        </Box>

        {location?.state?.message && (
          <Alert status="error" mt={5} mb={5}>
            <AlertIcon />
            {location.state.message}
          </Alert>
        )}

        <Form
          onSubmit={async (input: FormInputs, actions) => {
            const {
              data: { result },
            } = await login({ variables: { input } });

            if (result.errors) {
              return result.errors.forEach((error) => {
                actions.setFieldError(error.field, error.message);
              });
            }

            const sessionPayload = buildPayloadFromSession(result.session);
            dispatch(setAuthFromSession(sessionPayload));
            history.push(location?.state?.next || "/");
          }}
        />

        <Box textAlign="center" mt={5}>
          <Link
            color="brandYellow.500"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing={1}
            fontSize={12}
            as={RouterLink as any}
            {...{ to: "/forgot-password" }}
          >
            Forgot Password
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
