import {
  Box,
  Text,
  MenuList,
  MenuItem,
  MenuDivider,
  Badge,
  Icon,
} from "@chakra-ui/core";
import useDocumentTitle from "@rehooks/document-title";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../../../components/Table";
import ItemMenu from "../../../../../components/ItemMenu";
import { formatDate } from "../../../../../lib/dates";
import DeleteDialog from "./DeleteQuestionDialog";
import ManageConsentQuestion from "./ManageConsentQuestion";
import Button from "../../../../../components/Button";
import { useMutation } from "@apollo/client";
import { REORDER_CONSENT_QUESTION } from "./gql";

const ConsentQuestions = ({ store, category, questions }) => {
  useDocumentTitle(`Consent Questions - ${store.name} - Bodyart`);

  const [reoderQuestion, { loading }] = useMutation(REORDER_CONSENT_QUESTION, {
    refetchQueries: ["ConsentQuestionCategories"],
    awaitRefetchQueries: true,
  });

  const [removingId, setRemovingId] = useState(null);
  const [editing, setEditing] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  return (
    <Box>
      <DeleteDialog
        isOpen={!!removingId}
        consentQuestionId={removingId}
        onClose={() => setRemovingId(null)}
      />
      <ManageConsentQuestion
        isOpen={!!editing || isCreating}
        store={store}
        category={category}
        consentQuestion={editing}
        onClose={() => {
          setEditing(null);
          setIsCreating(false);
        }}
      />
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) return;

          const input = {
            consentQuestionId: result.draggableId,
            position: result.destination.index,
          };

          reoderQuestion({ variables: { input } });
        }}
      >
        <Table marginBottom={10}>
          <TableBody>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questions.map((question) => (
                    <Draggable
                      key={question.id}
                      draggableId={question.id}
                      index={question.index}
                    >
                      {(provided) => (
                        <TableRow
                          key={question.id}
                          height="auto"
                          innerRef={provided.innerRef}
                          opacity={loading ? 0.5 : 1}
                          {...provided.draggableProps}
                        >
                          <TableCell flexShrink={0} flexGrow={0} flexBasis={30}>
                            <span {...provided.dragHandleProps}>
                              <Icon name="drag-handle" />
                            </span>
                          </TableCell>
                          <TableCell flex={1} whiteSpace="pre-line">
                            <Text my={3}>{question.question}</Text>
                          </TableCell>
                          <TableCell
                            flexShrink={0}
                            flexGrow={0}
                            flexBasis={150}
                          >
                            <Badge>{question.type}</Badge>
                            {question.isRequired && (
                              <Badge variantColor="red" ml={2}>
                                Required
                              </Badge>
                            )}
                          </TableCell>
                          <TableCell
                            justifyContent="flex-end"
                            flexShrink={0}
                            flexGrow={0}
                            flexBasis={200}
                            hideOnSmall
                          >
                            <Text color="midGrey" fontSize={14}>
                              Last Updated:{" "}
                              {formatDate(question.updatedAt) || "N/A"}
                            </Text>
                          </TableCell>
                          <TableCell
                            justifyContent="flex-end"
                            flexShrink={0}
                            flexGrow={0}
                            flexBasis={50}
                          >
                            <ItemMenu>
                              <MenuList>
                                <MenuItem onClick={() => setEditing(question)}>
                                  Edit
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem
                                  color="statusRed.500"
                                  onClick={() => setRemovingId(question.id)}
                                >
                                  Remove
                                </MenuItem>
                              </MenuList>
                            </ItemMenu>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </TableBody>
        </Table>
      </DragDropContext>

      <Button
        variant="primary"
        position="absolute"
        bottom={5}
        right={5}
        onClick={() => setIsCreating(true)}
      >
        Add new question
      </Button>
    </Box>
  );
};

export default ConsentQuestions;
