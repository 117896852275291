import { theme as baseTheme } from "@chakra-ui/core";
import icons from "./icons";
const poppinsFontStack = "Poppins, sans-serif";

const theme = {
  ...baseTheme,
  breakpoints: ["60em", "80em"],
  fonts: {
    body: poppinsFontStack,
    heading: poppinsFontStack,
    mono: poppinsFontStack,
  },
  fontSizes: {
    xs: "11px",
    sm: "13px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "32px",
    "4xl": "40px",
    "5xl": "48px",
    "6xl": "54px",
  },
  fontWeights: {
    ...baseTheme.fontWeights,
    normal: 400,
    medium: 500,
    bold: 600,
    black: 700,
  },
  icons: {
    ...baseTheme.icons,
    ...icons,
  },
  colors: {
    ...baseTheme.colors,
    black: "#151829",
    brandYellow: {
      500: "#FEB94C",
    },
    statusBlue: "#326AEB",
    statusRed: {
      500: "#FC3D28",
    },
    statusGreen: "#2D9B42",
    lightGrey: "#F3F3F3",
    darkGrey: "#666A74",
    midGrey: "#B0B1B7",
    secondaryMidGrey: "#DDD",
    darkestGrey: "#3B3C46",
  },
};

export default theme;
