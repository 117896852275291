import React from "react";
import { Formik } from "formik";
import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  useToast,
  Input,
  Select,
} from "@chakra-ui/core";
import * as Yup from "yup";
import PageHeader from "../../../../components/PageHeader";
import Button from "../../../../components/Button";
import FormField from "../../../../components/FormField";
import { useMutation } from "@apollo/client";
import { CREATE_INVENTORY_ITEM, UPDATE_INVENTORY_ITEM } from "./gql";
import TagInput from "../../../../components/TagInput";
import { prepareForSubmission } from "../../../../lib/form";
import ServiceSelect from "./ServiceSelect";
import InputModal from "../../../../components/InputModal";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Must set a name"),
  serviceIds: Yup.array().of(Yup.string()).required("Must select services"),
});

enum Mode {
  Creating = "CREATING",
  Updating = "UPDATING",
}

const Mutations = {
  [Mode.Creating]: CREATE_INVENTORY_ITEM,
  [Mode.Updating]: UPDATE_INVENTORY_ITEM,
};

const SERVICE_TYPE = "PIERCING";

const ManageInventoryItem = ({ isOpen, onClose, store, inventoryItem }) => {
  const toast = useToast();
  const mode = inventoryItem ? Mode.Updating : Mode.Creating;
  const mutation = Mutations[mode];

  const [performMutation, { loading: isSaving }] = useMutation(mutation, {
    refetchQueries: ["InventoryItemsForStore"],
    onCompleted: ({ result }) => {
      if (!result.errors) {
        toast({
          title: `Inventory item ${
            mode === Mode.Creating ? "created" : "updated"
          }`,
          status: "success",
          position: "bottom-left",
        });
        onClose();
      }
    },
  });

  const initialValues = inventoryItem
    ? {
        inventoryItemId: inventoryItem.id,
        name: inventoryItem.name,
        type: inventoryItem.type,
        properties: prepareForSubmission(inventoryItem.properties),
        serviceIds: inventoryItem.services.map((s) => s.id),
      }
    : {
        storeId: store.id,
        name: "",
        type: SERVICE_TYPE,
        properties: [
          {
            name: "Material",
            options: [],
          },
          {
            name: "Threading",
            options: [],
          },
          {
            name: "Size / Thickness",
            options: [],
          },
          {
            name: "Length",
            options: [],
          },
        ],
        serviceIds: [],
      };

  return (
    <InputModal isOpen={isOpen} onClose={onClose}>
      <Flex
        flexDirection="column"
        position="relative"
        overflow="hidden"
        padding={6}
        flex={1}
      >
        <PageHeader
          title="Manage Inventory Item"
          renderRightControls={() => (
            <ModalCloseButton size="lg" right={0} top={-2} />
          )}
        />

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (input, actions) => {
            const { data } = await performMutation({ variables: { input } });

            if (data.result.errors) {
              data.result.errors.forEach((err) =>
                actions.setFieldError(err.field, err.message),
              );
            }
          }}
        >
          {(formProps) => (
            <>
              <ModalBody margin={0} padding={0} overflowY="scroll" flex={1}>
                <Flex
                  as="form"
                  flexDirection={["column", "row"]}
                  onSubmit={formProps.handleSubmit}
                >
                  <Box paddingY={5} width={["100%", "50%"]}>
                    <FormField name="name" label="Name">
                      {(props) => <Input {...props} variant="filled" />}
                    </FormField>
                    <FormField name="type" label="Type">
                      {(props) => (
                        <Select {...props} variant="filled">
                          <option value={SERVICE_TYPE}>Piercing</option>
                        </Select>
                      )}
                    </FormField>

                    {formProps.values.properties.map((item, idx) => (
                      <FormField
                        name={`properties[${idx}].options`}
                        label={item.name}
                      >
                        {(props) => <TagInput {...props} />}
                      </FormField>
                    ))}
                  </Box>
                  <Box paddingY={5} width={["100%", "50%"]}>
                    <FormField name="serviceIds" label="Attached Services">
                      {(props) => (
                        <ServiceSelect
                          {...props}
                          serviceIds={formProps.values.serviceIds}
                          selectedServiceType={SERVICE_TYPE}
                          isOpen={isOpen}
                          storeId={store.id}
                        />
                      )}
                    </FormField>
                  </Box>
                </Flex>
              </ModalBody>
              <Flex
                justifyContent="flex-end"
                paddingTop={5}
                borderTop="1px solid"
                borderTopColor="black"
              >
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={isSaving}
                  disabled={!formProps.isValid}
                  onClick={formProps.handleSubmit}
                >
                  Save
                </Button>
              </Flex>
            </>
          )}
        </Formik>
      </Flex>
    </InputModal>
  );
};

export default ManageInventoryItem;
